$search-icon-width: $unit-md;
$search-close-button-width: 44px;
$search-container-height: 44px;

.search-content-container {
  border-radius: calc(#{$search-container-height} / 2);
  box-shadow: $box-shadow-standard;
  height: $search-container-height;
  width: 100%;

  &.on-red {
    background: nurx-color("white");
  }

  &.on-white {
    border: 1px solid nurx-color-level("black", 9.3);
  }

  // Search Icon
  .search-icon-container {
    float: left;
    height: 100%;
    width: $search-icon-width;

    .search-icon {
      background-image: url("images/ios-app/icon-search-blue.svg");
      background-position: center;
      background-position-x: 10px;
      background-repeat: no-repeat;
      background-size: 50%;
      height: 100%;
      width: $search-icon-width;
    }
  }

  // Search Input
  .search-input-container {
    float: left;
    height: 100%;
    width: calc(100% - (#{$search-close-button-width}) - (#{$search-icon-width}));

    .search-input {
      border: 0;
      height: 100%;
      padding: $unit-xs;
      width: 100%;
    }
  }

  // Search Close
  .search-close-container {
    float: left;
    height: 100%;
    width: $search-close-button-width;

    .search-close-button {
      background-color: transparent;
      background-image: url("images/ios-app/icon-close-blue.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 38%;
      border: 0;
      height: 100%;
      width: $search-close-button-width;
    }
  }
}
