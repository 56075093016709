.__user-order-billing-confirmation__25773 .card {
  border-bottom: 0;
}

.__user-order-billing-confirmation__25773 .confirm-button {
  border-bottom-left-radius: $border-radius-standard;
  border-bottom-right-radius: $border-radius-standard;
}

.__user-order-billing-confirmation__25773 .order-implications-container {
  border-top: 1px solid grayscale-color("light-gray");
}
