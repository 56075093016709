.__journal-closed-tickets__453c2 .closed-ticket {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $unit-reg;

  .topic-ticket {
    align-items: center;
    display: flex;
  }
}
