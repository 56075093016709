$role-blue: #5bc0de;
$role-green: #60e600;
$role-orange: #f0ad4e;
$role-pink: #f53e95;

$role-colors: (
  "dev": $role-orange,
  "doctor": $role-pink,
  "ops": $role-blue,
  "registered-nurse": $role-green,
);

@function role-color($key) {
  @return get-color($role-colors, $key, "role-color");
}
