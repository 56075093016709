$border-color: grayscale-color("light-gray");

.__user-order-summary-card-item-v2__1aeb6 {
  display: flex;
  padding: {
    top: 18px;
    left: 16px;
    right: 10px;
    bottom: 8px;
  }
}

.__user-order-summary-card-item-v2__1aeb6 .item-content {
  flex: 1;
}

.__user-order-summary-card-item-v2__1aeb6 .item-icon {
  color: grayscale-color("black");
}

.__user-order-summary-card-item-v2__1aeb6 .item-title {
  position: relative;
  font-size: 14px;
  color: grayscale-color("black");
  line-height: 20px;

  &:last-child {
    margin-bottom: 8px;
  }
}

.__user-order-summary-card-item-v2__1aeb6 .item-description {
  font-size: 12px;
  line-height: 20px;
  color: grayscale-color("gray");
  margin-bottom: 8px;
}
