.__admin-drug__86187 .back-btn {
  margin-right: 0.5em;
}

.__admin-drug__86187 .admin-drug ul.dropdown {
  font-size: 0.95em;
  position: absolute;
  width: 100%;
  z-index: 4;
}

.__admin-drug__86187 .admin-drug ul.dropdown:not(.active) {
  display: none;
}

.__admin-drug__86187 .admin-drug ul.dropdown li {
  background: nurx-color("white");
  border: 1px solid #e3e3e3;
  border-top: 0;
  color: grayscale-color("black");
  cursor: pointer;
  display: block;
  padding: 0.4em 0.8em;
}

.__admin-drug__86187 .admin-drug ul.dropdown li:not(:last-child) {
  border-bottom: 0;
}

.__admin-drug__86187 .admin-drug ul.dropdown li:last-child {
  border-bottom: 1px solid #add8e6;
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
}

.__admin-drug__86187 .non-rx-form {
  /* something that will increase margin-bottom */
}
