.__admin__items__prescribing-messages-modal__1d14c .background {
  background-color: grayscale-color("black");
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
}

.__admin__items__prescribing-messages-modal__1d14c .prescribing-messages-modal {
  background-color: grayscale-color("white");
  border-radius: 4px;
  height: 80%;
  margin: 0 10%;
  overflow: hidden;
  padding: 16px 24px;
  position: absolute;
  width: 80%;
  z-index: 7;

  .prescribing-messages-header {
    .title {
      color: $dark-gray;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 160%;
      margin-bottom: 16px;
    }

    .subtitle {
      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.15px;
      margin-bottom: 16px;
    }
  }

  .prescribing-messages-body {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    height: calc(90% - 54px);

    .prescribing-messages-sidebar {
      height: 100%;
      margin-right: 16px;
      max-width: 260px;
      width: 30%;

      .prescribing-messages-sidebar-header {
        align-items: flex-start;
        background: $gray-header-bg;
        border: 1px solid $sidebar-border-gray;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: column;
        height: 48px;
        padding: 13px 16px;
        width: 100%;

        .sidebar-header-label {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.1px;
          line-height: 157%;
        }
      }

      .prescribing-messages-service-line {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 56px;
        padding: 4px 4px 4px 16px;
        width: 100%;

        .prescribing-messages-service-line-info {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          height: 48px;
          padding: 4px 0;

          .name {
            color: $dark-gray;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            letter-spacing: 0.15px;
            line-height: 143%;
            width: 197px;
          }

          .saved {
            color: $demon-gray;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: 40px;
            letter-spacing: 0.15px;
            line-height: 143%;
            width: 197px;
          }
        }

        .prescribing-messages-service-line-checkbox {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          height: 40px;
          padding: 0;
          width: 40px;

          .checkbox {
            background: $mid-blue;
            border-color: $mid-blue;
          }
        }
      }
    }

    .prescribing-messages-message-edit {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 70%;

      .edit-header {
        align-items: center;
        background: $off-gray;
        border: 1px solid $in-the-grayme;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: row;
        height: 45px;
        margin-bottom: 5px;
        padding: 8px 16px;
        width: 100%;

        .header-label {
          color: $dark-gray;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.15px;
          line-height: 150%;
        }
      }

      .multi-edit-chip {
        background: $warning-bg;
        border: 1px solid $warning-border;
        border-radius: 4px;
        color: $warning-text;
        font-size: 14px;
        font-weight: 400;
        height: 32px;
        line-height: 22px;
        margin-bottom: 5px;
        padding: 5px 16px;
      }

      .prescribing-message {
        border: 1px solid $sidebar-border-gray;
        border-radius: 0 0 8px 8px;
        color: $dark-gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        height: 100%;
        letter-spacing: 0.15px;
        line-height: 150%;
        width: 100%;
      }

      .prescribing-messages-char-count {
        color: grayscale-color("black");
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .prescribing-messages-footer {
    display: flex;
    flex-direction: row;
    height: 54px;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    .change-count-warning {
      background: $warning-bg;
      border: 1px solid $warning-border;
      border-radius: 4px;
      color: $warning-text;
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      line-height: 22px;
      margin-bottom: 5px;
      padding: 5px 16px;
    }

    .cancel {
      background: grayscale-color("white");
      border: 0;
      color: $dark-gray;
      font-size: 14px;
      font-weight: 400;
      height: 36px;
      letter-spacing: 0.4px;
      line-height: 24px;
      width: 60px;
    }

    .save {
      background: $light-blue;
      border: 0;
      border-radius: 4px;
      color: grayscale-color("white");
      font-size: 14px;
      font-weight: 400;
      height: 36px;
      letter-spacing: 0.4px;
      line-height: 24px;
      width: 60px;
    }
  }
}
