.__shipping-preference-radio__e2d84 .radio-option {
  border: 1px solid bright-color("grey");
  border-radius: 8px;
  cursor: pointer;
  color: bright-color("black");
  display: flex;
  align-items: start;

  &.opt-out {
    background-color: grayscale-color("lighter-gray");

    .radio-header {
      font-weight: 400;
      font-size: 14px;
    }
  }

  label {
    color: bright-color("black");
  }

  &:hover {
    opacity: 0.8;
  }

  &.recommended {
    background-color: transparent;
    margin-bottom: 10px;
  }

  input[type="radio"] {
    accent-color: bright-color("black");
    width: inherit;
  }

  &.selected {
    border-color: bright-color("black");
  }

  .radio-text {
    display: flex;
    flex-direction: column;

    .radio-header {
      font-size: 14px;
    }

    .radio-subheader {
      font-weight: 400;
    }

    > ul {
      margin-top: 6px;

      > li {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .radio-description {
      font-weight: normal;
    }

    .radio-label {
      border-radius: 4px;
      width: max-content;
      color: #709355;
      margin-top: 7px;
      padding-left: 0;
      font-size: 12px;

      img {
        margin-right: 2px;
        margin-top: -3px;
        height: 16px;
        width: 16px;
      }
    }
  }

  >label {
    display: flex;
    align-items: flex-start;
  }
}

.__shipping-preference-radio__e2d84 legend {
  display: none;
}
