.__fill-date-change__49014 .topic-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.__fill-date-change__49014 .bottom-button-wrapper {
  @media (min-width: 768px) {
    margin: auto;
    width: 537px;
  }
  @media (max-width: 768px) {
    margin: auto;
    width: 292px;
  }
}
