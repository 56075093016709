@mixin black-button {
  @include text-bold;
  background-color: grayscale-color("black");
  border: 0;
  border-radius: 3px;
  color: grayscale-color("white");
  height: 30px;
}

@mixin width {
  width: 30px;
}

@mixin rectangle {
  background-color: grayscale-color("lighter-gray");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: dashed 1px grayscale-color("light-gray");
  border-radius: 10px;
  display: flex;
  height: 200px;
  padding: 5px;
  width: 100%;
}

.__question__photo-upload__3b926 {
  @include vertical-spacing-half;
}

/*
Note that this class is duplicated elsewhere.
See: https://nurxeng.atlassian.net/browse/PXE-825
*/
.__question__photo-upload__3b926 .not-uploaded {
  @include rectangle;
  justify-content: center;

  .upload {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.__question__photo-upload__3b926 .retake {
  @include black-button;
  bottom: 5px;
  position: absolute;
  right: 5px;
}

.__question__photo-upload__3b926 .rotate {
  @include black-button;
  @include width;
  bottom: 5px;
  left: 5px;
  position: absolute;
}

.__question__photo-upload__3b926 .upload {
  align-items: center;
  background: none;
  border: 0;
  display: flex;

  .icon {
    @include black-button;
    @include width;
    align-items: center;
    border-radius: 100%;
    color: grayscale-color("white");
    display: flex;
    font-size: 25px;
    justify-content: center;
    margin-right: 5px;
  }
}

.__question__photo-upload__3b926 .uploaded {
  @include rectangle;
  align-items: center;
  justify-content: center;
  position: relative;

  .upload-preview {
    max-height: 100%;
    max-width: 100%;
  }
}
