.__admin-drug-config__bd7b4 .drug-config {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.__admin-drug-config__bd7b4 .config-checkbox {
  margin-left: 10px;
  width: auto;
}

.__admin-drug-config__bd7b4 .btn-save-settings {
  margin-top: 20px;
}
