.__admin__user__health-attribute__indicator__4f815 {
  $icon-size: 7px;
  border-radius: 50%;
  bottom: 1px;
  display: inline-flex;
  height: $icon-size;
  position: relative;
  width: $icon-size;

  &.baseline {
    background-color: nurx-color("success");
  }

  &.danger {
    background-color: nurx-color("danger");
  }

  &.warning {
    background-color: nurx-color("warning");
  }
}
