@mixin request-intro($background-color, $bottom-padding: 1rem) {
  background-color: $background-color;
  color: grayscale-color("gray");
  left: 50%;
  margin: 0 -50vw 2.25rem;
  position: relative;
  right: 50%;
  text-align: center;
  top: -15px; // removes padding added by full-screen-height component
  width: 100vw;

  .request-intro-content {
    padding: 4rem 1rem $bottom-padding;

    h1 {
      @include nurx-digital-font;
      user-select: none;
    }
  }
}
