// Navbar – Default Styles
.app-navbar {
  @extend %iphone-padding-bottom;
  @extend %box-sizing-initial;
  background: grayscale-color("white");
  bottom: 0;
  box-shadow: 0 -2px 25px 0 rgba(181, 181, 205, 0.2);
  height: $app-navbar-height;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 5;
}
