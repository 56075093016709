.__nav-tabs__71ac9 .nav-tab-item {
  // Currently there are 5 items in our nav. Setting min-width to increase tap area size and remove visual bug.
  width: 20%;

  .navbar-item {
    @extend .text-center;
    opacity: 0.4;
    padding-bottom: 5px;
    padding-top: 1px;

    a {
      display: block;
    }

    &.current {
      opacity: 1;
    }

    .navbar-item-icon {
      $size: 30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: $size;
      margin: 0 auto;
      width: $size;
    }

    .navbar-item-text {
      @include text-bold;
      color: grayscale-color("black");
      font-size: 10px;
    }
  }
}
