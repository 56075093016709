$section-margin: 42px;

.__styles__onboarding__confirmed__e57c0 .bg-blue {
  background-color: pastel-color("blue-40");
}

.__styles__onboarding__confirmed__e57c0 .bg-purple {
  background-color: pastel-color("purple-40");
}

.__styles__onboarding__confirmed__e57c0 .bg-peach {
  background-color: pastel-color("peach-40");
}

.__styles__onboarding__confirmed__e57c0 .bg-gray {
  background-color: grayscale-color("lighter-gray");

  .subheading-text {
    color: grayscale-color("gray");
  }
}

.__styles__onboarding__confirmed__e57c0 .logo {
  height: 23px;
  max-width: 69px;
  margin-top: 32px;
  margin-bottom: 64px;
}

.__styles__onboarding__confirmed__e57c0 .info {
  margin-bottom: $section-margin;

  .header {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .subheading-text {
    font-size: 12px;
  }
}

.__styles__onboarding__confirmed__e57c0 .additional-details {
  margin-bottom: $section-margin;
}

.__styles__onboarding__confirmed__e57c0 .image-card {
  img {
    max-width: 100%;
  }
}

.__styles__onboarding__confirmed__e57c0 .legal {
  margin-bottom: $section-margin;

  .legal-data {
    font-size: 10px;
    margin-top: 24px;
    margin-bottom: 10px;
  }
}
