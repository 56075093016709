/*
  This font software is the property of Commercial Type.
  You may not modify the font software, use it on another website, or install it on a computer.
  License information is available at http://commercialtype.com/eula
  For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com
  Copyright (C) 2019 Schwartzco Inc.
  License: 1903-QRFVNS
*/

// TODO(anthony 2019-03-08) consolidate fonts
// https://phabricator.nurx.com/T5082

@font-face {
  font-display: swap;
  font-family: "Graphik Web";
  font-stretch: normal;
  font-style: normal;
  font-weight: $font-weight-graphik-regular;
  src: url("/assets/f/Graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("/assets/f/Graphik/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik Web";
  font-stretch: normal;
  font-style: normal;
  font-weight: $font-weight-graphik-medium;
  src: url("/assets/f/Graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("/assets/f/Graphik/Graphik-Medium-Web.woff") format("woff");
}
