// Welcome Body
.app-welcome {
  @extend %iphone-padding-top;
  height: 100vh;
  overflow: auto;
  position: relative;

  h1.instructions {
    // TODO @MRurka – confusing classes.
    color: nurx-color("black");
    font-size: $unit-reg;
    line-height: $unit-reg;
    margin: $unit-md 0 $unit-reg;
    text-align: center;
  }

  p.instructions {
    // TODO @MRurka – confusing classes. This is only for resetpw-page.hbs.
    color: #aaaab7;
    font-size: $unit-reg;
    margin-bottom: $unit-md;
    padding-top: 0 $unit-reg 0 $unit-reg;
  }

  // Patterned form background
  .form-group::after {
    background: url("/assets/images/ios-app/gray-pattern.svg") no-repeat;
    background-position: center;
    background-size: 90%;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  button.btn-primary,
  button.btn-primary:hover,
  button.btn-primary:active,
  button.btn-primary:focus {
    // TODO @MRurka – Inheriting blue button styles from default button styles, despite this design being white. Need to create a whole new button style and put there, then remove from here.
    background: #fc6855;
    border: 0;
    border-radius: $unit-lg;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
    color: nurx-color("white");
    font-size: $unit-reg;
    height: 50px;
    width: 100%;
  }

  // Touch ID Login
  // TODO @MRurka – This is actually good – just put it in the right place.
  .touch-id-login {
    margin-bottom: $unit-reg;

    .touch-id-icon {
      img {
        display: block;
        margin: 0 auto $unit-sm;
        width: $unit-lg;
      }
    }

    .touch-id-text {
      color: #788494;
      font-size: $unit-reg;
      margin-top: $unit-xs;
      text-align: center;
    }
  }

  // Terms & Conditions
  .terms {
    height: 75vh;
    overflow-y: scroll;
  }

  .security-badge-container {
    a {
      display: inline;
      font-size: 1em;
    }

    .badges {
      text-align: center;

      img {
        height: 54px;
        margin: 0 10px;
        width: auto;
      }
    }
  }
}

.app-login-back {
  left: $unit-reg;
  position: absolute;
  top: 46px;
}

.btn-oval {
  padding: 7px 13px;
}
