// style-namespace "subscription-terms"
.__checkout__subscription-terms__72c1a {
  font-size: 12px;
  border: 1px solid #BCD8DF;
  background: rgba(229, 235, 243, 0.5);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 24px;

  .muted {
    @include text-muted;
  }

  .terms-accepted-checkbox {
    width: 20px;
    height: 20px;
  }

  .terms-accepted-checkbox-label {
    padding-top: 2px;
    margin-left: 8px;
  }

  .terms-heading {
    margin-bottom: 2px;
  }
}
