// For some reason messages has a separate instance of our mobile nav
.__styles__messages__78df4 .messages-nav-tabs {
  align-items: center;
  background-color: nurx-color("white");
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 5px 10px 3px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
