.__styles__cross-care-checkout-confirmation__4204a .message-container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 30px;
  padding-top: 24px;
}

.__styles__cross-care-checkout-confirmation__4204a .checkout-banner {
  flex: 1;
  display: flex;
  align-items: stretch;
  border-bottom: 2px solid grayscale-color("light-gray");
  grid-row-end: 1; // This forces them all into one row
  grid-row-start: 1;
  position: sticky;
  margin-bottom: 20px;
}

.__styles__cross-care-checkout-confirmation__4204a .checkmark-icon-container {
  margin-right: 12px;
  display: flex;
}

.__styles__cross-care-checkout-confirmation__4204a .checkmark-icon {
  height: 32px;
  width: 32px;
  overflow: hidden;

  img {
    max-height: 32px;
    max-width: 32px;
  }
}

.__styles__cross-care-checkout-confirmation__4204a .banner-text {
  font-size: 1em;
  flex-grow: 1;
}

.__styles__cross-care-checkout-confirmation__4204a .account-button {
  margin-top: 40px;
}
