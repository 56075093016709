.__service-not-available__c80de {
  background-color: pastel-color("blue-40");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  text-align: center;

  .main-content {
    // Note: This is an arbitrary value. The consumer of this component
    // should specify the size of the element, allowing the spacing of the main content
    // and the footer content to adjust dynamically
    margin-bottom: 100px;

    .not-available-icon {
      height: 45px;
      margin-top: 41px;
    }

    .subheading {
      margin-top: 36px;
    }
  }

  footer {
    margin-bottom: 48px;

    .social-links {
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }
}
