@mixin request-intro-numbered-list($accent-color) {
  & {
    padding-bottom: 2rem;
  }

  ol {
    padding: 0 2rem 1rem;

    li {
      counter-increment: number;
      display: flex;
      margin: 0 1.25rem;
      padding: 1rem 0;
      text-align: left;

      &::before {
        @include nurx-digital-font;
        color: $accent-color;
        content: counter(number);
        font-size: 3.75rem;
        min-width: 4rem;
        padding-right: 2rem;
      }
    }
  }
}
