.__admin-banner__9d5f2 {
  $active-banner-color: #cfc;

  .table {
    font-size: 0.9em;
  }

  .active-banner {
    background: $active-banner-color;
  }

  .module-title-padded {
    margin-top: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
