.__acne-intro__f7386 {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__acne-intro__f7386 .intro {
  @include request-intro(pastel-color("blue"), 0);
}

.__acne-intro__f7386 .intro-image {
  height: auto;
  margin: 0 auto;
  max-width: 210px;
}

.__acne-intro__f7386 li {
  padding: 2rem 0;
}
