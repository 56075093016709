.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.w-auto {
  width: auto;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.w-100 {
  width: 100%;
}

.w-66-md {
  @media (min-width: $screen-md-min) {
    width: 66%;
  }
}

.w-50-lg {
  @media (min-width: $screen-lg-min) {
    width: 50%;
  }
}

.mb-12 {
  margin-bottom: 3rem;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}
