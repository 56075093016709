$button-size: 30px;
$button-border-radius: 4px;

$rectangle-border-radius: 10px;
$rectangle-height: 200px;
$small-padding: 5px;

@mixin black-button {
  @include text-bold;
  background-color: grayscale-color("black");
  border: 0;
  border-radius: $button-border-radius;
  color: grayscale-color("white");
  height: $button-size;
}

@mixin width {
  width: $button-size;
}

@mixin rectangle {
  background-color: grayscale-color("lighter-gray");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: dashed 1px grayscale-color("light-gray");
  border-radius: $rectangle-border-radius;
  display: flex;
  height: $rectangle-height;
  padding: $small-padding;
  width: 100%;
}

.__card-side-upload__9c632 {
  @include vertical-spacing-half;
}

/*
Note that this class is duplicated elsewhere.
See: https://nurxeng.atlassian.net/browse/PXE-825
*/
.__card-side-upload__9c632 .not-uploaded {
  @include rectangle;
  justify-content: center;

  .upload {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.__card-side-upload__9c632 .retake {
  @include black-button;
}

.__card-side-upload__9c632 .rotate {
  @include black-button;
  @include width;
}

.__card-side-upload__9c632 .upload {
  align-items: center;
  background: none;
  border: 0;
  display: flex;

  .icon {
    @include black-button;
    @include width;
    align-items: center;
    background-color: bright-color("blue");
    border-radius: 100%;
    color: grayscale-color("white");
    display: flex;
    font-size: 25px;
    justify-content: center;
    margin-right: $small-padding;
  }
}

.__card-side-upload__9c632 .uploaded {
  @include rectangle;
  align-items: flex-end;
  justify-content: space-between;
}
