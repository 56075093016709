.__pinned-notes-panel__14ad5 .pinned-notes-header {
  align-items: center;
  background-color: #383e48;
  color: nurx-color("white");
  display: flex;
  font-size: 0.9em;
  height: 100%;
  padding: 0 $unit-reg;

  svg {
    color: #f2b543;
  }
}

.__pinned-notes-panel__14ad5 .pinned-notes-panel {
  max-width: 35%;
  min-width: 15%;
  position: fixed;
  right: 29%;
  top: 82px;
  z-index: 9;

  .notes-container {
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;

    .panel {
      flex-grow: 1;
      justify-content: center;

      .panel-heading {
        background-color: #fff9cb;
        display: flex;
        justify-content: space-between;

        .left {
          align-items: center;
          display: flex;

          .tack {
            margin-right: 10px;
          }
        }

        .dismiss {
          color: grayscale-color("dark-gray");
        }
      }

      .scroll-container {
        max-height: 60vh;
        overflow: auto;
      }

      .panel-body {
        flex-grow: 1;

        .row {
          flex-grow: 1;

          .list-group {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
