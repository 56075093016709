.nurx_body.questions {
  background: nurx-color("white");

  .container {
    max-width: 600px;
  }

  section.header {
    padding-top: $unit-reg;

    .nurx-doctor-avatar {
      $size: 90px;
      background-image: url("/assets/images/nurx-doctor-avatar.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: $size;
      margin: $unit-sm auto;
      width: $size;

      @media (max-width: $phone) {
        $size: 84px;
        height: $size;
        width: $size;
      }

      @media (max-height: 570px) {
        display: none;
      }
    }

    .nurx-logo-circle {
      $size: 90px;
      background-image: url("/assets/images/nurx-logo-new.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: $size;
      margin: $unit-sm auto;
      width: $size;

      @media (max-width: $phone) {
        $size: 84px;
        height: $size;
        width: $size;
      }

      @media (max-height: 570px) {
        display: none;
      }
    }

    .question-count {
      text-align: left;
    }
  }
}

.nurx_body.questions.intro {
  section.header {
    .nurx-doctor-avatar {
      display: block;
      height: 90px;
      margin: $unit-sm auto;

      @media (max-width: $phone) {
        height: 84px;
      }
    }
  }

  .question-content {
    text-align: center;
  }

  p.intro {
    color: nurx-color-level("black", 2.5);
    font-size: 1.6em;
    line-height: 1.4em;
    margin: $unit-reg 0;
    text-align: center;

    @media (max-width: $phone) {
      font-size: 1em;
      padding: 0 20px;
    }
  }
}

.nurx_body.questions section.question-content {
  .button-group {
    button:first-of-type {
      margin-left: 15px;
    }

    button:last-of-type {
      margin-right: 15px;
    }
  }

  .ratio-label {
    margin-left: 15px;
  }

  .ratio-example {
    background-color: lighten(grayscale-color("black"), 97%);
    padding: 10px;
    text-align: center;
  }

  .question {
    text-align: left;

    label,
    p {
      @include text-bold;
      color: nurx-color-level("black", 2.5);
      font-size: 1.5em;
      line-height: 1.4em;
      margin-bottom: $unit-reg;
      margin-top: $unit-sm;

      @media (max-width: $phone) {
        font-size: 1.2em;
        line-height: 1.3em;
        margin-bottom: $unit-reg;
      }
    }

    &.second {
      margin-top: 20px;
    }

  }

  .read-only-question {
    @include text-bold;
    line-height: 1.4em;
  }

  .read-only-answer {
    @include text-bold;
    line-height: 2em;

    .fa {
      padding-right: 9px;
    }

    .checked {
      .fa {
        color: nurx-color("success");
      }
    }

    .unchecked {
      .fa {
        color: #e6e6e6;
      }
    }
  }

  .fixed-footer-actions {
    label {
      display: none;
    }

    @media (max-width: $phone) {
      background-color: nurx-color("white");
      bottom: 0;
      box-shadow: $box-shadow-standard;
      left: 0;
      padding-bottom: $unit-reg;
      padding-top: $unit-reg;
      position: fixed;

      label {
        display: block;
      }
    }
  }

  .answers {
    padding: $unit-reg;

    button.answer,
    button.btn {
      @extend .btn;
      @include text-bold;
      background: nurx-color("white");
      border: 1px solid #e4e4e4;
      color: nurx-color("accent");
      float: left;
      margin-bottom: $unit-reg !important;
      width: 48%;

      // DEFAULT STATES
      &:hover {
        color: nurx-color("accent");
      }

      &:active,
      &:focus {
        border-color: nurx-color("accent");
        color: nurx-color("accent");
        outline: none;
      }

      &:nth-child(even) {
        float: right;
      }

      // IF SELECTED STATES
      &.selected {
        background-color: nurx-color("accent");
        border-color: nurx-color("accent");
        color: nurx-color("white");

        &:focus,
        &:hover {
          background-color: nurx-color-level("accent", 1.5);
          border-color: nurx-color-level("accent", 1.5);
        }
      }

      &.fullwidth {
        width: 100%;
      }

      &.none-of-the-above {
        @extend .btn-primary;
        float: none;
        position: relative;
        width: 100%;
      }

      @media (min-width: $tablet) {
        font-size: 1.3em;
      }
    }

    &.multiple,
    &.toggle {
      button.btn {
        font-size: 1.2em;

        @media (max-width: $phone) {
          font-size: 1.16em;
          padding-left: $unit-sm;
          padding-right: $unit-sm;

          &:not(.none-of-the-above) {
            &:not(.fullwidth) {
              min-height: 70px;
            }
          }
        }
      }
    }
  }

  .flex-start {
    justify-content: flex-start;
  }

  .space-evenly {
    justify-content: space-evenly;
  }
}

section.footer-controls {

  $link-padding: 13px;
  $link-padding-vertical-lg: 22px;
  $edge-side-padding: 48px;

  $arrow-position: $link-padding;

  @extend %iphone-padding-bottom;
  @extend %box-sizing-initial; // Necessary for iPhone X
  background: nurx-color("white");
  border-top: 1px solid grayscale-color("light-gray");
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;

  .container {
    max-width: 600px;
  }

  .previous,
  .next,
  .help {
    padding-left: 0;
    padding-right: 0;

    a {
      @include text-bold;
      color: nurx-color-level("black", 4);
      font-size: 1.5em;
      line-height: 1em;
    }
  }

  a {
    display: inline-block;
    padding-bottom: $link-padding;
    padding-left: $link-padding;
    padding-right: $link-padding;
    padding-top: $link-padding;
    width: 112px;

    @media(min-width: $phone) {
      padding-bottom: $link-padding-vertical-lg;
      padding-top: $link-padding-vertical-lg;
    }
  }

  .loading a {
    display: inline-block;
    width: 166px;
  }

  .previous a,
  .next a {
    &::before {
      background-image: url("/assets/images/questions/previous-arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: 24px;
      position: absolute;
      top: $link-padding - 1px;
      width: 24px;

      @media(min-width: $phone) {
        top: $link-padding-vertical-lg - 1px;
      }
    }
  }

  .previous {
    a {
      padding-left: $edge-side-padding;

      &::before {
        background-image: url("/assets/images/questions/previous-arrow.svg");
        left: $arrow-position;
      }
    }
  }

  .help {
    text-align: center;
  }

  .next {
    text-align: right;

    a {
      padding-right: $edge-side-padding;

      &::before {
        background-image: url("/assets/images/questions/next-arrow.svg");
        right: $arrow-position;
      }
    }

  }
}

section.takeover-footer {
  left: 0;
}

.progress-bar-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}
