.__user-messenger__8649b .user-messenger-welcome-message {
  background-color: pastel-color("blue");
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;

  h3 {
    margin-bottom: 20px;
  }

  p {
    a.tel {
      font-weight: bold;
    }
  }
}
