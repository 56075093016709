.__icon-button__af75a .text-white {
  color: nurx-color("white");
}

.__icon-button__af75a {
  @include icon;
  align-items: center;
  background-color: inherit;
  border-width: 0;
  color: #656665;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  justify-content: center;
  padding-top: 2px; // hard-coded offset to center the icon vertically
  user-select: none;
}

.__icon-button__af75a.default {
  &:hover {
    svg {
      color: nurx-color("accent");
    }
  }
}

.__icon-button__af75a.danger {
  &:hover {
    svg {
      color: nurx-color("danger");
    }
  }
}

.__icon-button__af75a.invalid {
  &:hover {
    svg {
      color: nurx-color("danger");
    }
  }

  svg {
    color: nurx-color("danger");
  }
}

.__icon-button__af75a.disabled {
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed;

    svg {
      color: grayscale-color("gray");
      cursor: not-allowed;
    }
  }

  svg {
    color: grayscale-color("gray");
    cursor: not-allowed;
  }
}
