$_vertical-spacing-px-count: 20;
$vertical-spacing-none: 0;
$vertical-spacing: $_vertical-spacing-px-count + px;                 // 20px
$vertical-spacing-quarter: ($_vertical-spacing-px-count / 4) + px;   // 5px
$vertical-spacing-half: ($_vertical-spacing-px-count / 2) + px;      // 10px
$vertical-spacing-double: ($_vertical-spacing-px-count * 2) + px;    // 40px
$vertical-spacing-quadruple: ($_vertical-spacing-px-count * 4) + px; // 80px

.vertical-spacing-quarter {
  @include vertical-spacing-quarter();
}

.vertical-spacing-half {
  @include vertical-spacing-half();
}

.vertical-spacing {
  @include vertical-spacing();

  &.shipping-preference-continue {
    margin-top: 100px;
  }
}

.vertical-spacing-double {
  @include vertical-spacing-double();
}

.margin-auto {
  margin: auto;
}

.margin-top-neg-xs {
  margin-top: -$unit-xs;
}

.margin-top-xs {
  margin-top: $unit-xs;
}

.margin-top-sm {
  margin-top: $unit-reg;
}

.margin-top-md {
  margin-top: $unit-md;
}

.margin-top-lg {
  margin-top: $unit-lg;
}

.margin-top-account-page {
  margin-top: 40px;
}

.margin-left-xs {
  margin-left: $unit-xs;
}

.margin-left-sm {
  margin-left: $unit-reg;
}

.margin-left-md {
  margin-left: $unit-md;
}

.margin-left-lg {
  margin-left: $unit-lg;
}

.margin-right-xs {
  margin-right: $unit-xs;
}

.margin-right-sm {
  margin-right: $unit-reg;
}

.margin-right-md {
  margin-right: $unit-md;
}

.margin-right-lg {
  margin-right: $unit-lg;
}

.margin-bottom-xs {
  margin-bottom: $unit-xs;
}

.margin-bottom-sm {
  margin-bottom: $unit-reg;
}

.margins-bottom-sm>* {
  @extend .margin-bottom-sm;
}

.margin-bottom-md {
  margin-bottom: $unit-md;
}

.margin-bottom-lg {
  margin-bottom: $unit-lg;
}

.padding-top-xs {
  padding-top: $unit-xs !important; // !important OK
}

.padding-top-sm {
  padding-top: $unit-reg;
}

.padding-top-md {
  padding-top: $unit-md;
}

.padding-top-lg {
  padding-top: $unit-lg;
}

.padding-bottom-xs {
  padding-bottom: $unit-xs !important; // !important OK
}

.padding-bottom-sm {
  padding-bottom: $unit-reg;
}

.padding-bottom-md {
  padding-bottom: $unit-md;
}

.padding-bottom-lg {
  padding-bottom: $unit-lg;
}

.padding-left-xs {
  padding-left: $unit-xs;
}

.padding-left-sm {
  padding-left: $unit-sm;
}

.padding-left-reg {
  padding-left: $unit-reg;
}

.padding-right-xs {
  padding-right: $unit-xs;
}

.padding-right-sm {
  padding-right: $unit-sm;
}

.padding-right-reg {
  padding-right: $unit-reg;
}

.padding-xs {
  padding: $unit-xs;
}

.padding-sm {
  padding: $unit-reg;
}

.padding-md {
  padding: $unit-md;
}

.no-margin-top {
  margin-top: 0 !important; // !important OK
}

.no-margin-bottom {
  margin-bottom: 0 !important; // !important OK
}

.no-margin-left {
  margin-left: 0 !important; // !important OK
}

.no-margin-right {
  margin-right: 0 !important; // !important OK
}

.no-margin-center {
  margin: 0 auto;
}

.no-margin {
  margin: 0;
}

.no-padding-top {
  padding-top: 0 !important; // !important OK
}

.no-padding-bottom {
  padding-bottom: 0 !important; // !important OK
}

.no-padding-right {
  padding-right: 0 !important; // !important OK
}

.no-padding-left {
  padding-left: 0 !important; // !important OK
}

.no-padding-left-right {
  padding-left: 0;
  padding-right: 0;
}
