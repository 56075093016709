.__ui-patient__brand-logos__db0b4 {
  .brands-divider {
    background-color: brand-color("tm-primary");
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 4px;
    margin-top: 24px;
  }

  .brand-logos {
    background-color: grayscale-color("lightest-gray");
    border: 1px solid rgba(189, 217, 224, 0.4);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 0;
    padding: 30px;
  }

  .tm-logo {
    width: 50%;
  }

  .brand-logo {
    width: 30%;
  }
}
