// bright-color("pink") is same as rgb(249, 216, 216):
$bright-pink-transparent: rgba(249, 216, 216, 0.75);

// bright-color("red") is same as rgb(25, 70, 61):
$bright-red-transparent: rgba(255, 70, 61, 0.4);
$bright-red-fully-transparent: rgba(255, 70, 61, 0);

@keyframes pulseInBrightRed {
  0% {
    box-shadow: 0 0 3px 0 $bright-red-transparent;
  }

  65% {
    box-shadow: 0 0 3px 8px $bright-red-transparent;
  }

  90% {
    box-shadow: 0 0 3px 8px $bright-red-fully-transparent;
  }
}

.__video-playback__fca7d {
  position: relative;
}

.__video-playback__fca7d video {
  width: 100%;
}

.__video-playback__fca7d .preview-badge {
  // positioning:
  background-color: $bright-pink-transparent;
  font-size: 0.85em;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.__video-playback__fca7d .recording-in-progress-icon {
  // for pulse outline:
  animation: pulseInBrightRed 1.75s infinite alternate linear;
  border: 0;
  border-radius: 50%;
  color: bright-color("red");
  font-size: 19px;
  outline: none;

  // positioning:
  position: absolute;
  right: 12px;
  top: 12px;
}

.__video-playback__fca7d .video-overlay {
  background-image: url("/assets/images/icons/play.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 68px;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
