// style-namespace "container"
.__container__8b863 {
  // match the gutter width padding:
  $padding: $grid-gutter-width;

  &.container-padded-top {
    padding-top: $padding;
  }

  &.full-screen-height {
    display: flex;
    flex: 1;
    flex-flow: column;
    min-height: calc(100vh - #{$iphonex-top-spacing});
    padding-bottom: 15px;
    padding-top: 0;

    &.no-header-and-footer {
      min-height: calc(100vh - #{$app-navbar-height} - #{$iphonex-top-spacing});
    }

    &.header-and-no-footer {
      min-height: calc(100vh - #{$app-header-height} - #{$iphonex-top-spacing});
    }

    &.header-and-footer {
      min-height: calc(100vh - #{$app-header-height} - #{$app-navbar-height} - #{$iphonex-top-spacing});
    }
  }

  &.full-screen-height.container-padded-top {
    padding-top: 15px;
  }
}
