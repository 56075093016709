.__cross-care-call-to-action__02e93 .header {
  @include vertical-spacing;
  @include text-bold;
  @include text-big;
  color: grayscale-color("black");
  margin-bottom: 20px;
  margin-top: 40px;
}

.__cross-care-call-to-action__02e93 .footer {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: grayscale-color("black");
    text-decoration: underline;
  }
}

.__cross-care-call-to-action__02e93 .cta-tile-container {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
}
