.__admin__items__confirmation-modal__b50c3 .background {
  background-color: grayscale-color("black");
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
}

.__admin__items__confirmation-modal__b50c3 .confirmation-modal {
  background-color: grayscale-color("white");
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 20%;
  margin: 0 40%;
  overflow: hidden;
  padding: 16px 24px;
  position: absolute;
  width: 20%;
  z-index: 7;

  .header {
    align-items: flex-start;
    border-bottom: 1px solid $confirmation-border-gray;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 16px;

    .header-text {
      color: grayscale-color("black");
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .body {
    color: $confirmation-body-text;
    font-size: 16px;
    font-weight: 400;
    height: calc(100% - 121px);
  }

  .footer {
    align-items: flex-end;
    border-top: 1px solid $confirmation-border-gray;
    display: flex;
    flex-direction: row;
    height: 71px;
    text-align: right;
    width: 100%;

    .go-back-button {
      background-color: $confirmation-body-text;
      border-radius: 4px;
      color: grayscale-color("white");
      font-size: 16px;
      font-weight: 600;
      height: 39px;
      margin-right: 24px;
      text-align: center;
      width: 96px;
    }

    .confirm-button {
      background-color: $mid-blue;
      border-radius: 4px;
      color: grayscale-color("white");
      font-size: 16px;
      font-weight: 600;
      height: 39px;
      text-align: center;
      width: 96px;
    }
  }
}
