.__home__call-to-action__85865 .nurx-font {
  font-family: NurxDigital;
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  margin-bottom: $vertical-spacing;
  user-select: none;
}

.__home__call-to-action__85865 .cta-image {
  width: 100%;
}

.__home__call-to-action__85865 .cta-button-link {
  @include button;
}

.__home__call-to-action__85865 .cta-text-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-weight: $font-weight-bold;
  line-height: $vertical-spacing;
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
}

.__home__call-to-action__85865 .cta-content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $vertical-spacing;
}

.__home__call-to-action__85865 .background-green {
  background: pastel-color("green");
}

.__home__call-to-action__85865 .background-pink {
  background: pastel-color("pink");
}

.__home__call-to-action__85865 .background-purple {
  background: pastel-color("purple");
}
