.__admin__remove-medication-checkbox__92a6d {
  @include icon(14px);
  border: 1px solid grayscale-color("gray-five");
  height: 30px;
  min-width: 30px;

  input {
    &[type="checkbox"] {
      height: 0;
      opacity: 0;
      width: 0;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &[aria-checked] {
    background-image: url("/assets/images/admin-dashboard/icons/remove-medication.svg");
  }
}
