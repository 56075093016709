.__admin__user__renewal__24a73 .renewal {
  .panel-heading {
    display: flex;
    justify-content: space-between;
  }

  .panel-actions-highlight {
    background-color: $panel-background-color;
    flex-direction: column;
    padding: 12px;

    .panel-actions-highlight-row  * + * {
      margin-left: 14px;
    }

    .panel-actions-highlight-row  + .panel-actions-highlight-row {
      margin-top: 14px;
    }

    button {
      margin-bottom: 0;
    }

    label {
      white-space: nowrap;
    }

    .spacer {
      border-left: 1px solid grayscale-color("medium-gray");
      width: 2px;
    }
  }

  .body-container {
    .claim-row {
      align-items: center;
      color: grayscale-color("dark-gray");
      display: flex;
      justify-content: space-between;

      p {
        color: grayscale-color("gray");
      }

      .claim-checkbox {
        accent-color: grayscale-color("black");
        height: 24px;
        width: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
