.__styles__cart__summary__ba494 .detail + .subtotal,
.__styles__cart__summary__ba494 .subtotal + .summary {
  border-top: 2px solid grayscale-color("lighter-gray");
  padding-top: $vertical-spacing;
}

.__styles__cart__summary__ba494 .summary {
  margin-top: 8px;

  span {
    font-weight: $font-weight-bold;
  }
}

.__styles__cart__summary__ba494 .section-header {
  align-items: center;
  border-top: 2px solid grayscale-color("lighter-gray");
  display: flex;
  justify-content: center;
  margin-bottom: $vertical-spacing;
  margin-top: $vertical-spacing-half;

  .header-text {
    background-color: nurx-color("white");
    margin-top: -$vertical-spacing-half;
    padding-left: $unit-reg;
    padding-right: $unit-reg;
  }
}

.__styles__cart__summary__ba494 .line-subtext {
  font-size: 10px;
  line-height: 20px;
  color: grayscale-color("gray");
  margin-bottom: 10px;
  margin-top: -10px;
}

.__styles__cart__summary__ba494 .line-description {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  color: grayscale-color("gray");
  max-width: 250px;
}

.__styles__cart__summary__ba494 .checkbox-text-label {
  @include text-bold;
}

.__styles__cart__summary__ba494 .set-alignment {
  align-items: flex-start;
}

.__styles__cart__summary__ba494 .summary-card {
  border: 1px solid grayscale-color("light-gray");
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0 0;

  .summary-card-title {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 10px 0 18px;
    display: flex;
    align-items: center;
  }

  .summary-card-icon {
    margin-left: auto;
    margin-right: 10px;

    &.active {
      transform: rotate(180deg);
    }
  }

  .summary-card-line {
    border-top: 1px solid grayscale-color("light-gray");
    padding: 8px 0;
    position: relative;
    z-index: 1;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &.summary-card-collapsible {
    margin: 16px 0 24px;
    background-color: grayscale-color("lighter-gray");

    .summary-card-title {
      padding-bottom: 10px;
    }

    + .summary {
      border-top: 1px solid grayscale-color("light-gray");
      margin-top: 0;
    }
  }

  .summary-card-description {
    margin-bottom: 8px;
  }

  .summary-card-note {
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 20px;
    color: grayscale-color("gray");
  }

  .summary-card-collapse {
    margin-top: 10px;

    &[aria-hidden="true"] {
      display: none;
    }
  }
}

.__styles__cart__summary__ba494 .checkout-content-test-wrapper {
  padding-bottom: 30px;
}

.__styles__cart__summary__ba494 .encapsulating-rectangle {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: #F8F8F8;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.__styles__cart__summary__ba494 .social-proof-heading {
  font-family: "Graphik", sans-serif;
  font-size: 14px;
  font-weight: 550;
  line-height: 18px;
  text-align: center;
  color: #666;
  margin-bottom: 15px;
  max-width: 330px;
  width: 100%;
}

.__styles__cart__summary__ba494 .social-proof-frame {
  min-width: 370px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 16px;
  background-color: #FFF;

  .social-proof-stars {
    height: 16.92px;
    align-self: flex-start;
  }

  .testimonial-text {
    font-family: "Graphik", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333;
    padding-top: 10px;
  }

  .attribution {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    margin-top: 12px;
  }
}

.__styles__cart__summary__ba494 .medication-efficacy-frame {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 8px 16px 8px 16px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tretinoin-tube {
    height: 120px;
    width: auto;
    align-self: center;
  }

  .medication-factoid {
    font-family: "Graphik", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #333;
    flex-grow: 1;
    margin-right: 8px;

    b {
      font-weight: bold;
    }
  }
}
