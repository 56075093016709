.__styles__cart__selection__45d4f .checkout-banner {
  background-color: pastel-color("blue");

  .image-container {
    align-items: flex-end;
  }
}

.__styles__cart__selection__45d4f .payment-method {
  display: flex;
  padding-bottom: $vertical-spacing;

  .cash {
    margin-right: 30px;
  }
}

.__styles__cart__selection__45d4f .payment-method-inline {
  flex-flow: row;
}

.__styles__cart__selection__45d4f .payment-method-newline {
  flex-flow: column;

  & button {
    margin: $vertical-spacing-half 0;
  }
}
