// Profile Create / Shared Styles
.__styles__pages__profile__bdef9 .nurx_body.profile.create,
.__styles__pages__profile__bdef9 .nurx_body.profile.summary {
  background: nurx-color-level("black", 9.5);

  .container {
    max-width: 600px;
  }

  header {
    padding-top: $unit-reg;

    h1 {
      @include text-bold;
      color: nurx-color-level("black", 2.5);
      font-size: 1.6em;
      line-height: 1.4em;
      margin-top: $unit-reg;
      text-align: center;
    }

    .container {
      @extend %iphone-padding-top;
    }

    .nurx-doctor-avatar {
      background-image: url("/assets/images/nurx-doctor-avatar.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 90px;
      margin: $unit-sm auto 0;
      width: 90px;

      @media (max-width: $phone) {
        height: 84px;
        position: relative;
        width: 84px;
      }

      @media (max-height: 575px) {
        display: none;
      }
    }
  }
}

// Profile Create / Create Only (not review)
.__styles__pages__profile__bdef9 .nurx_body.profile {
  .oc-section {
    text-align: center;

    h2 {

      @include text-normal;
      color: nurx-color-level("black", 3);
      display: inline-block;
      font-size: 20px;
      position: relative;
      text-align: center;
      width: auto;

      &::before {
        background: nurx-color-level("black", 8);
        content: " ";
        height: 2px;
        left: -55px;
        position: absolute;
        top: 8px;
        width: 45px;
      }

      &::after {
        background: nurx-color-level("black", 8);
        content: " ";
        height: 2px;
        position: absolute;
        right: -55px;
        top: 8px;
        width: 45px;
      }
    }
  }

  section.question-content {
    padding-bottom: 4em;

    label {
      @include text-bold;
      color: nurx-color-level("black", 2.5);
      display: block;
      font-size: 1.2em;
      margin: $unit-md 0 $unit-xs;

      @media (max-width: $phone) {
        font-size: 1.18em;
        padding-left: 2px;
      }
    }

    input {
      border: 1px solid #e4e4e4;
      border-radius: $border-radius-standard;
      box-shadow: none;
      color: nurx-color("black");
      font-size: 1.3em;
      padding: 9.5px 14px 8px;
      width: 100%;

      &:focus {
        border-color: nurx-color("accent");
      }

      &.validated-invalid {
        border: 1px solid nurx-color("danger");
        color: nurx-color("danger");
      }
    }

    .validated-invalid input {
      border: 1px solid nurx-color("danger");
      color: nurx-color("danger");
    }

    .validated-valid input {
      color: nurx-color("accent");
    }

    input.date {
      appearance: none;
    }

    input.height {
      font-size: 1.5em;
      text-align: center;
      width: 45%;
    }

    input.weight {
      margin-right: $unit-sm;
      text-align: center;
      width: 50%;
    }

    a.continue {
      background: nurx-color("primary");
      float: right;

      &.disabled {
        background: #cacaca;
      }
    }
  }
}

// Profile Create / Toggle Button Styles
.__styles__pages__profile__bdef9 .nurx_body.profile.create {
  section.question-content {
    input::placeholder {
      color: nurx-color-level("black", 7);
    }
  }
}

// Summary Styles Only
.__styles__pages__profile__bdef9 .nurx_body.profile.summary {
  .profile-summary {
    padding-bottom: $unit-lg;

    ul {
      margin-top: $unit-md;

      @media (max-width: $phone) {
        margin-left: -$unit-md;
        margin-top: 0;
      }
    }

    li {
      @include text-bold;
      border-bottom: 1px solid #e4e4e4;
      float: right;
      font-size: 1.2em;
      padding-left: $unit-xs;
      width: 100%;

      &:last-child {
        border: 0;
      }

      @media (max-width: $phone) {
        padding-left: $unit-md;
      }
    }

    p {
      margin: 16.8px 0 15.1px;
      padding: 0;

      &.question {
        color: nurx-color-level("black", 2.5);
      }

      &.answer {
        color: nurx-color("accent");
      }
    }

    a.edit {
      background: nurx-color("primary");
    }
  }
}
