.__admin__user__address__item__c1b6c .user-address {
  &.panel {
    &.panel-default {
      &.primary-address {
        border-color: $brand-info;
      }
    }

    .panel-body {
      display: flex;
      justify-content: space-between;
      min-height: 110px; // Ensure that addresses with 2 lines and 3 are the same height

      ul {
        flex-grow: 2;
      }

      .primary-address-label {
        align-self: flex-start;
      }

      .addressee {
        text-transform: capitalize;

        label {
          font-weight: normal;
          margin-bottom: $unit-xs;
        }
      }
    }
  }
}
