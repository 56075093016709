$banner-full-height: 66vh;

.__checkout-banner-v2__79b3b .checkout-banner {
  background: pastel-color("blue");
  margin-bottom: $vertical-spacing;
  padding: 2rem;
}

// On mobile, the color background should span the
// full width of the container. In order to do that
// we need to break from the inherited column flow
// (which has a padding). We then added a spacer
// afterwards so that the rest of the content flows
// naturally beneath it.
@include media-breakpoint-down(sm) {
  $gutter-margins: 15px;

  .checkout-banner {
    margin-left: -$gutter-margins;
    margin-right: -$gutter-margins;
  }
}

.__checkout-banner-v2__79b3b .full-height {
  height: $banner-full-height;
  position: relative;
}

.__checkout-banner-v2__79b3b h1 {
  @include nurx-digital-font;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 48px;
  margin-bottom: $vertical-spacing;
}

.__checkout-banner-v2__79b3b h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: $vertical-spacing;
}
