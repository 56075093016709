// Universal Card Styles
.card ~ .card {
  margin-top: $unit-sm;
}

.card .card {
  border-bottom: 0;
}

.card {
  background: nurx-color("white");
  border-bottom: 2px solid #dedede;
  border-radius: $border-radius-standard;
  position: relative;
}

.skyblue-background {
  background-color: $info-header-blue;
}

.card-row {
  position: relative;
}

.card-header {
  border-bottom: 2px solid #f3f3f3;
  color: #444;
  padding-bottom: $unit-sm;
  padding-top: $unit-reg;
  position: relative;

  .title {
    @include text-bold;
    font-size: 1.1em;
  }
}

.card-content {
  display: table;
  font-size: 1.1em;
  width: 100%;

  @include bp(large) {
    padding: $unit-reg;
  }

  &:not(:last-child) {
    border-bottom: 2px solid #f7f7f7;
  }

  &:only-child {
    border-bottom: 2px solid #f7f7f7;
  }
}

.onboarding-icon,
.onboarding-message {
  float: none;
  height: 100%;
  vertical-align: middle;
}

.onboarding-main-msg {
  color: #565d67;
}
