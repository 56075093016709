// Compose Box / Template List
.communication-compose {
  .template-list {
    background: nurx-color("white");
    border: 1px solid grayscale-color("light-gray");
    border-radius: $unit-sm;
    bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: #494d55;
    position: fixed;
    right: calc((100vw - 210px) * 0.33);
    width: 50%;
    z-index: 1;

    .template-list-header {
      border-radius: $unit-sm $unit-sm 0 0;

      .title-container {
        background: #f9f9fb;
        border-radius: $unit-sm $unit-sm 0 0;
        display: flex;
        justify-content: space-between;
        padding: $unit-md 30px $unit-sm $unit-md;

        button.close {
          color: grayscale-color("black");
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }

      .filters-container {
        border-bottom: 1px solid grayscale-color("light-gray");
        display: flex;
        justify-content: space-between;
        padding: $unit-sm 20px $unit-sm $unit-md;

        .query-filter {
          border-bottom-width: 3px;
          margin-bottom: 0;
          max-width: 40%;

          &::placeholder {
            color: #aaa;
          }
        }

        .btn-group,
        .refined {
          flex-grow: 1;
          max-width: 28%;
          min-width: 25%;
        }

        button {
          width: 100%;
        }

        button,
        button.refined {
          color: #494d55;
          margin: 0;
          text-transform: capitalize;
        }
      }
    }

    // Template Container Body
    .template-list-body {
      @include scrollbar (0.5em, #6393e8, hidden);
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      height: 76vh;
      overflow-y: auto;
      padding: $unit-reg $unit-md $unit-sm;

      .panel-hover:hover {
        filter: brightness(98%);
      }

      .panel-body {
        position: relative;

        .mark {
          background-color: #eee;
          border-bottom: 1px solid #58bbee;
          color: grayscale-color("black");
          font-weight: 500;
          padding: 0;
        }
      }

      .pull-right {
        text-transform: capitalize;
      }
    }
  }
}

// Dropdown Styles
.filters-container {
  .dropdown-menu {
    background: nurx-color("white");
    overflow-x: hidden;
    padding: 0;
    text-transform: capitalize;
    width: 100%;

    >li {
      >a:hover {
        background: #eee;
      }
    }
  }
}

// Results: Template Button Styles
button {
  &.btn-older {
    background-color: #eee;
    border: 1px solid grayscale-color("light-gray");
    border-radius: 4px;
    color: grayscale-color("black");
    font-weight: 400;
    margin: 0 $unit-xs $unit-sm 0;
    padding: $unit-xs $unit-reg;
  }
}
