.modal-overlay {
  background-color: grayscale-color("black");
  height: 100%;
  left: 0;
  opacity: 0.75;
  overflow: auto; // Enable scroll if necessary
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  &.warning {
    background-color: nurx-color("danger");
    opacity: 0.9;
  }
}

.modal-content {
  background-color: nurx-color("white");
  border: 1px solid grayscale-color("medium-gray");
  left: 0;
  margin: 5% auto; // % from the top and centered
  max-height: 90%;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 0;
  width: 40%;
  z-index: 2;
}

.modal-fit-content {
  min-width: 40%;
  width: fit-content;
}
