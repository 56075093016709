.__styles__pages__legacy-messenger__bbd1e .messenger-template {
  .site-header {
    position: fixed;
    width: 100%;
    z-index: 99999;
  }

  // Conversation - Container Styles
  .legacy-conversation-container {
    .legacy-messenger-row {
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      position: relative;
      width: 100%;
    }

    &.old {
      opacity: 0.5;
    }
  }

  // Legacy Message General Styles
  .message {
    // stylelint-disable-next-line scss/no-global-function-names
    $message-video-wrapper-aspect-ratio: percentage(9/16);
    clear: both;
    font-size: 1.1em;
    line-height: 1.4em;
    margin: $unit-sm 0;
    max-width: 70%;
    position: relative;

    .legacy-message-container {
      border-radius: 15px;
      max-width: 100%;
      padding: 0;
      position: relative;
    }

    .message-body {
      border-radius: 15px;
      color: grayscale-color("black");
      font-size: 14px;
      letter-spacing: normal;
      line-height: 1.43;
      padding: 10px;

      p {
        // remove bottom margin found on global p elements
        margin-bottom: 0;
      }

      span {
        display: block;
        word-break: break-word;
      }

      img {
        border-radius: $bubble-radius;
        height: auto;
        max-height: 700px;
        max-width: 300px;
        width: 100%;
      }
    }

    // Message Video
    &.message-video {
      max-width: none;
      width: 70%;

      .message-body {
        .video-wrapper {
          height: 0;
          padding-bottom: $message-video-wrapper-aspect-ratio;
          position: relative;

          iframe {
            border-radius: $bubble-radius;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }

      @media (max-width: $phone) {
        width: 85%;
      }
    }

    // Patient Messages will appear right-aligned
    &.message-by-me {
      align-self: flex-end;

      .legacy-message-container {
        display: inline-block;
        position: relative;

        label {
          color: nurx-color("white");
        }

        .message-body {
          background-color: grayscale-color("light-gray");
          color: grayscale-color("black");

          a {
            text-decoration: underline;
          }
        }
      }
    }

    &.message-by-them {
      // Provider messages will appear left-aligned
      align-self: flex-start;

      .legacy-message-container {
        background-color: $message-by-them-body-bg;

        label {
          color: #444;
        }

        &::after {
          background-color: $message-by-them-body-bg;
          border-radius: $bubble-triangle-radius;
          bottom: 12px;
          content: "";
          height: $bubble-triangle-size;
          left: calc((((#{$bubble-triangle-size}) / (-2)) + #{$bubble-triangle-radius}));
          position: absolute;
          transform: rotate(45deg);
          width: $bubble-triangle-size;
          z-index: -1;
        }
      }
    }

    @media (max-width: $phone) {
      font-size: 1.1em;
      line-height: 1.5em;
      max-width: 85%;
    }
  }

  .initial-prompt {
    max-width: 100%;
    text-align: center;

    .prompt-action {
      svg {
        color: nurx-color("success");
      }
    }

    .prompt-message {
      color: nurx-color-level("black", 1.5);
      margin: auto;

      @media (max-width: $phone) {
        @include text-bold;
        font-size: 0.9em;
      }
    }
  }

  // Message Grouping
  .message-group {
    margin-bottom: 0;
    margin-top: 0;

    &.message-group-first {
      padding-bottom: $vertical-spacing-half;
    }

    &.message-group-middle {
      padding-bottom: $vertical-spacing-half;
    }

    &.message-group-last {
      padding-bottom: $vertical-spacing;
    }
  }

  // Meta Info
  .meta-info {
    @include vertical-spacing;
    font-size: 12px;
    position: relative;
    text-align: center;
    width: 100%;

    .timestamp-text {
      @include text-bold;
      @include text-muted;
      margin: 0;
    }

    &.meta-new-convo {
      align-items: center;
      display: flex;

      .text {
        margin-left: 1em;
        margin-right: 1em;
      }

      .spacer {
        border-bottom: 1px solid #dcdcdc;
        flex-grow: 1;
        height: 1px;
      }
    }
  }

  // Now Talking To
  .now-talking-to {
    $avatar-size: 32px;
    @include vertical-spacing-half;
    display: flex;

    .avatar {
      background-color: grayscale-color("light-gray");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: calc(#{$avatar-size} / 2);
      height: $avatar-size;
      margin-right: 5px;
      overflow: hidden;
      width: $avatar-size;
    }

    .text {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .text-muted;
      font-size: 12px;
      height: 100%;
      margin: auto 0;
    }
  }

  // Attachment
  .message.message-attachment {
    .legacy-message-container {
      .message-footer {
        position: relative;
      }
    }

    &.message-by-me {
      &::after {
        background-color: $message-by-me-body-bg;
        border-radius: $bubble-triangle-radius;
        bottom: 12px;
        content: "";
        height: $bubble-triangle-size;
        position: absolute;
        right: calc((((#{$bubble-triangle-size}) / (-2)) + #{$bubble-triangle-radius}));
        transform: rotate(45deg);
        width: $bubble-triangle-size;
      }
    }

    &.message-by-them {
      &::after {
        background-color: $message-by-them-body-bg;
        border-radius: $bubble-triangle-radius;
        bottom: 12px;
        content: "";
        height: $bubble-triangle-size;
        left: calc((((#{$bubble-triangle-size}) / (-2)) + #{$bubble-triangle-radius}));
        position: absolute;
        transform: rotate(45deg);
        width: $bubble-triangle-size;
      }
    }
  }

  // Has Unread Message
  .has-unread-message {
    position: fixed;
    width: 100%;
  }

  .has-unread-message-content {
    background: nurx-color("primary");
    border-radius: $bubble-radius;
    color: nurx-color("white");
    cursor: pointer;
    padding: $unit-sm;
    text-align: center;
    width: 200px;
  }

  // Default Entry Message
  .composer-container {
    background: nurx-color("white");
    border-top: 1px solid grayscale-color("light-gray");
    bottom: 0;
    height: auto;
    position: fixed;
    width: 100%;
    z-index: 999;

    .compose-content {
      padding: 8px 0 16px;

      @media (max-width: $phone) {
        padding: 8px;
      }
    }

    .textarea-container {
      display: inline-flex;
      width: calc(100% - 70px);

      textarea {
        appearance: none;
        border: 0;
        font-size: 1.2em;
        line-height: 1.2em;
        margin: 0;
        max-height: 33vh;
        min-height: 40px;
        overflow-y: auto;
        padding: 10px 8px 8px;
        resize: none;
        width: 100%;
        word-wrap: break-word;

        @media (min-width: $phone) {
          font-size: 1.1em;
          padding: 9px 12px;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .attach-btn-container {
      float: left;

      button {
        padding-left: 0;
        padding-right: 0;
        width: 35px;
      }
    }

    .send-btn-container {
      float: right;

      button {
        padding-left: 0;
        padding-right: 0;
        width: 35px;
      }
    }
  }

  // Textarea Styles
  .textarea-container {
    textarea {
      &::placeholder {
        color: #a6b1bd;
      }
    }
  }
}

.__styles__pages__legacy-messenger__bbd1e .app.new.messenger-template {

  $compose-container-height: 75px;

  .app-container {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 $unit-reg;
  }

  .app-body {
    padding: 0;
  }

  .nurx-messenger {
    background: nurx-color("white");
    position: absolute;
    top: calc(#{$site-header-height} + #{$iphonex-top-spacing});
    width: 100%;
  }

  .user-messenger-scroll-body {
    // Note (jacob 2023-11-16): vh calculations are not well supported on mobile devices
    // This led to a bug where a CTA was not displayed without scrolling. The new dynamic
    // viewport height calc accounts for this and is supported on modern iOS operating systems
    // and browser builds, so we will use that where possible and fallback on vh for older devices
    height: calc(100vh - #{$compose-container-height} - #{$site-header-height});
    // stylelint-disable-next-line declaration-block-no-duplicate-properties
    height: calc(100dvh - #{$compose-container-height} - #{$site-header-height});
    -webkit-overflow-scrolling: touch;
    // NOTE(eunice 2018-01-08): has to be scroll, not auto for momentum scrolling
    overflow-y: scroll;
    padding-bottom: $vertical-spacing;
  }

  .has-unread-message {
    bottom: $compose-container-height;
  }

  .has-unread-message-mobile {
    bottom: 19%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .has-unread-message-content {
    margin: 0 auto $unit-sm;
  }
}
