.__checkout-cart-item-box__5c266 .wrapper {
  $min-height-sm: 140px;
  $min-height-lg: 200px;

  &.min-height-sm {
    min-height: $min-height-sm;
  }

  &.min-height-lg {
    min-height: $min-height-lg;
  }
}

.__checkout-cart-item-box__5c266 .col-logo {
  background-color: pastel-color("blue");

  &>.logo-wrapper {
    &.logo-vertical-alignment-above-center {
      // the image below gets vertically centered; setting to <100% let's us vertically place less than halfway down:
      height: 80%;
    }

    &.logo-vertical-alignment-center {
      height: 100%;
    }
  }

  .logo-image {
    height: 100%;
    object-position: center;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .logo-image-contain {
    object-fit: contain;
    padding: 10px;
  }

  .logo-image-cover {
    object-fit: cover;
  }

  &>.col-logo-inner {
    $corner-spacing: 10px;
    bottom: $corner-spacing;
    left: $corner-spacing;
    position: absolute;
    right: $corner-spacing;
    top: $corner-spacing;
  }
}

.__checkout-cart-item-box__5c266 .main-wrapper {
  $padding: $grid-gutter-width / 2;
  @include uniform-padding($padding);
  background-color: grayscale-color("lighter-gray");
  color: grayscale-color("black");

  .price {
    @include text-bold;
  }
}

.__checkout-cart-item-box__5c266 .selection-status {
  $size: 24px;

  @include text-bold;
  height: $size;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: $size;

  &:not(.is-selected) {
    background-color: grayscale-color("white");
    border-color: grayscale-color("light-gray");
    border-width: 1px;
  }

  &.is-selected {
    background-color: grayscale-color("black");
    color: grayscale-color("white");
    line-height: $size;
  }
}

.__checkout-cart-item-box__5c266 .delete-item-button {
  $size: 24px;

  @extend .border-box;
  @include uniform-padding(0);
  background-color: grayscale-color("white");
  border: 0;
  border-radius: 50%;
  color: grayscale-color("light-gray");
  font-size: 10px;
  height: $size;
  left: 0;
  line-height: $size;
  position: absolute;
  text-align: center;
  top: 0;
  width: $size;

  &:hover {
    background-color: grayscale-color("lighter-gray");
    color: grayscale-color("gray");
  }
}

// TODO(gabriel): SC-297925: Remove or baseline this when experiment has a conclusive winner
.__checkout-cart-item-box__5c266 .pricing-display-v2 {
  .main-wrapper {
    padding: 24px;
  }

  .checkout-cart-item-box-title {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .checkout-cart-item-box-description {
    font-size: 12px;
  }

  .checkout-cart-item-box-price-line {
    font-size: 12px;
    margin-bottom: 0;
  }
}
