.__pharmacy-progress-header__3955b .flex {
  display: flex;
}

.__pharmacy-progress-header__3955b .tracker {
  align-items: center;
  background-color: #3044a8;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3,
  p {
    color: nurx-color("white");
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    text-align: center;
  }

  p {
    font-size: 12px;
    letter-spacing: 0.8px;
    margin-bottom: 5px;
  }

  h3 {
    font-size: 18px;
    letter-spacing: normal;
  }
}

.__pharmacy-progress-header__3955b .label-refill {
  color: #8a40a7; /* purple */
}
