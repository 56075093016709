$img-icon-size: 77px;
$icon-vertical-displacement: $img-icon-size / 2;

.img-icon-cta-link-card {
  @extend .text-center;
  @include uniform-padding(0);
  border-radius: 5px;
  margin-top: $icon-vertical-displacement;

  .card-inner {
    overflow: hidden;
  }

  .card-item-text {
    @include uniform-padding(20px);
    color: grayscale-color("dark-gray");
    font-size: 1.2em;

    @media(min-width: $tablet) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .img-icon {
    bottom: $icon-vertical-displacement;
    height: auto;
    margin-bottom: -1 * $icon-vertical-displacement;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: $img-icon-size;
  }
}

.btn.img-icon-cta-link-card-button {
  @include uniform-padding($unit-reg);
  font-size: 18px;
}
