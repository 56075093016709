.__canvas-carousel__2071d {
  $black: grayscale-color("black");

  .carousel-container {
    align-items: center;
    display: flex;
    height: 500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
  }

  .canvas-container {
    display: inline;
    width: 600px;
  }

  .carousel-canvas {
    border: 2px solid $black;
    cursor: grab;
    height: 450px;
    width: 600px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 150px;
  }

  button {
    background-color: $black;
    border: 1px solid;
    border-radius: 10px;
    display: inline-flex;
    min-height: 50px;
    min-width: 50px;
    padding: 5px;

    i {
      align-items: center;
      color: nurx-color("white");
      justify-content: center;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }

  .zoom-group {
    min-height: 100px;
  }

  .rotate-group {
    min-height: 100px;
  }

  .reset-group {
    align-items: center;
    justify-content: center;
    min-height: 50px;

    button {
      align-items: center;
      color: nurx-color("white");
      flex-grow: 1;
      justify-content: center;
      min-width: 100px;
    }
  }

  .selection-group {
    align-items: flex-end;
    flex-grow: 1;
    min-height: 100px;
  }
}
