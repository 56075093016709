.__styles__request__start__jurisdiction__confirm__b47c6 .jurisdiction-select {
  padding: 2rem 0;
}

// --- Start: Styles for experimental JX selection element
.__styles__request__start__jurisdiction__confirm__b47c6 .jx-select-control-label {
  color: grayscale-color("black");
  margin-top: 32px;
}

.__styles__request__start__jurisdiction__confirm__b47c6 .jx-select-control {
  $icon-path: "/assets/images/icons/";
  appearance: none;
  background-color: grayscale-color("white");
  border-color: grayscale-color("gray");
  border-radius: 0;
  /* stylelint-disable declaration-property-value-disallowed-list */
  border-top: none;
  border-left: none;
  border-right: none;
  /* stylelint-enable declaration-property-value-disallowed-list */
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 100%;
  width: 100%;

  // Style the icon image
  background-image: url("#{$icon-path}chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 17px auto;

  option:disabled {
    color: grayscale-color("gray");
  }
}

.__styles__request__start__jurisdiction__confirm__b47c6 .jx-select-control:focus {
  background-color: grayscale-color("lightest-gray");
}

.__styles__request__start__jurisdiction__confirm__b47c6 .jx-select-control:hover {
  background-color: grayscale-color("lighter-gray");
}
// --- End: Styles for experimental JX selection element
