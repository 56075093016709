section.section-actionable {
  padding-top: $unit-md;

  @media (max-width: 420px) {
    padding-top: 0;
  }

  .module {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  .module-heading {
    h1.module-title {
      @include text-bold;
      display: inline-block;
      font-size: 2rem;
      margin: $unit-xs 0 0;

      a {
        font-weight: inherit;
      }
    }
  }

  .module-heading {
    .actions {
      button.btn-link {
        padding: 0;
      }

      .pe-icon {
        padding-right: $unit-sm;
      }
    }
  }

  .panel-default {
    border-color: grayscale-color("gray-five");

    &.panel-subtle-emphasis {
      border: 1px solid $charcoal;

      >.panel-heading {
        background: $light-blue-gray;
        color: bright-color("dark-grey");
      }

      .panel-footer {
        background: $charcoal;
        border-color: $charcoal;
        color: nurx-color("white");
      }
    }
  }

  .panel-child {
    border: 0;
    border-radius: 0;
    border-top: 1px solid #eaeaf1;
  }

  .panel-heading-hoverable {
    cursor: pointer;
  }

  .panel-heading-hoverable:hover {
    background-color: #eeeef5;
  }

  .panel-heading {
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    position: relative;

    .panel-heading-actions {
      right: 0;
      top: $unit-reg;

      &.bottom {
        bottom: $unit-sm;
        top: initial;
      }
    }
  }

  .panel-footer {
    .panel-default {
      display: inline;
      float: left;

      i {
        color: nurx-color-level("accent", 1);
      }

      &.panel-default-selected {
        @include text-bold;
        color: #53575e;
        font-size: 12px;
        line-height: 1.5;
        margin-top: $unit-sm;

        i {
          margin-right: $unit-xs;
        }
      }
    }

    button {
      &:not(:only-of-type) {
        &:not(:last-of-type) {
          margin-right: $unit-sm;
        }
      }
    }
  }

  .panel-body {
    .photo-group-wrapper {
      position: relative;

      .user-photo {
        border: 2px solid nurx-color("accent");
        overflow: hidden;
      }
    }

    .photo-insurance {
      text-align: center;

      .photo-content {
        span:not(.insurance-text) {
          display: none;
        }
      }

      fieldset {
        text-align: center;
      }

      label {
        font-size: 1em;
        margin: 0;
      }

      .user-photo {
        border-radius: 7%/11%;
        cursor: pointer;

        .user-photo-image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          padding-bottom: 70%;
          width: 100%;
        }
      }

      &.photo-insurance-transcription {
        .user-photo {
          @include scrollbar (0.6rem, #eaeaf1, hidden);
          border: 0;
          border-radius: $unit-reg;
          max-height: 240px;
          overflow: auto;
        }

        .user-photo-large {
          border: 0;
          border-radius: $unit-reg;
          max-height: 500px;
        }
      }

      &.enlarged-photo {
        .user-photo-large {
          max-height: 750px;
        }
      }
    }

    &.has-image {
      min-height: $unit-xl;

      .card-image {
        position: relative;

        img {
          border-radius: $unit-xs;
          left: 0;
          max-height: $unit-lg;
          max-width: $unit-xl;
          position: absolute;
        }
      }

      ul {
        margin-left: 120px;
      }
    }

    &.has-insurance {
      .card-image {
        display: inline-block;
        position: relative;

        img {
          border-radius: $unit-xs;
          height: auto;
          width: 100%;
        }
      }

      ul {
        display: inline-block;
      }
    }
  }

  .container-fluid {
    &.max-width-1920 {
      max-width: 1920px;
    }
  }
}

section.section-actionable {
  .empty-state-message {
    color: #838698;
    margin-top: $unit-reg;
  }
}

// Table Grayed out Table Row
section.section-actionable {
  table {
    tr {
      &.disabled {
        color: #babace;
      }
    }
  }
}

section.section-actionable {
  .form-group {
    textarea {
      min-height: $unit-xl;
    }
  }

  .prescriber-form {
    .control-label {
      text-align: left;
    }

    .form-control-static {
      display: inline-block;
    }

    .button-container {
      display: inline-block;
    }

    .btn-link {
      font-size: 12px;
      padding: 0;
    }
  }

  .form-control-active {
    border-color: nurx-color("accent");
    border-width: 2px;
  }
}


.switch-cards {
  margin-bottom: $unit-reg;

  .btn {
    color: #1ba9ff;
    font-size: 1em;

    &:focus {
      outline: none;
    }

    .fa {
      margin: 0;
    }

    .switch-arrows {
      border: 1px solid #e6e7ea;
      border-radius: $unit-xs;
      display: inline-block;
      font-size: 1.1em;
      margin-right: $unit-sm;
      padding: $unit-xs $unit-sm;
    }
  }
}

// FIX: (@rurka) Find universal solution for both full-width to multi-columned interfaces.
//
// Below summary: If content section of admin interface is full width, add padding to the right
// Reason: padding was removed from `container-fluid` to accommodate the messenger column which hugs the right-extremity.
.admin-dashboard {
  .section-actionable {

    // Also, make ".section-actionable" content neutral/agnostic and/or move the top padding to inner modules.
    &.col-12 {
      $extra-padding: 30px;
      $base-right-padding: 15px;
      padding-right: $base-right-padding + $extra-padding;
    }
  }
}

.radio-buttons button {
  margin: 0 $unit-sm $unit-sm 0;
}

// New Insurance Card view
.cards-preview-stacked {
  position: relative;
  width: calc(100% - #{$unit-reg});

  .card-stack {
    background-color: #e8e8ec;
    background-position: center;
    background-size: cover;
    border: 0.3rem solid nurx-color("white");
    border-radius: $unit-xs;
    box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
    margin-bottom: $unit-md;
    padding-bottom: 55%;
    width: 100%;

    &.front {
      left: 0;
      position: relative;
      top: 0;
      z-index: 2;
    }

    &.back {
      left: $unit-reg;
      position: absolute;
      top: $unit-reg;
      z-index: 1;
    }
  }
}
