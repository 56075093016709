// NOTE(anthony 2018-07-16): pulled this scss from forked repo:
// https://raw.githubusercontent.com/nurx/ember-power-calendar/master/app/styles/ember-power-calendar.scss
// We want full control over its styling so instead of trying to override all the classes we just copy it here

.ember-power-calendar-nav-title {
  @include text-bold;
  font-size: 16px;
}

.ember-power-calendar {
  box-sizing: border-box;
  position: relative;
}

.ember-power-calendar-nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.ember-power-calendar-nav * {
  box-sizing: border-box;
}

.ember-power-calendar-days,
.ember-power-calendar-days * {
  box-sizing: border-box;
}

.ember-power-calendar-nav-title {
  flex: 1;
  text-align: center;
}

.ember-power-calendar-row {
  display: flex;
  justify-content: space-between;
}

.ember-power-calendar-weekday {
  align-items: center;
  appearance: none;
  background-color: transparent;
  background-color: initial;
  border: 0;
  border: initial;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  outline: none;
  outline: initial;
  padding: 0;
}

.ember-power-calendar-day {
  align-items: center;
  appearance: none;
  background-color: transparent;
  background-color: initial;
  border: 0;
  border: initial;
  flex: 1 1 100%;
  font-size: inherit;
  justify-content: center;
  outline: none;
  outline: initial;
  padding: 0;
}

.ember-power-calendar-nav-control {
  appearance: none;
  background-color: transparent;
  background-color: initial;
  border: 0;
  border: initial;
  font-size: inherit;
  outline: none;
  outline: initial;
}

// Theme styles
.ember-power-calendar {
  font-size: 14px;
  line-height: 1.42857;
}

.ember-power-calendar-nav {
  line-height: 2;
}

.ember-power-calendar-nav-control {
  font-size: 150%;
  line-height: 1;

  &:focus {
    transform: scale(1.2);
  }
}

.ember-power-calendar-day--selected,
.ember-power-calendar-day--selected:not([disabled]):hover {
  @include text-bold;
}

.ember-power-calendar-day--interactive[disabled] {
  opacity: 0.4;
}

@mixin ember-power-calendar($cell-size: null, $cell-width: $cell-size, $cell-height: $cell-size, $cell-with-spacing-width: $cell-width + 2px, $row-padding-top: 0, $row-padding-bottom: 0, $row-padding-left: 0, $row-padding-right: 0, $row-width: $cell-with-spacing-width * 7 - 2px + $row-padding-left + $row-padding-right, $primary-color: #0078c9, $secondary-color: rgb(252, 240, 238), $nav-button-color: $primary-color, $nav-button-color--focus: lighten($nav-button-color, 10%), $day-default-text-color: #bbb, $day-current-month-color: #f5f7fa, $day-weekday-text-color: #333, $day-current-month-text-color: #656d78, $day-other-month-text-color--hover: $day-current-month-text-color, $day-focus-shadow-color: $primary-color, $day-today-color: #eee, $day-color--hover: #eee, $day-range-bookend-color: lighten($primary-color, 40%), $day-range-start-background-color: $day-range-bookend-color, $day-range-start-background-color--hover: $day-range-start-background-color, $day-range-end-background-color: $day-range-bookend-color, $day-range-end-background-color--hover: $day-range-end-background-color, $day-selected-color: lighten($primary-color, 50%), $day-selected-color--hover: $day-selected-color, $day-selected-text-color: #656d78, $day-selected-text-color--hover: $day-selected-text-color) {
  margin: auto;
  width: $row-width;

  .ember-power-calendar-week:first-child {
    &[data-missing-days="1"] {
      padding-left: $cell-with-spacing-width * 1;
    }

    &[data-missing-days="2"] {
      padding-left: $cell-with-spacing-width * 2;
    }

    &[data-missing-days="3"] {
      padding-left: $cell-with-spacing-width * 3;
    }

    &[data-missing-days="4"] {
      padding-left: $cell-with-spacing-width * 4;
    }

    &[data-missing-days="5"] {
      padding-left: $cell-with-spacing-width * 5;
    }

    &[data-missing-days="6"] {
      padding-left: $cell-with-spacing-width * 6;
    }
  }

  .ember-power-calendar-week:last-child {
    &[data-missing-days="1"] {
      padding-right: $cell-with-spacing-width * 1;
    }

    &[data-missing-days="2"] {
      padding-right: $cell-with-spacing-width * 2;
    }

    &[data-missing-days="3"] {
      padding-right: $cell-with-spacing-width * 3;
    }

    &[data-missing-days="4"] {
      padding-right: $cell-with-spacing-width * 4;
    }

    &[data-missing-days="5"] {
      padding-right: $cell-with-spacing-width * 5;
    }

    &[data-missing-days="6"] {
      padding-right: $cell-with-spacing-width * 6;
    }
  }

  .ember-power-calendar-day,
  .ember-power-calendar-weekday {
    height: $cell-height;
    max-height: $cell-height;
    max-width: $cell-width;
    width: $cell-width;
  }

  .ember-power-calendar-weekdays,
  .ember-power-calendar-week {
    height: $cell-height + 2px;
    padding-left: $row-padding-left;
    padding-right: $row-padding-right;
  }

  .ember-power-calendar-day {
    color: $day-default-text-color;
  }

  .ember-power-calendar-weekdays {
    color: $day-weekday-text-color;
  }

  .ember-power-calendar-nav-control {
    color: $nav-button-color;

    &:focus {
      color: lighten($nav-button-color--focus, 10%);
    }
  }

  .ember-power-calendar-day--current-month {
    background-color: $day-current-month-color;
    color: $day-current-month-text-color;
  }

  .ember-power-calendar-day--today {
    background-color: $day-today-color;
  }

  .ember-power-calendar-day--focused {
    box-shadow: inset 0 -2px 0 0 $day-focus-shadow-color;
  }

  .ember-power-calendar-day--selected.ember-power-calendar-day--range-start {
    background-color: $day-range-start-background-color;
  }

  .ember-power-calendar-day--selected.ember-power-calendar-day--range-end {
    background-color: $day-range-end-background-color;
  }

  .ember-power-calendar-day--highlight {
    background-color: $secondary-color;
    border-radius: 0%;
  }

  .ember-power-calendar-day--selected {
    background-color: $primary-color;
    border-radius: 50%;
    color: $day-selected-text-color;
  }

  .ember-power-calendar-day-grid {
    min-height: (($cell-size+2) * 6);
  }
}

.ember-power-calendar-default {
  $cell-size: 75px;
  padding-top: 15px;

  @media (min-width: $tablet) {
    @include ember-power-calendar($cell-size: 75px, $primary-color: rgb(235, 113, 93), $day-current-month-color: #fff, $day-selected-text-color: #fff);
  }

  @media (max-width: $tablet) {
    @include ember-power-calendar($cell-size: 40px, $primary-color: rgb(235, 113, 93), $day-current-month-color: #fff, $day-selected-text-color: #fff);
  }
}
