.webcam-photo-upload {
  color: nurx-color("white");

  .webcam-photo-upload-backdrop {
    background: rgba(0, 0, 0, 0.83);
    height: 100%;
    left: 0;
    padding: 20px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 99999;
  }

  .text-container {
    font-size: 1.3em;
    margin: 0 auto;
    margin-top: 2em;
    max-width: 650px;
  }

  video {
    border: 2px solid nurx-color("white");
    border-radius: 1em;
    height: auto;
    margin-bottom: 0;
    margin-top: 5vh;
    max-height: 70%;
    max-width: 520px;
    overflow: hidden;
    width: 70%;

    &.webcam-graphic {
      background-color: nurx-color("black");
      background-image: url("/assets/images/webcam-authenticate-graphic.svg");
      background-position-x: 44%;
      background-position-y: 45%;
      background-repeat: no-repeat;
      background-size: 45%;
    }
  }

  button {
    &:not(:only-child) {
      margin: 1em 0.5em;
    }
  }

  a.cancel {
    display: inline-block;
    font-size: 1.1em;
    margin-top: 2em;
  }

  .upload-helper-text {
    p {
      align-items: center;
      color: #808080;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      &.danger {
        color: nurx-color("danger");
      }

      svg {
        margin: 0 0.5em 0 0;
      }
    }
  }

  button.btn-circle {
    background-clip: padding-box;
    border: 0;
    border-radius: 50%;
    height: 5em;
    padding: 0;
    text-align: center;
    width: 5em;
  }

  button.btn-default {
    background: none;
    border: 1px solid nurx-color("white");
    color: nurx-color("white");
    font-size: 1.02em;
    padding: 0.6em 1.3em;
  }
}

.upload-basic-buttons {
  @media (max-width: 767px) {
    bottom: 1em;
    left: 1em;
    position: absolute;
    right: 1em;
  }
}

.upload-canvas {
  border: 1px solid grayscale-color("black");
  display: none;
}
