// style-namespace "screen"
.__screen__8a6c4 {
  &.fixed-header-margin {
    @extend %iphone-padding-top;
    margin-top: $app-header-height - 1px;
  }

  &.fixed-footer-margin {
    margin-bottom: #{$app-navbar-height};
  }

  .screen-column {
    // DIY .offset-lg-3pt5 to center the col-lg-5:
    @include media-breakpoint-up(lg) {
      $used-columns: 5;
      @include make-col-offset-center($used-columns);
    }
  }
}
