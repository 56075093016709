.__admin__items__admin-item-family__07cc6 .panel {
  margin-bottom: 0;
}

.__admin__items__admin-item-family__07cc6 .prescribing-messages-modal-container,
.__admin__items__admin-item-family__07cc6 .cancel-confirm-modal-container,
.__admin__items__admin-item-family__07cc6 .save-confirm-modal-container {
  display: none;
}

.__admin__items__admin-item-family__07cc6 .enabled {
  display: block;
}
// Create a responsive grid-based table that will display our data well
.__admin__items__admin-item-family__07cc6 .item-family-table {
  border-collapse: collapse;
  column-gap: 10px;
  display: grid;
  flex: 1;
  // Column names noted for ease of reference / layout adjustment
  grid-template-columns: minmax(45px, 1fr) // ID
    minmax(228px, 1fr) // Name
    minmax(130px, 1fr) // Unit Config
    minmax(175px, 1fr) // Patient Directions
    minmax(150px, 1fr) // Pharmacy Notes
    minmax(150px, 1fr) // Medication Message
    minmax(249px, 1fr) // Legacy Topic: Needs to be this wide because of the dropdown options
    minmax(140px, 1fr) // Topics
    minmax(50px, 1fr) // Default
    minmax(45px, 1fr) // Allow Subs
    minmax(70px, 1fr) // Allow Dispensing
    minmax(187px, 1fr) // Packaging: Needs to be this wide because of the dropdown options
    minmax(187px, 1fr) // Shipping
    minmax(82px, 1fr) // Default Price ($): Needs to be this size to fit longer prices
    minmax(125px, 1fr); // This is where action buttons appear
  margin: 0;
  max-height: calc(100vh - 280px); // This sizes the panel and allows the table to be vertically responsive. Max height specified to keep table from expanding on items with only a few rows.
  max-width: 95vw;
  min-width: 100%;
  overflow: scroll;
  row-gap: 15px;
  width: auto;
}

.__admin__items__admin-item-family__07cc6 thead,
.__admin__items__admin-item-family__07cc6 tbody,
.__admin__items__admin-item-family__07cc6 .tr-wrapper,
.__admin__items__admin-item-family__07cc6 tr {
  display: contents;
  overflow: hidden;
}

.__admin__items__admin-item-family__07cc6 thead {
  border-bottom: 3px solid grayscale-color("light-gray");
  padding-bottom: 5px;
}

.__admin__items__admin-item-family__07cc6 th {
  background-color: nurx-color("white"); // Added for readability on scrolling effect
  border-bottom: 3px solid grayscale-color("light-gray");
  display: flex; // flex is used to align header text in the cell
  flex-direction: column;
  justify-content: center;
  max-height: 60px; // fixes display bug for empty tables
  padding: 0 0 3px;
  position: sticky; // This fixes the header on scroll
  top: 0; // fixed header to top of table

  &:last-of-type {
    border: 0;
  }
}

.__admin__items__admin-item-family__07cc6 tbody {
  // not used currently
}

.__admin__items__admin-item-family__07cc6 .tr-wrapper {
  border-bottom: 1px solid grayscale-color("light-gray");
  padding: 7px 0 0;
}
