.multi-button-container {
  button {
    &:not(.dropdown-toggle) {
      padding-right: $unit-md;
    }
  }

  button.dropdown-toggle {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

input.multi-button-text-area {
  width: 160px;
}

.dropdown-menu>li.divider {
  margin: 0;
}
