// App orders page specific styling

.module-content.no-orders,
.module-content.app-welcome-msg {
  .app-new-request {
    a {
      @include text-bold;
      background-color: nurx-color("accent");
      border-radius: 50px;
      color: nurx-color("white");
      display: inline-block;
      line-height: 21px;
      padding-bottom: $unit-xs;
      padding-left: $unit-reg;
      padding-right: $unit-reg;
      padding-top: $unit-xs;
    }

    .fa {
      color: nurx-color("white");
    }
  }
}

.module-content.no-orders {
  .inner-icon {
    background-image: url("images/ios-app/orders-purple.svg");
    background-size: 100%;
    height: 38px;
    margin: auto;
    opacity: 0.3;
    width: 42px;
  }
}

.no-order-header {
  position: absolute;
  right: 0;
  top: -61px;

  .app-new-request {
    a {
      @include text-bold;
      background-color: nurx-color("white");
      border-radius: 50px;
      color: #fc6855;
      display: inline-block;
      font-size: 12px;
      line-height: $unit-reg;
      padding-bottom: $unit-xs;
      padding-left: $unit-sm;
      padding-right: $unit-sm;
      padding-top: $unit-xs;
    }
  }
}

.app-order-status {
  .order-name,
  .status {
    color: nurx-color("black");
    font-size: $unit-reg;
  }

  i.fa {
    font-size: 20px;
    padding-right: $unit-xs;
  }

  .timestamp {
    @include text-bold;
    color: #cecfd6;
    font-size: 14px;

    span {
      vertical-align: middle;
    }
  }
}
