// Universal Card Styles
.__styles__layout__card__08edd .card ~ .card {
  margin-top: $unit-sm;
}

.__styles__layout__card__08edd .card .card {
  border-bottom: 0;
}

.__styles__layout__card__08edd .card {
  background: nurx-color("white");
  border-bottom: 2px solid #dedede;
  border-radius: $border-radius-standard;
  position: relative;
}

.__styles__layout__card__08edd .skyblue-background {
  background-color: $info-header-blue;
}

.__styles__layout__card__08edd .card-row {
  position: relative;
}

.__styles__layout__card__08edd .card-header {
  border-bottom: 2px solid #f3f3f3;
  color: #444;
  padding-bottom: $unit-sm;
  padding-top: $unit-reg;
  position: relative;

  .title {
    @include text-bold;
    font-size: 1.1em;
  }
}

.__styles__layout__card__08edd .card-content {
  display: table;
  font-size: 1.1em;
  width: 100%;

  @include bp(large) {
    padding: $unit-reg;
  }

  &:not(:last-child) {
    border-bottom: 2px solid #f7f7f7;
  }

  &:only-child {
    border-bottom: 2px solid #f7f7f7;
  }
}

.__styles__layout__card__08edd .onboarding-icon,
.__styles__layout__card__08edd .onboarding-message {
  float: none;
  height: 100%;
  vertical-align: middle;
}

.__styles__layout__card__08edd .onboarding-main-msg {
  color: #565d67;
}
