.__checkout__cart-item-box-v2__f1b95 {
  border: 1px solid grayscale-color("light-gray");

  .wrapper {
    background-color: grayscale-color("lighter-gray");
  }

  .col-logo {
    background-color: grayscale-color("lighter-gray");


    &>.logo-wrapper {
      &.logo-vertical-alignment-above-center {
        // the image below gets vertically centered; setting to <100% let's us vertically place less than halfway down:
        height: 80%;
      }
    }

    .logo-image {
      height: 100%;
      object-position: center;
      overflow: hidden;
      position: absolute;
      width: 100%;
    }

    .logo-image-contain {
      object-fit: contain;
      padding: 16px;
    }

    .logo-image-cover {
      object-fit: cover;
      padding: 20px 16px;
    }

    &>.col-logo-inner {
      $corner-spacing: 10px;
      bottom: $corner-spacing;
      left: $corner-spacing;
      position: absolute;
      right: $corner-spacing;
      top: $corner-spacing;
    }
  }

  .col-description {
    padding: 20px 16px 20px 0;
    background-color: grayscale-color("lighter-gray");

    .sub-heading {
      font-size: 18px;
    }

    ul {
      list-style-position: inside;
      margin-left: 24px;

      li {
        color: grayscale-color("gray");
        font-size: 12px;
        line-height: 20px;
        list-style-type: disc;
        margin: 0;
        padding-top: 0;
      }
    }
  }

  .spacer {
    padding: 0 16px;
    background-color: grayscale-color("lighter-gray");

    .divider{
      left: 0;
      margin: 0;
      width: 100%;
    }
  }

  .pricing {
    background-color: grayscale-color("lighter-gray");
    padding: 16px;

    li {
      margin-bottom: 4px;

      &.disclaimer {
        display: flex;
        color: grayscale-color("gray");
        font-weight: 500;
        margin-top: 8px;
        padding: 4px 0;

        span {
          font-size: 10px;
        }
      }

      .exclamation-circle {
        margin-right: 4px;
        color: pastel-color("blue");
      }
    }
  }
}
