$screen-gutter: 15px;

.__styles__request__start__jurisdiction__service-not-available__f1ccf .service-not-available {
  flex-grow: 1;
  margin: -$screen-gutter;
}

// HACK (kenny 2024-04-29) - Extra bottom margin adjustment needed
// to bridge the difference between the $app-header-height and the $site-header-height
// The fix is to ensure the Container component is sized appropriately for both
// app and site navigation headers, depending on where the web app is being rendered
.__styles__request__start__jurisdiction__service-not-available__f1ccf .service-not-available.extended-bottom-margin {
  margin-bottom: calc(0px - #{$screen-gutter} - (#{$app-header-height} - #{$site-header-height}));
}
