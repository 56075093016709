.__exam-view__item__fdff8 {
  display: grid;
  grid-template-rows: 4em auto;

  header {
    display: flex;
    flex-direction: column;
    font-size: 11px;
  }

  .item-copy {
    font-size: 12px;
    padding: 0 5px;
    padding-left: 2px;
  }

  .item-preview {
    align-self: flex-end;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
