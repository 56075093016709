.__styles__cart__addons-menu__084cc .menu-container {
  --padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: -15px;
  margin-left: -15px;
  margin-right: -15px;
}

.__styles__cart__addons-menu__084cc .addon-header {
  background-color: pastel-color("blue");
  color: bright-color("black");
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px var(--padding) 16px;
  margin-bottom: 15px;
}

.__styles__cart__addons-menu__084cc .intro {
  font-family: "NurxDigital", "Graphik Web", arial, helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 46px;
  margin-bottom: 0;
  text-align: left;
  user-select: none;
}

.__styles__cart__addons-menu__084cc .product-container {
  padding: 0 var(--padding);
  margin-bottom: 15px;
}

.__styles__cart__addons-menu__084cc .addon-continue {
  margin: 0 var(--padding);
  padding: var(--padding) 0;
}

.__styles__cart__addons-menu__084cc .btn-default {
  background-color: nurx-color("white");
  color: bright-color("black");
  font-size: 12px;
}

.__styles__cart__addons-menu__084cc .global-disclaimer-text {
  font-size: 12px;
  margin-top: auto;
  padding: 0 var(--padding);
}

.__styles__cart__addons-menu__084cc .divider {
  border-top: 1px solid grayscale-color("gray-five");
  left: auto;
  margin: 15px 0;
  width: 100%;
}

.__styles__cart__addons-menu__084cc .footer {
  font-size: 12px;
}

.__styles__cart__addons-menu__084cc .addon-hero {
  display: flex;
  align-items: center;
  padding: 18px 26px;
  background-color: #F8E7D7;
  margin-bottom: 20px;

  .addon-hero-title {
    margin-left: 20px;
  }
}

.__styles__cart__addons-menu__084cc .addon-hero-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: grayscale-color("black");
}

.__styles__cart__addons-menu__084cc .addon-hero-subtitle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: grayscale-color("black");
  margin: 0;
}

.__styles__cart__addons-menu__084cc .addon-hero-image {
  flex: 0 0 80px;
  max-width: 80px;
  height: 80px;
}

.__styles__cart__addons-menu__084cc .addon-spotlight-hero {
  background-color: #E5F0F3;
  padding: 24px 20px 20px;
  margin-bottom: 20px;
  text-align: center;

  .addon-hero-title {
    margin-bottom: 8px;
  }
}

.__styles__cart__addons-menu__084cc .addon-spotlight-border {
  border: 1px solid grayscale-color("light-gray");
  border-radius: 8px;
}

.__styles__cart__addons-menu__084cc .addon-spotlight-hero-image {
  width: 100%;
}

.__styles__cart__addons-menu__084cc .dropdown-toggle {
  text-align: center;
  cursor: pointer;
}

.__styles__cart__addons-menu__084cc .dropdown-info {
  font-weight: 500;
  color: bright-color("black");
  margin: 0;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
  display: inline;
}

.__styles__cart__addons-menu__084cc .dropdown-text {
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.__styles__cart__addons-menu__084cc .dropdown-prompt {
  font-weight: 500;
  color: bright-color("black");
  text-align: center;
  display: block;
  margin: 0;
  padding: 10px 0;
  user-select: none;
}

.__styles__cart__addons-menu__084cc .dropdown-arrow {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
