.__auto-refill-subscription-items-consent-view__1a062 .refill-consent-status-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.__auto-refill-subscription-items-consent-view__1a062 .refill-status-title {
  font-size: 15px;
  font-weight: 500;
}
