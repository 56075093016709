.__admin__minor-badge__6684d {
  background-color: nurx-color("danger");
  border-radius: 0;
  font-size: 9px;
  padding: 1px 2px;

  &:not(:first-child) {
    margin-left: 2px;
  }
}
