// webkit scrollbar appearance
@mixin scrollbar ($width, $thumb-color, $track-color) {
  @if $track-color=="hidden" {
    // Make scrollbar element "invisible" if value is = hidden
    $track-color: rgba(0, 0, 0, 0.01);
  }

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $width;
    box-shadow: inset $width 0 0 $width $thumb-color;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset $width 0 0 $width $track-color;
  }
}
