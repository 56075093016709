// Standard Styles
.__warning-panel__19dd5 .panel-warning {
  border-color: nurx-color("danger");
  display: flex;
  user-select: none;

  .panel-icon {
    align-items: center;
    background-color: nurx-color("danger");
    color: nurx-color("white");
    display: flex;
    justify-content: center;
    padding: $unit-sm;
    width: 40px;
  }

  .panel-heading {
    background: nurx-color("danger");
    border-color: nurx-color("danger");
    color: nurx-color("white");
  }

  .panel-body {
    color: nurx-color("danger");
  }

  // Account-level warning styles
  // This creates a special block style warning that is always present and visually different from our other alerts and notifications
  &.account-level-warning {
    align-items: center;
    background-color: nurx-color("danger");
    border-radius: 0;
    color: nurx-color("white");
    display: flex;
    justify-content: center;
    left: 145px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 145px);
    z-index: 3; // keeps at top level above patient profile images

    .panel-icon {
      padding: 0;
    }

    .panel-body {
      color: nurx-color("white");
      padding: 5px 0;
    }
  }

  // This creates a special block style warning for migrated patients that is always present and visually different from our other alerts and notifications
  &.central-app-patient-warning {
    min-height: 400px;

    .panel-body {
      color: nurx-color("danger");
      font-size: 24px;
      font-weight: bold;

      ul {
        padding-left: 20px;

        li {
          list-style-type: disc;
        }
      }
    }
  }
}
