.__journal-ticket-editor__ff0f9 .card-content {
  padding: 11px 9px 7px;
}

.__journal-ticket-editor__ff0f9 .card-content-header {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;

  .ticket-topic-missing {
    align-items: center;
    display: flex;
    flex-grow: 4;
  }

  .card-content-header-actions {
    align-items: center;
    display: flex;
  }
}

.__journal-ticket-editor__ff0f9 .topic-grid {
  align-items: stretch;
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  row-gap: 10px;

  button {
    font-size: 12px;
  }
}

.__journal-ticket-editor__ff0f9 .topic-header {
  align-items: center;
  display: flex;
  margin: 20px 0 10px;
  text-transform: capitalize;
}
