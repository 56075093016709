.__admin__user__appointments__appointment-modal__ca857 .modal {
  background-color: rgba(0, 0 , 0 , 0.4); /* Fallback color */
  display: block;
  height: 100%; /* Full height */
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  padding-top: 100px; /* Location of the box */
  position: fixed; /* Stay in place */
  top: 0;
  width: 100%; /* Full width */
  z-index: 1; /* Sit on top */
}

/* Modal Content */
.__admin__user__appointments__appointment-modal__ca857 .modal-content {
  background-color: nurx-color("white");
  border: 1px solid nurx-color-level("black", 2.5);
  border-radius: 20px;
  margin: auto;
  padding: 20px;
  width: fit-content;
}

/* The Close Button */
.__admin__user__appointments__appointment-modal__ca857 .close {
  background-color: transparent;
  border: 0;
  color: nurx-color-level("black", 2.5);
  font-size: 20px;
}

.__admin__user__appointments__appointment-modal__ca857 .modal-top {
  border-bottom: 1px solid nurx-color-level("black", 2.5);
  display: flex;
  font-weight: 500;
  justify-content: space-between;
}

.__admin__user__appointments__appointment-modal__ca857 .modal-body {
  border-bottom: 1px solid nurx-color-level("black", 2.5);
  padding-bottom: 10px;

  & label {
    font-weight: normal;
  }

  & .form-group {
    display: flex;
    flex-direction: column;
  }
}

.__admin__user__appointments__appointment-modal__ca857 .form-appointment-status {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.__admin__user__appointments__appointment-modal__ca857 .form-header {
  display: flex;
}

.__admin__user__appointments__appointment-modal__ca857 .modal-bottom {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.__admin__user__appointments__appointment-modal__ca857 .close:hover,
.__admin__user__appointments__appointment-modal__ca857 .close:focus {
  color: nurx-color("danger");
  cursor: pointer;
  text-decoration: none;
}

.__admin__user__appointments__appointment-modal__ca857 .modal-bottom button {
  margin-right: 10px;
}
