.routing-rule-table {
  overflow: visible;

  .table-cell-input-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .table-cell-add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: $unit-xs;
    }
  }
}
