// Expedient override of inherited styles until we can take a more wholistic look at the layout architecture
.__admin__zen-task__zen-fulfill-container__8b878 .zen-fulfill-container-main {
  margin: 10px;
  width: 100%;
}

.__admin__zen-task__zen-fulfill-container__8b878 .zen-fulfill-container-sidebar-refit {
  flex-basis: 150px;
  font-size: 0.9em;
}
