.spin,
.spin-reverse {
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spin {
  animation-name: spin;
}

.spin-reverse {
  animation-name: spin-reverse;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}
