.component-panel-card {

  // Base Styles
  color: #444f67;

  .panel {
    position: relative;

    &::after {
      background: #eeeef5;
      border-bottom-left-radius: $border-radius-standard;
      border-top-left-radius: $border-radius-standard;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color 0.25s ease;
    }
  }

  .panel-body {
    padding: 20px 25px;
  }

  p {
    margin: 0;
  }

  .form-group {
    margin: 0 0 $unit-sm;
  }

  .form-control-static {
    @include text-bold;
    min-height: 10px !important;
    padding: 0 !important;
  }

  .form-group label {
    @include text-normal;
  }

  .form-horizontal .control-label {
    padding-top: 0;
  }

  // Top section
  .panel-card-top {
    cursor: pointer;
  }

  // Heading
  .panel-card-heading {
    @include text-bold;
    font-size: 1.6rem;
    margin: 0;
  }

  // Summary Content
  .panel-card-content {
    margin-top: $unit-sm;

    .label {
      margin-right: $unit-xs;
    }
  }

  // Information in outer card
  .panel-card-info {
    margin-bottom: $unit-xs;
    min-height: 90px;
  }

  // Grayed Out text
  .panel-card-meta {
    margin-top: $unit-sm;
    opacity: 0.4;
  }

  // Cards within Cards
  .panel-card-item-group {
    margin-bottom: 0;
    margin-top: $unit-reg;

    .panel-card-item {
      margin-bottom: $unit-reg;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Actions
  .panel-card-actions {
    text-align: right;
  }
}
