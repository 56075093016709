$guardian-consent-form-pending-color: #bebebe;
$guardian-consent-form-opted-in-color: #393f47;
$guardian-consent-form-revoked-color: #f77c6b;

.__admin__guardian-consent-form__3e959 .guardian-consent-form-pending {
  background-color: $guardian-consent-form-pending-color;
}

.__admin__guardian-consent-form__3e959 .guardian-consent-form-opted-in {
  background-color: $guardian-consent-form-opted-in-color;
}

.__admin__guardian-consent-form__3e959 .guardian-consent-form-revoked {
  background-color: $guardian-consent-form-revoked-color;
}

.__admin__guardian-consent-form__3e959 .set-consent-state-button {
  font-size: 11px;
  margin-left: 4px;
}
