.__admin__user__request__eaaeb .message-builder-closeable-tickets {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.__admin__user__request__eaaeb .warning {
  @include text-bold;
  color: nurx-color("danger");
}

.__admin__user__request__eaaeb .cancel-button {
  background-color: nurx-color("white");
  border: 1px solid grayscale-color("gray");
  color: grayscale-color("gray");
}

.__admin__user__request__eaaeb .body-container {
  .claim-row {
    align-items: center;
    color: grayscale-color("dark-gray");
    display: flex;
    justify-content: space-between;

    p {
      color: grayscale-color("gray");
    }

    .claim-checkbox {
      accent-color: grayscale-color("black");
      height: 24px;
      width: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
