.__checkout-banner__183d8 .checkout-banner {
  background-color: pastel-color("blue");
  height: auto;
  position: relative;

  .content-container {
    display: flex;
    overflow: auto;
    width: 100%;

    .image-container {
      align-items: center;
      display: flex;
      width: 45%;
    }

    .banner-image-caption {
      bottom: $vertical-spacing-half;
      color: grayscale-color("white");
      margin-bottom: 0;
      position: absolute;
      right: $vertical-spacing-half;
    }

    .banner-image {
      width: 100%;
    }

    .text-container {
      padding: 20px;
      width: 55%;
    }
  }
}
