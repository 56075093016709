.__confirmation-button__8ef0c .btn-confirm-component {
  .btn-confirm-container {
    display: inline-block;

    .btn-confirm-state-confirm-container {
      .btn.success {
        // Shouldn't need these but our text-success isn't applying due to hierarchy
        color: #33d7a3;
      }

      .btn.cancel {
        // Shouldn't need these but our text-success isn't applying due to hierarchy
        color: #ff7b7b;
      }
    }
  }
}
