.__styles__user__a82f4 .user-screen-tabs {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .user-screen-tabs {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .user-screen-tabs {
    display: flex;
  }
}
