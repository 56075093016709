$font-family-graphik: "Graphik Web", arial, helvetica, sans-serif;
$font-family-nurx-digital: "NurxDigital", $font-family-graphik;

$font-weight-graphik-regular: 400;
$font-weight-graphik-medium: 500;
$font-weight-graphik-bold: 800;

$font-weight-extra-bold: $font-weight-graphik-bold;
$font-weight-bold: $font-weight-graphik-medium;
$font-weight-normal: $font-weight-graphik-regular;
