.__auto-refill-subscription-item-consent__35dc8 .wrapper {
  min-height: 140px;
  margin-bottom: 24px;
}

.__auto-refill-subscription-item-consent__35dc8 .col-logo {
  background-color: pastel-color("blue");
}

.__auto-refill-subscription-item-consent__35dc8 .logo-image {
  height: 100%;
  object-position: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.__auto-refill-subscription-item-consent__35dc8 .main-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 26px;
  background-color: grayscale-color("lighter-gray");
}

.__auto-refill-subscription-item-consent__35dc8 .main-content {
  width: 100%;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.__auto-refill-subscription-item-consent__35dc8 .subheading {
  @include text-bold;
  @include text-medium;
  color: grayscale-color("black");
}

.__auto-refill-subscription-item-consent__35dc8 .input-wrapper {
  width: 100%;
  max-width: 24px;
  display: flex;
  align-items: center;
}

.__auto-refill-subscription-item-consent__35dc8 .no-image {
  background-color: #fcd1cc;
}
