$inactive-background-color: #f1f1f1;

.__jurisdiction-select__b53d7 .header-selected {
  display: flex;
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 0;
  }

  .jurisdiction-select-icon {
    align-self: center;
    font-size: 2.5rem;
    padding: 0 0.8rem;
  }
}

.__jurisdiction-select__b53d7 ul {
  user-select: none;

  li {
    border-bottom: 1px solid grayscale-color("light-gray");
    display: flex;
    font-weight: $font-weight-bold;
    padding: 1rem;

    .jurisdiction-label {
      display: inline-flex;
      width: 100%;
    }

    &:hover {
      border-bottom: 1px solid grayscale-color("gray");
      border-top: 1px solid grayscale-color("gray");
    }

    &.inactive {
      background-color: $inactive-background-color;

      &:hover {
        border-bottom: 1px solid grayscale-color("light-gray");
        border-top: 0;
      }

      .inactive-label {
        align-items: center;
        display: inline-flex;
        font-size: 1rem;
        font-style: italic;
        font-weight: normal;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}
