// NOTE: There is a copy-paste of this code in styles/pages/login-template. All details there. Dec 19, 2016. -@MRurka.
// Remove this only if and when the issue in styles/pages/login-template has been rectified :thumbsup:

.component-promo-code {
  label {
    color: #6e798a;
    font-size: 1.16em;
    margin-bottom: $unit-sm;
    width: 100%;
  }

  button {
    min-height: $unit-md;

    @media (max-width: $tablet) {
      position: relative;
      top: -3px;
    }
  }

  .promo-feedback-message {
    @include text-bold;
    font-size: 1.05em;
    line-height: 1.5em;
    margin: 0 auto;
    max-width: 280px;
  }

  input.form-control {
    display: inline-block;
    max-width: 190px;
    width: auto;
  }
}
