.__styles__redirect__birthdate-confirmation__6624b .nurx-font {
  @include nurx-digital-font;
  font-size: 48px;
  line-height: 58px;
}

.__styles__redirect__birthdate-confirmation__6624b .subheader {
  @include text-bold;
  color: grayscale-color("gray");
  line-height: 22px;
  padding-bottom: 20px;
}

.__styles__redirect__birthdate-confirmation__6624b .content {
  color: grayscale-color("gray");
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 20px;
}

.__styles__redirect__birthdate-confirmation__6624b .dob {
  @include text-bold;
  color: grayscale-color("gray");
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
}
