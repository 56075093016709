.__admin-drug-unit-weight-item__9fef1 tr {
  display: flex;

  &.modified {
    background-color: #d9ffb4;

    td {
      background-color: #ffffe0 !important;
    }
  }
}

.__admin-drug-unit-weight-item__9fef1 td {
  flex: 1;
  flex-direction: row;
  padding: 5px;
  width: 33%;

  input {
    margin-bottom: 5px;
  }
}
