$card-height: 80px;
$card-image-height: 40px;
$card-image-width: 57.47px;

.__payment-card__6ee96 .card-section {
  align-items: center;
  display: flex;
  max-height: $card-height;
  padding-bottom: $vertical-spacing;
}

.__payment-card__6ee96 .card-brand-padding {
  padding-left: $vertical-spacing-half;
  padding-right: $vertical-spacing;
}

.__payment-card__6ee96 .card-brand {
  height: $card-image-height;
  width: $card-image-width;
}

.__payment-card__6ee96 .card-details {
  color: grayscale-color("black");
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
