.__question__input-question-jurisdiction-provider__09008 .display-area {
  @include media-breakpoint-down(md) {
    left: 50%;
    margin-left: -50vw;
    position: relative;
    width: 100vw;
  }
}

.__question__input-question-jurisdiction-provider__09008 .image-wrapper {
  margin: 0 auto;
  position: relative;
  width: 375px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.__question__input-question-jurisdiction-provider__09008 .text-display {
  margin-left: 6%;
  margin-top: 40%;
  max-width: 45%;
  position: absolute;
}

.__question__input-question-jurisdiction-provider__09008 .doctor-image {
  display: block;
  margin: 0 auto;
  width: 375px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.__question__input-question-jurisdiction-provider__09008 .error-message {
  left: 50%;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
