.__reprocess-rejection-panel__f2abf .rejection-reprocess-panel {
  flex-direction: column;

  .fa {
    color: nurx-color("warning");
  }

  h4 {
    @include text-bold;
  }
}
