.__styles__pages__pharmacy-workflow__80581 .zen-verify-container {
  margin: 40px auto;
  width: 1140px;

  h3 {
    color: #616670;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    margin: 5px 0;
  }

  .pharmacy-note {
    background-color: #fbfaed;
    border: 1px solid #edeac6;
    padding: 5px 10px;
    width: 1140px;

    p {
      color: #354052;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.43;
    }
  }
}

.__styles__pages__pharmacy-workflow__80581 .pharmacy-workflow-header-panel {
  border: 1px solid #eceff2;
  border-left-color: #425db5;
  border-left-width: 5px;
  border-radius: 1%;
  padding: 10px 5px;
}

.__styles__pages__pharmacy-workflow__80581 .pharmacy-workflow-body-panel {
  background-color: #f5f7f9;
  border: 1px solid #eceff2;
  padding: 10px 5px;
}

.__styles__pages__pharmacy-workflow__80581 .pharmacy-workflow-card {
  margin: auto;
  width: 500px;

  .pharmacy-workflow-card-header {
    background-color: #4e5076;
    border-radius: 4px;
    height: 64px;
    padding: 13px 20px;

    h3 {
      color: nurx-color("white");
      font-size: 26px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.8px;
      line-height: 1.15;
    }

    &.blue {
      background-color: #425db5;
    }
  }

  .pharmacy-workflow-card-body {
    background-color: nurx-color("white");
    border: 1px solid #e6eaee;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    color: #454b60;
    font-size: 23px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.39;
    min-height: 325px;
    padding: 15px;

    p {
      margin-bottom: 5px;
    }
  }
}

.__styles__pages__pharmacy-workflow__80581 .pharmacy-workflow-btn {
  background-color: #dee2e8;
  border: 1px solid #c6cacf;
  border-radius: 4px;
  color: #515254;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  width: 140px;

  &.blue {
    background-color: #556bda;
    border: 1px solid #3044a8;
    color: nurx-color("white");
  }
}

.__styles__pages__pharmacy-workflow__80581 .pharmacy-workflow-wrapper {
  margin: auto;
  width: 1140px;

  h3 {
    color: #454b60;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.3;
  }

  .pharmacy-workflow-header {
    height: 80px;
    padding: 40px 0;
  }

  .pharm-workflow-warning-banner {
    background-color: rgba(181, 66, 66, 1);
    border-radius: 4px;
    height: 70px;
    margin-bottom: 4px;
    padding: 25px 0;

    h3 {
      color: nurx-color("white");
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
    }
  }

  .pharm-workflow-header-panel {
    background-color: #e5ecf8;
    border-radius: 4px;
    height: 70px;
    margin-bottom: 4px;
    padding: 25px 0;

    h3 {
      color: #354052;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
    }
  }

  .pharm-workflow-body-panel {
    background-color: #e5ecf8;
    padding: 25px 0;
  }

  .scan-placeholder {
    display: block;
    height: 288px;
    margin: 70px auto;
    width: 400px;
  }

  .pharm-workflow-info-box {
    background-color: nurx-color("white");
    border: 1px solid #e6eaee;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;
    min-height: 90px;

    .pharm-workflow-info-box-col {
      padding: 20px;

      &.divider {
        border-right: solid 1px #e6eaee;
      }

      &.no-padding {
        padding: 0;
      }

      &.less-padding {
        padding: 10px;
      }
    }

    .sub-container {
      padding: 0 5px;
    }

    h3 {
      color: #586a82;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.5px;
      line-height: normal;
    }

    p {
      color: #262f3b;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 2px;

      strong {
        font-weight: 800;
      }
    }

    a {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 2px;

      strong {
        font-weight: 800;
      }
    }

    .time-since-display {
      color: #262f3b;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 36px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      margin-top: 19px;
      overflow: hidden;
      text-align: center;
    }

    .time-since-label {
      color: #262f3b;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.5px;
      line-height: normal;
      text-align: center;
    }

    .highlighted-info {
      background-color: #e5ecf8;
      border-radius: 16px;
      color: #262f3b;
      display: inline-flex;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      height: 32px;
      letter-spacing: normal;
      line-height: 1;
      margin: 0 2.5px;
      padding: 6px;
      text-align: center;
      width: 120px;
    }
  }

  .prescription {
    align-items: center;
    background-color: nurx-color("white");
    border: 1px solid #3044a8;
    border-radius: 16px;
    color: #3044a8;
    display: flex;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 30px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.14;
    margin: 5px;
    padding: 0 10px;

    &.approved {
      background-color: rgba(71, 194, 87, 0.3);
      border: 1px solid rgba(71, 194, 87, 0.6);
    }
  }

  .pharm-workflow-card-container {
    align-items: center;
    box-orient: horizontal;
    display: flex;
    flex-flow: wrap row;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .pharm-workflow-card {
    background-color: nurx-color("white");
    border: 1px solid rgba(127, 143, 164, 0.67);
    border-radius: 4px;
    margin: 10px;
    width: 325px;

    .pharm-workflow-card-top {
      min-height: 275px;
      padding: 25px;
      width: 100%;
    }

    .pharm-workflow-card-progress-bar {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;

      .pharm-workflow-card-progress-bar-confirmation {
        background-color: nurx-color("white");
        border: 1px solid rgba(132, 147, 168, 0.6);
        border-style: dashed;
        flex-grow: 1;
        height: 50px;
        min-width: 0;
        padding: 2px;
        text-align: center;

        &.confirmed {
          background-color: #42b58c;
        }

        i {
          color: nurx-color("white");
        }

        h3 {
          color: #7f8fa4;
          font-family: Graphik, Arial, Helvetica, sans-serif;
          font-size: 36px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 800;
          letter-spacing: normal;
          line-height: normal;
          margin-top: 3px;
          text-align: center;
        }
      }
    }

    p {
      color: #454b60;
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;

      .strong {
        font-weight: 500;
      }

      .extra-strong {
        font-weight: 600;
      }
    }
  }
}

.__styles__pages__pharmacy-workflow__80581 .fill-exception {
  h2 {
    color: #454b60;
    font-weight: 300;
    height: 28px;
  }

  .rejection-reason-detail {
    background-color: rgba(230, 234, 238, 0.3);
    border: 1px solid grayscale-color("light-gray");
    height: 110px;
    padding: 10px;
  }

  .cancel-button {
    background-color: nurx-color("white");
    border: 1.1px solid #454b60;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: normal;
    width: 160px;
  }

  .reject-button {
    background-color: #425db5;
    border: 1.1px solid #454b60;
    color: nurx-color("white");
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0.3px;
    line-height: normal;
    width: 160px;
  }
}

/* Clear floats */
.__styles__pages__pharmacy-workflow__80581 .pharm-workflow-page-wrapper {
  clear: both;
  content: "";
  display: table;
  width: 100%;
}

.__styles__pages__pharmacy-workflow__80581 .pharm-workflow-half-page {
  float: left;
  width: 50%;
}

@media screen and (max-width: 2400px) {
  .__styles__pages__pharmacy-workflow__80581 .pharm-workflow-half-page {
    width: 100%;
  }
}
