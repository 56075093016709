.__user-journal__c46ae .user-journal-section {
  margin-top: $vertical-spacing;

  .message-auto-reply {
    background-color: pastel-color("peach-40");
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto auto auto;
    padding: 10px;
    padding-bottom: 0;

    .clock-image {
      max-width: 36px;
    }

    .will-respond-text {
      color: grayscale-color("gray");
    }
  }

  .todo-container {
    margin-top: $vertical-spacing;
  }
}
