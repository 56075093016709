.__patient-back-button__61db3 button {
  background-color: transparent;
  background-image: none;
  border: 0;
  display: block;
  height: 45px;
  left: -10px;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  position: relative;
  text-align: left;
  width: 45px;

  img {
    height: 21px;
    width: auto;
  }
}
