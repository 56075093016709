// Mostly copied from deprecated app/styles/components/input-dropdown-autocomplete.scss
.__auto-complete__b3f1e {
  $result-list-background-color: grayscale-color("white");
  $result-list-border-color: #bcbcd2;
  $result-list-separator-color: #dadae7;
  $result-list-focus-color: #f9f9fb;
  $result-list-option-text-color: #333;
  position: relative;

  input {
    margin-bottom: 0;
  }

  // Adjustments for when search results list is displayed
  input:not(:only-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ul {
    background: $result-list-background-color;
    border: 1px solid $result-list-border-color;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    border-top-width: 0;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 1;

    li {
      cursor: pointer;
      margin: 0;
      padding: 0.5em 0.8em;
      transition: background 0.25s ease;

      &:not(:last-child) {
        border-bottom: 1px solid $result-list-separator-color;
      }

      &:hover,
      &:focus {
        background: $result-list-focus-color;
      }
    }
  }

  .autocomplete-option-text {
    color: $result-list-option-text-color;
  }
}
