$zen-verify-task-a-color: #3044a8;

.__zen-verify-task__1d7e0 .fa-check-circle {
  color: nurx-color("success");
}

.__zen-verify-task__1d7e0 a {
  color: $zen-verify-task-a-color;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.29;
}
