.__admin__topic-configuration-display__373c9 .main-topic-configuration-display {
  display: flex;
  height: 100vh;
  margin-left: 15rem;
}

.__admin__topic-configuration-display__373c9 .topic-display {
  column-gap: 10px;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
  width: 80vw;

  li {
    border: 2px solid grayscale-color("black");
    font-size: 22px;
    height: 15rem;
    text-align: center;
    width: 95%;
  }
}
