.__ui-patient__alert__75510 .alert-wrapper {
  @include media-breakpoint-up(xs) {
    background-color: rgba(0, 0 , 0 , 0.4); /* Fallback color */
    display: block;
    height: 100%; /* Full height */
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    position: fixed; /* Stay in place */
    top: 0;
    width: 100%; /* Full width */
    z-index: 1; /* Sit on top */

    /* Alert Content */
    .alert-container {
      background-color: nurx-color("white");
      border: 1px solid grayscale-color("dark-gray");
      border-radius: 20px;
      margin: auto;
      padding: 25px 50px 50px 50px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 90%;

      .alert-top {
        display: block;
        font-weight: 500;

        .close-row {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          .close {
            background-color: transparent;
            border: hidden;
            font-size: 20px;
            padding: 10px;
          }
        }

        .header-container {
          text-align: center;
        }

        .header-icon {
          width: 80px;
          height: 80px;
          margin-bottom: 11px;
        }
      }

      .content-container {
        display: flex;
        justify-content: center;
      }

      .footer-container {
        display: flex;
        justify-content: space-between;

        &.center-solo {
          justify-content: center;
        }

        button {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 50%;
        }
      }

      .refill-footer-container {
        display: flex;
        flex-direction: column;
      }

      .refill-button-width {
        width: 100%;
      }
    }

    .square-border {
      border-radius: unset;
    }
  }

  @include media-breakpoint-up(sm) {
    .alert-container {
      width: 75%;
    }
  }

  @include media-breakpoint-up(md) {
    .alert-container {
      width: 50%;

      .confirm-button {
        max-width: 50%;
      }

      .refill-button-width {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
