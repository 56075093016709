// Create a responsive grid-based table that will display our data well
.__admin-drug-unit-weight__f6db6 .drug-unit-weight-table {
  border-collapse: collapse;
  column-gap: 10px;
  display: grid;
  flex: 1;
  height: auto; // This sizes the panel and allows the table to be vertically responsive
  margin: 0;
  max-width: 95vw;
  min-width: 100%;
  overflow: scroll;
  row-gap: 15px;
  width: auto;
}

.__admin-drug-unit-weight__f6db6 thead,
.__admin-drug-unit-weight__f6db6 tbody,
.__admin-drug-unit-weight__f6db6 tr {
  display: contents;
  overflow: hidden;
}

.__admin-drug-unit-weight__f6db6 thead {
  background-color: nurx-color("white"); // Added for readability on scrolling effect
  border-bottom: 3px solid grayscale-color("light-gray");
  display: flex;
  flex-direction: row;
}

.__admin-drug-unit-weight__f6db6 th {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-height: 60px;
  padding: 0 0 3px;

  &:last-of-type {
    border: 0;
  }
}
