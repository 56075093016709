// style-namespace "self-serve-subscription-cancellation-confirm"
.__self-serve-subscription-cancellation-confirm__3b4ca .medications {
  background-color: grayscale-color("lighter-gray");
  margin-top: $vertical-spacing;
  padding: 20px;

  .medication-name {
    margin-top: $vertical-spacing;
  }

  .medication {
    margin-top: $vertical-spacing;

    p {
      margin-bottom: 0;
    }
  }
}
