.single-template {
  background-color: nurx-color-level("black", 9.5);

  .single-content {
    padding: $unit-lg 0 $unit-xl;

    @media (max-width: $tablet) {
      padding: $unit-reg 0 $unit-md;
    }

    @media (max-width: $phone) {
      padding: $unit-reg 0 $unit-lg;
    }
  }

  section.page-body {
    .main-content {
      background: nurx-color("white");
      border-bottom: 2px solid #dedede;
      border-radius: $border-radius-standard;
      margin: 10px; // for the card drop shadow to appear
      padding: 24px 43px 43px 49px;
      text-align: center;

      @media (max-width: $tablet) {
        margin: 0;
      }

      @media (max-width: $phone) {
        padding: 24px 24px 49px;
      }

      img.brand-logo {
        display: block;
        height: auto;
        margin: 14px auto 0;
        max-height: 98px;
        max-width: 200px;
        width: 60%;

        @media (max-width: $phone) {
          max-height: 80px;
        }
      }

      h1 {
        @include text-bold;
        color: nurx-color("black");
        font-size: 2em;
        margin: $unit-reg 0 $unit-md;
        text-align: center;

        @media (max-width: $tablet) {
          font-size: 2.2em;
          text-align: center;
        }

        @media (max-width: $phone) {
          font-size: 1.8em;
          margin: $unit-reg 0;
        }
      }

      .mobile-cta {
        display: none;

        @media (max-width: $phone) {
          display: block;
        }
      }

      .tablet-cta {
        @media (min-width: 991px) {
          margin-top: $unit-md;
        }

        @media (max-width: $tablet) {
          display: block;
          margin: 0;
        }

        @media (max-width: $phone) {
          display: none;
        }
      }

      .tags {
        ul {
          li {
            @include text-bold;
            background: nurx-color-level("black", 9.5);
            border-radius: 20px;
            color: nurx-color-level("black", 2.5);
            display: inline-block;
            font-size: 1.05em;
            padding: 7px $unit-reg;

            &:not(:only-child) {
              margin: 0 4px 11px;
            }

            @media (max-width: $phone) {
              font-size: 1.1em;
            }
          }
        }
      }

      .generic {
        img {
          height: auto;
          width: 140px;
        }
      }

      .description {
        margin: $unit-md;

        @media (max-width: $phone) {
          margin: 0;
        }

        p {
          color: nurx-color("black");
          font-size: 1.24em;
          line-height: 1.5em;

          @media (max-width: $phone) {
            font-size: 1.34em;
            text-align: left;
          }
        }
      }

      a.see-website {
        color: nurx-color("accent");
      }

      .brand-specs {
        @include text-bold;
        display: none;
        text-align: left;

        span.label {
          color: nurx-color-level("black", 5.5);
          display: inline-block;
          font-size: 1.05em;
          padding: $unit-reg 0 $unit-xs;

          @media (max-width: $phone) {
            font-size: 1.2em;
          }
        }

        p,
        ul {
          color: nurx-color-level("black", 2.5);
          font-size: 1.05em;
          line-height: 1.5em;

          @media (max-width: $phone) {
            font-size: 1.2em;
          }
        }

        .get-button-container {
          @media (max-width: 991px) {
            display: none;
          }

          @media (max-width: $tablet) {
            display: none;
          }

          @media (max-width: $phone) {
            display: block;
            margin-top: $unit-reg;
          }
        }

        @media (max-width: 991px) {
          border-top: 2px solid #eee;
          display: block;
          padding-top: $unit-reg;
        }
      }
    }

    .sidebar {
      margin-top: $unit-reg;

      @media (max-width: 991px) {
        display: none;
      }

      .brand-specs {
        @include text-bold;

        >div {
          margin-bottom: $unit-sm;
        }

        span.label {
          color: nurx-color-level("black", 4);
          display: inline-block;
          font-size: 1.05em;
          padding: $unit-reg 0 $unit-xs;
        }

        p,
        ul {
          // TODO : Identical as above
          color: nurx-color-level("black", 2.5);
          font-size: 1.05em;
          line-height: 1.5em;
        }
      }
    }
  }

  div.get-button-container {
    margin: $unit-sm 0 0 0;

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: $tablet) {
      text-align: center;
    }
  }

  section.similar-products {
    background: #eeeef3;
    padding-bottom: $unit-lg;

    .brand-listing-header {
      margin-top: $unit-lg;

      @media (max-width: $phone) {
        margin: $unit-md 0 $unit-reg;
      }

      .title {
        @include text-bold;
        border: 0;
        color: #6e798a;
        font-size: 2em;
        text-align: center;

        @media (max-width: $phone) {
          font-size: 2em;
        }
      }
    }
  }
}
