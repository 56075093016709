.admin-dashboard {
  display: flex;
  height: calc(100vh - #{$admin-site-header-height});

  .section-activity {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .container-fluid {
    flex-grow: 1;
    height: 100%;

    >.row {
      height: 100%;

      .module-wrapper {
        height: 100%;
      }
    }
  }
}

.module-content {
  &:not(:last-child) {
    margin-bottom: 1em;
    padding-bottom: 2em;

    @media (max-width: 420px) {
      margin: 0;
    }
  }

  @media (max-width: 420px) {
    padding-top: 1em;
  }
}

.module-content-user-account {
  &:not(:last-child) {
    padding-bottom: 1em;

    @media (max-width: 420px) {
      margin: 0;
    }
  }

  @media (max-width: 420px) {
    padding-top: 1em;
  }
}

.panel {
  &.panel-selectable {
    border-width: 2px;
    cursor: pointer;

    &:hover {
      border-color: nurx-color-level("accent", 1);
    }
  }

  &.panel-selected {
    border-color: nurx-color-level("accent", 1);
  }
}

// Activity Section
.section-activity {
  $activity-sidebar-width: 200px;
  $activity-top-level-tab-height: 4em;
  $activity-sub-level-tab-height: 2.7em;
  $section-activity-filter-top-bg-color: #383e48;
  $section-activity-filter-top-text-color: #879fcc;
  $section-activity-filter-top-active-tab-border-bottom: nurx-color("accent");
  $section-activity-filter-top-border-bottom-color: nurx-color("accent");
  $section-activity-filter-sub-level-border-color: nurx-color("accent");
  $section-activity-filter-sub-level-active-bg: nurx-color("accent");
  $section-activity-list-bg-color-1: #383e48;
  $section-activity-list-bg-color-2: #383e48;
  $section-activity-list-item-bg-color-1: #383e48;
  $section-activity-list-item-bg-color-2: nurx-color("accent");
  $section-activity-list-item-bg-color-active: #445167;
  $section-activity-list-item-bg-color-hover: #445167;
  $section-activity-list-item-text-color: #8f9fb9;
  $section-activity-list-item-actionable-icon-color: #ff6884;
  $section-activity-scrollbar-width: 0.6rem;
  height: 100%;
  overflow-y: scroll;
  padding-right: 0;
  width: $activity-sidebar-width;

  .activity-tabs {
    background-color: $section-activity-filter-top-bg-color;
    border-bottom: 2px solid $section-activity-filter-sub-level-active-bg;
    text-align: center;

    .nav-tabs {
      border: 0;
      margin: 0;
    }

    li {
      height: 100%;
      width: 25%;

      a {
        @include text-bold;
        border: 1px solid transparent !important;
        border-radius: 0;
        color: $section-activity-filter-top-text-color;
        height: 100%;
        margin: 0;
        padding: 0.7em 0 0;

        span {
          display: block;
          font-size: 0.94em;
        }
      }

      &.active,
      &:hover {
        a {
          background-color: $section-activity-filter-top-bg-color;
          border: 0;
          color: nurx-color("white");

          span {
            border-bottom: 3px solid $section-activity-filter-top-active-tab-border-bottom;
            display: table;
            margin: 0 auto;
            padding-bottom: 2px;
          }
        }
      }
    }
  }

  .activity-filters {
    background-color: $section-activity-filter-top-bg-color;
    padding: 0.8em 0.8em 0;

    button {
      background-color: #383e48;
      border: 1px solid $section-activity-filter-sub-level-border-color;
      border-radius: 3px;
      color: #cde1f4;
      font-size: 11px;
      font-weight: 500;
      min-width: 60px;
      padding: 3px $unit-sm;

      &.active,
      &:hover {
        background-color: $section-activity-filter-sub-level-active-bg;
        border-color: $section-activity-filter-sub-level-active-bg;
        color: nurx-color("white");
      }
    }

    input.search {
      background: #464e5a;
      border: 0;
      border-radius: 2em;
      color: nurx-color("white");
      margin-bottom: 0.7em;
      margin-top: -0.5em;
      padding: 0.26em 0.8em;
      width: 100%;
    }
  }

  .activity-feed {
    background: $section-activity-list-bg-color-1;
    font-size: 0.94em;
    overflow-x: hidden;
    overflow-y: scroll;

    .activity-item {
      position: relative;

      .activity-item-content {
        color: $section-activity-list-item-text-color;
        line-height: 1.5em;
        padding: 1em;
        position: relative;

        ul {
          li {
            &.name {
              @include text-bold;
              align-items: center;
              color: nurx-color("white");
              display: inline-flex;
              flex-wrap: wrap;
              text-transform: capitalize;

              & :not(:first-child) {
                margin-left: 4px;
              }
            }
          }
        }
      }

      .activity-item-count {
        background: $section-activity-filter-sub-level-active-bg;
        color: nurx-color("white");
        padding: 0.5em 1em;
      }
    }

    .activity-item a {
      $font-size-category: 0.9em;
      display: block;

      &.actionable {
        li.name {
          padding-left: 1.4em;
          position: relative;

          &::after {
            background: $section-activity-list-item-actionable-icon-color;
            border-radius: 5px;
            content: "";
            height: 10px;
            left: 0;
            position: absolute;
            top: 4px;
            width: 10px;
          }
        }
      }

      &.active,
      &:hover {
        background: $section-activity-list-item-bg-color-active;
      }

      &.emergency {
        border-left: 5px solid $section-activity-list-item-actionable-icon-color;

        li.category {
          @include text-bold;
          color: $section-activity-list-item-actionable-icon-color;
          font-size: $font-size-category;
        }
      }

      &.pharmacy {
        border-left: 5px solid #8e91ef;

        li.category {
          @include text-bold;
          color: #989bff;
          font-size: $font-size-category;
        }
      }
    }

    // TICKET-TEMP-STYLES
    .ticket-queue {
      margin: $unit-sm 6px 0;

      button {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}

// Actionable Section
.section-actionable {
  .form-group {
    .checkbox input[type="checkbox"] {
      cursor: pointer;

      @media (max-width: 768px) {
        width: 15px;
      }
    }
  }
}

.section-actionable::-webkit-scrollbar {
  display: none;
}

.form-horizontal.account-info {
  max-width: 420px;

  fieldset {
    margin: 1em 0 2em;
  }
}

.form-horizontal .form-group {
  display: flex;
}

.panel-header-title {
  @include text-bold;
  color: #616670;
  font-size: 1.6rem;
  margin: 1em 0;

  @media (max-width: 420px) {
    margin-bottom: $unit-reg;
    margin-top: $unit-reg;
  }
}

.panel-header-subtitle {
  @include text-normal;
  color: #616670;
  margin: 1em 0;

  @media (max-width: 420px) {
    margin: 1em 0 0.5em;
  }
}

.ember-view {
  &:not(:last-child) {
    .panel-table-stack {
      border-bottom: 1px solid #e5e5ef;
    }
  }
}

.panel.panel-table-stack {
  border: 1px solid nurx-color("white");

  &:not(.panel-expanded) {
    .panel-heading {
      &:hover {
        background: #f9f9fb;
      }
    }

    .panel-heading-actions {
      display: none;
    }
  }

  // EXPANDED
  &.panel-expanded {
    border: 1px solid #efeff7;
    border-radius: 0.3em;

    >.panel-heading {
      background: #f9f9fb;
    }
  }

  // DEFAULT
  >.panel-heading {
    background: nurx-color("white");
    border: 0;
    color: #797d86;
    padding: 0;
    word-break: break-word;

    .panel-title {
      @include text-bold;
      cursor: pointer;
      font-size: 13px;
      padding: 1em;
      position: relative;

      li {
        margin-bottom: 0.3em;
      }
    }

    .panel-heading-actions {
      padding: 0.75em 1em 1em;
      text-align: right;

      button {
        &:not(:only-child) {
          margin-left: 0.75em;
        }
      }
    }

    &.with-nav-tabs {
      padding-bottom: 3.5em;

      .nav-tabs {
        bottom: 0;
        font-size: 0.95em;
        margin-bottom: 0;
        position: absolute;

        >li {
          a {
            @include text-bold;
            padding: 11px 15px 6px;
          }

          &:not(.active) {
            a {
              color: nurx-color-level("accent", 1);
            }
          }
        }
      }
    }
  }
}

.user-profile-summary {
  display: flex;
  height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    overflow-y: auto;
  }

  .user-info {
    @include text-bold;
    line-height: 1.4em;

    .user-avatar {
      .flag-icon {
        display: none;
      }
    }

    &.flagged {
      .user-avatar {
        position: relative;

        .flag-icon {
          background: nurx-color("danger");
          border-radius: 50%;
          bottom: -0.5em;
          color: nurx-color("white");
          display: block;
          height: 2.2em;
          padding-top: 0.5em;
          position: absolute;
          right: -0.7em;
          width: 2.2em;
          z-index: 1;
        }
      }
    }

    .user-status-controls {
      button {
        color: nurx-color("danger");

        &:focus {
          outline: none;
        }
      }
    }

    li {
      @include text-normal;
      color: #80848b;
      font-size: 0.94em;
      line-height: 1.3em;
    }

    li.name {
      @include text-bold;
      color: #5f646d;
    }

    // User Profile Picture in Admin
    .user-avatar {
      height: 120px;
      margin: 0 auto $unit-sm;
      object-fit: cover;
      object-position: center;
      width: stretch;
    }
  }
}

.user-photo {
  position: relative;

  img {
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    height: auto;
    width: 100%;
  }

  .user-photo-actions {
    border: 1px solid #dadae7;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    padding: 1em 0.5em;
  }
}

.user-photo-controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em 0;

  .btn {
    align-items: center;
    display: flex;
    font-size: 0.8em;
    margin: 0;

    svg {
      margin-right: 4px;
    }
  }
}

img.patient-photo-id {
  border-radius: 0.3em;
  height: auto;
  width: 100%;
}

// Patient Profile Panel
.patient-profile-panel {
  position: relative;
}

.patient-insurance-card-container {
  z-index: 1;

  .patient-insurance-card-hover-group {
    div.patient-insurance-card {
      border-radius: 0.1em;
      width: 40px;

      .card-stack {
        border: 0.1rem solid nurx-color("white");
        box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
        margin-bottom: $unit-xs;

        &.back {
          left: $unit-xs;
          position: absolute;
          top: $unit-xs;
          z-index: 1;
        }
      }
    }

    div.patient-insurance-card-details {
      display: none;
    }
  }

  .patient-insurance-card-hover-group:hover {
    div.patient-insurance-card {
      width: 425px;
      z-index: 2;

      .card-stack {
        border: 0.3rem solid nurx-color("white");
        box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
        margin-bottom: $unit-md;

        &.back {
          left: $unit-reg;
          position: absolute;
          top: $unit-reg;
          z-index: 1;
        }
      }
    }

    div.patient-insurance-card-details {
      background-color: nurx-color("white");
      display: flex;
      flex-direction: column;
      font-size: 1.5em;
    }
  }
}

.patient-photo-id-container {
  position: relative;
  z-index: 1;

  a {
    cursor: zoom-in;
    display: block;
  }

  img {
    height: auto;
    transition: 95ms ease-in-out;

    &.enlarged {
      transform: translateX(-50%) scale(2.5);
    }
  }


}

.patient-id-rotation-controls,
.patient-verification-controls,
.patient-verification-controls > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.radio-inline {
  display: inline-flex;
  padding-left: unset;

  input {
    &[type="radio"] {
      bottom: 1px;
      margin-left: unset;
      margin-right: $unit-xs;
      position: relative;
      width: auto;
    }
  }

  &.radio-text-input {
    input {
      &[type="radio"] {
        margin-top: 0.84em;
      }
    }
  }
}

.checkbox-inline {
  display: inline-flex;
  padding-left: unset;

  input {
    &[type="checkbox"] {
      bottom: 1px;
      margin-left: unset;
      margin-right: $unit-xs;
      position: relative;
      width: auto;
    }
  }
}

// Messenger Overrides for Pharmacy Interface ONLY
.content-wrapper.view-account {
  .communication-feed .nurx-messenger {
    max-height: calc(100vh - 10.4em);
    overflow: auto;
  }
}

.content-wrapper.view-account {
  .legacy-conversation-container {
    padding: 1em 0.5em 0.5em 0.75em;
    padding-bottom: $unit-sm;
    padding-left: $unit-sm;
    padding-right: $unit-sm;
    padding-top: 14px;
  }

  .message {
    font-size: 1.04em;
  }

  .composer-container {
    left: 0;
    position: absolute;
    right: 0;

    .compose-content {
      padding: 14px 21px 12.5px;

      .textarea-container {
        width: calc(100% - 4em);
      }

      textarea {
        @include scrollbar(0.4em, #ccd8e6, hidden);
        font-size: 1.1em;
        line-height: 1.4em;
        min-height: 86px;
        overflow-y: auto;
      }
    }

    .send-btn-container {
      position: absolute;
      right: 10px;
      top: 0.25em;
    }

    .attach-btn-container {
      bottom: 0.5em;
      position: absolute;
      right: 10px;
    }
  }
}
