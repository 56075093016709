$icon-size: 24px;

@mixin icon($size: $icon-size) {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $size $size;
  display: inline-block;
  height: $size;
  width: $size;
}

.icon {
  @include icon;

  &.complete {
    background-image: url("/assets/images/ios-app/complete-checkmark-circle.svg");
  }

  &.incomplete {
    background-image: url("/assets/images/ios-app/incomplete-checkmark-circle.svg");
  }

  &.issue {
    background-image: url("/assets/images/ios-app/issue.svg");
  }

  &.fail {
    background-image: url("/assets/images/ios-app/progress-bar-fail.svg");
  }

  &.hold {
    background-image: url("/assets/images/ios-app/progress-bar-hold.svg");
    color: nurx-color("primary");
  }

  &.pause {
    background-image: url("/assets/images/ios-app/pause-checkmark-circle.svg");
  }

  &.processing {
    background-image: url("/assets/images/ios-app/progress-bar-processing.svg");
  }
}
