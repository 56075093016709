.user-profile-summary {
  .module-heading {
    @media (max-width: 767px) {
      &::after {
        border-bottom: 1px solid #ebebf1;
        bottom: 0;
        content: "";
        left: -$unit-reg;
        position: absolute;
        right: -$unit-reg;
      }
    }
  }

  .name {
    word-wrap: break-word;
  }
}

.module {
  .user-profile-summary {
    .module-content {
      &.desktop-only {
        @media (max-width: 767px) {
          color: #f00 !important;
          display: none;
        }
      }

      &.mobile-only {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }
}
