.__nurx-address-contact-form__7c473 input {
  &::placeholder {
    color: grayscale-color("light-gray");
  }
}

.__nurx-address-contact-form__7c473 .address-form-actions,
.__nurx-address-contact-form__7c473 .address-form,
.__nurx-address-contact-form__7c473 form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.__nurx-address-contact-form__7c473 .form-info {
  color: $copy;
}
