.__button-select__9e8cd {
  display: flex;

  &.is-vertical {
    flex-direction: column;

    .child-button:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.__button-select__9e8cd .child-button {
  $hover-color: grayscale-color("black");
  border-width: 1px;

  @include active-states("&") {
    border-color: $hover-color !important;
    color: $hover-color !important;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.unselected {
    border-color: grayscale-color("light-gray");
    color: grayscale-color("light-gray");
  }

  &.none-selected {
    color: grayscale-color("black");
  }
}
