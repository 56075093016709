.single-announcement-page {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  text-align: center;

  .single-announcement-graphic {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 180px;
    margin: 0 auto;
    width: 200px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
  }
}
