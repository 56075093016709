.__admin-prescription__8d5da .admin-prescription {
  margin: 20px;
}

.__admin-prescription__8d5da .admin-prescription table {
  margin: 15px;
}

.__admin-prescription__8d5da .admin-prescription h4 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.__admin-prescription__8d5da .admin-prescription table td.label2 {
  @include text-bold;
  color: grayscale-color("black");
  font-size: 100%;
}

.__admin-prescription__8d5da .admin-prescription table td {
  padding: 5px;
}

.__admin-prescription__8d5da .admin-prescription ul.dropdown {
  font-size: 0.95em;
  position: absolute;
  width: 100%;
  z-index: 4;
}

.__admin-prescription__8d5da .admin-prescription ul.dropdown:not(.active) {
  display: none;
}

.__admin-prescription__8d5da .admin-prescription ul.dropdown li {
  background: nurx-color("white");
  border: 1px solid #e3e3e3;
  border-top: 0;
  color: grayscale-color("black");
  cursor: pointer;
  display: block;
  padding: 0.4em 0.8em;
}

.__admin-prescription__8d5da .admin-prescription ul.dropdown li:not(:last-child) {
  border-bottom: 0;
}

.__admin-prescription__8d5da .admin-prescription ul.dropdown li:last-child {
  border-bottom: 1px solid nurx-color-level("accent", 3.5);
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
}
