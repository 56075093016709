$width-layout: 1200px;

.__styles__admin__order-routing__15b22 .sub-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  max-width: $width-layout;
  width: 100%;
}

.__styles__admin__order-routing__15b22 .well {
  align-items: center;
  background-color: #f1f5f9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100% - #{$site-header-height});
  width: 100%;
}

.__styles__admin__order-routing__15b22 .form-row {
  margin-top: 16px;
  max-width: $width-layout;

  .empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;

    a {
      user-select: none;
    }
  }
}

.__styles__admin__order-routing__15b22 .create-form-wrapper {
  margin-right: 13px;
}
