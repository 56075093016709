// Our main container for the header
.__styles__layout__site-header__b4b81 .site-header-container {
  background: grayscale-color("white");
  color: grayscale-color("black");
  height: $site-header-height;
  width: 100%;

  // Display special background color on header for admins
  &.admin {
    background: linear-gradient(to right, nurx-color("accent") 0%, pastel-color("pink") 100%);
  }

  // Display special background color on header in dev env
  &.development-environment {
    background: linear-gradient(to right, nurx-color("warning") 80%, nurx-color("primary") 100%);
  }

  // This container houses the logo and nav
  .site-header-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: $site-header-height;
    justify-content: space-between;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    // At desktop, give logo and nav more breathing room
    @media (min-width: 992px) {
      padding: 0 40px;
    }

    .logo {
      height: auto;
      margin: $unit-sm 0 0;
      width: 80px;
    }

    .dropdown-toggle {
      font-size: 1em;
      height: 31px;
    }

    .search {
      max-width: 30rem;

      input {
        height: 31px;
      }
    }
  }

  // Checkout Header
  h1 {
    display: inline;
    left: 0;
    position: absolute;
    text-align: center;
    top: 5px;
    width: 100%;
    z-index: -1;
  }

  h2 {
    color: nurx-color("white");
  }
}

// Default Nav Styles
.__styles__layout__site-header__b4b81 .site-nav {
  .nav-main {
    @include text-bold;
    align-items: center;
    height: 100%;
    margin: 0;

    >li {
      >a {
        color: grayscale-color("black");
      }

      &.messages {
        display: none;
      }

      &:not(:last-child) {
        margin-right: $unit-reg;

        @media (max-width: $phone) {
          margin-right: $unit-xs;
        }
      }

      &.username {
        a {
          display: flex;
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;

          @media (max-width: $tablet) {
            max-width: 125px;
          }
        }
      }
    }

    >li.patient-nav-tab-item:not(.active)>a {
      color: grayscale-color("gray");
    }

    // Site Nav Icons
    >li.has-icon {
      position: relative;

      >a {
        padding-left: 35px;

        @media (max-width: $phone) {
          font-size: 1.1em;
          padding-left: 43px;
        }

        &::before {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          height: 30px;
          left: 0;
          position: absolute;
          top: -4px;
          width: 30px;

          @media (max-width: $phone) {
            height: 36px;
            top: -7px;
            width: 36px;
          }
        }
      }

      &.messages {
        a {
          &::before {
            background-image: url("/assets/images/icons/mobile-nav/messages.svg");
          }
        }
      }
    }
  }

  #dropdownMenu-notifications {
    padding-bottom: 0;
  }
}

// Show desktop navigation on desktop
@include media-breakpoint-up(md) {
  .nav-main {
    display: flex;
  }
}

// Hide desktop navigation on tablet and mobile
@include media-breakpoint-down(md) {
  // hide user nav
  .nav-main {
    display: none;
  }
  // keep admin nav though
  .site-header-container.admin .nav-main {
    display: flex;
  }
}

.__styles__layout__site-header__b4b81 header.site-header-container.admin {
  nav.site-nav {
    ul.nav-main {
      >li.has-icon {
        &.messages {
          a {
            padding: 0;

            &::before {
              display: none;
            }
          }
        }

        &.username {
          a {
            padding: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

// Fixed Header
.__styles__layout__site-header__b4b81 .site-header-container.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
