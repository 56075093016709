$pastel-colors: (
  "dark-blue": #5C8995,
  "blue": #bcd8df,
  "blue-80": #c9e0e5,
  "blue-60": #d7e8ec,
  "blue-40": #e4eff2,
  "green": #c3dbb8,
  "green-80": #cfe2c6,
  "green-60": #dbe9d4,
  "green-40": #e7f1e3,
  "peach": #efc39c,
  "peach-80": #f2cfb0,
  "peach-60": #f5dbc4,
  "peach-40": #f9e7d7,
  "pink": #f9d8d8,
  "pink-80": #fbe0e0,
  "pink-60": #fce8e8,
  "pink-40": #fdf0f0,
  "purple": #d7b0ef,
  "purple-80": #dac1ee,
  "purple-60": #e3d1f2,
  "purple-40": #ecdff7,
);

@function pastel-color($key) {
  @return get-color($pastel-colors, $key, "pastel-color");
}
