.animation-fade-in {
  animation: fadein 100ms ease-in-out forwards;
}

@keyframes fadein {
  from {
    @include opacity(0);
  }

  to {
    @include opacity(1);
  }
}
