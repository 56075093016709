.__user-order-details__316ff .borderless-btn {
  @include uniform-padding(20px);
  background: none;
  border: 0;

  &:disabled {
    color: grayscale-color("light-gray");
  }
}

.__user-order-details__316ff .detail-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: $vertical-spacing;
  padding-top: $vertical-spacing;

  &:first-child {
    border-top: 1px solid grayscale-color("lighter-gray");
  }

  h3 {
    font-size: 14px;
  }
}

.__user-order-details__316ff .screen-column {
  margin-left: 0;

  .module-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }

  .address-container {
    margin-top: 20px;
  }

  .panel-header-title {
    color: nurx-color("black");
  }

  .component-order {
    margin-left: -15px;
    margin-right: -15px;

    .user-item-container {
      margin: inherit;

      .user-order-item-card {
        background-color: grayscale-color("lighter-gray");
      }
    }

    .component-header {
      padding: 20px;
    }
  }

  .full-width {
    width: 100%;
  }
}
