.__order-routing-test-form__baa69 .order-routing-test-container {
  align-items: flex-start;
  background-color: nurx-color("white");
  border-radius: 3px;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 200px;
  padding: 10px;
  width: 100%;
}

.__order-routing-test-form__baa69 .order-routing-test-form-header {
  align-items: center;
  border-bottom: #dadae7 solid 1px;
  display: flex;
  justify-content: space-between;
  padding-bottom: $unit-sm;
  padding-left: 3px;
  padding-right: 3px;
  width: 100%;
}

.__order-routing-test-form__baa69 .order-routing-test-form {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 9px;
}

.__order-routing-test-form__baa69 .order-routing-test-form-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 42px;
  min-width: 110px;
}

.__order-routing-test-form__baa69 .order-routing-test-container.stripe {
  background-color: #fafdff;
}

.__order-routing-test-form__baa69 .order-routing-test-container h2 {
  text-align: center;
  width: 100%;
}

.__order-routing-test-form__baa69 .form-group {
  margin: 0 3px;
}

.__order-routing-test-form__baa69 .form-control {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.__order-routing-test-form__baa69 .form-control input {
  border: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
}

.__order-routing-test-form__baa69 .form-control ul {
  position: absolute;
  right: 0;
  top: 42px;
}

.__order-routing-test-form__baa69 .form-control.invalid {
  border-color: nurx-color("danger");
}

.__order-routing-test-form__baa69 .form-control.disabled {
  border-color: #c8c8c8;
}

.__order-routing-test-form__baa69 .form-control.disabled,
.__order-routing-test-form__baa69 .form-control.disabled input {
  background-color: #f6f6f6;
  cursor: not-allowed;
}

.__order-routing-test-form__baa69 .order-routing-test-results {
  margin-left: 3px;
  min-height: calc(24px * 3);
}

.__order-routing-test-form__baa69 .order-routing-test-results-message {
  height: 24px;
}

.__order-routing-test-form__baa69 .order-routing-test-results-message.danger {
  color: nurx-color("danger");
}

.__order-routing-test-form__baa69 .order-routing-test-results-message.dirty {
  color: #c8c8c8;
}
