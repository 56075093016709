.inline-block {
  display: inline-block;
}

// Hide
.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

input.hidden-input {
  display: none;
}

// Float
.clear {
  clear: both;
}

.blur-contents {
  filter: opacity(30%);
}

.btn-full-width-sm-below {
  margin-bottom: $unit-reg;
  width: 100%; // Global

  @media (min-width: $tablet) {
    margin-bottom: 0;
    width: auto;
  }

  // btn-links (secondary links)
  &.btn-link {
    margin-bottom: $unit-reg;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $tablet) {
      display: table;
      margin: 0;
    }
  }
}

.btn-link.btn-link-alternative {
  $color: #8358fc;
  $darker: #7545fc;
  color: $color;

  &:hover {
    color: $darker;
  }
}

.flex-column-center {
  align-items: center;
  display: flex;
  height: calc(100vh - 20vh);
}

.flex-column-center-justified {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  >* {
    margin-right: $unit-sm;
  }
}

.flex-grow {
  flex-grow: 1;
}

.items-center {
  align-items: center;
}

// img helpers
.img-max-height-500 {
  max-height: 500px;
}

.mw-100 {
  max-width: 100%;
}

// Styling
.bg-danger {
  background-color: nurx-color-level("danger", 5);
}

.box-shadow {
  box-shadow: $box-shadow-standard;
}

// TODO(jason 2019-04-15): componentize this (text next to checkbox) if we want to keep this in the long run
input[type="checkbox"].next-to-text {
  display: inline-block;
  margin-right: 5px;
  width: auto;
}

// iPhone X
// Use only in markup. For Scss, use the %placeholders
.iphone-padding-top {
  padding-top: env(safe-area-inset-top);
}

.iphone-padding-right {
  padding-right: env(safe-area-inset-right);
}

.iphone-padding-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.iphone-padding-left {
  padding-left: env(safe-area-inset-left);
}

.non-selectable {
  -webkit-touch-callout: none;
  user-select: none;
}

.selectable {
  -webkit-touch-callout: auto;
  user-select: auto;
}

.no-border {
  border-style: none;
  border-width: 0;
}

.border-box {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.space-evenly {
  justify-content: space-evenly;
}

.text-underline {
  text-decoration: underline;
}

.bottom-border {
  border-bottom: 1px solid grayscale-color("lighter-gray");
}

.invert-above-xs {
  @extend %invert-above-xs;
}

.break-word {
  word-wrap: break-word;
}

.w-60 {
  width: 15rem;
}
