ul.product-feature-tags {
  text-align: center;

  li.product-feature-tag {
    @include text-bold;
    background-color: nurx-color-level("black", 9.4);
    border-radius: 99px;
    color: nurx-color-level("black", 2);
    display: inline-block;
    margin-bottom: $unit-xs;
    padding: $unit-xs $unit-reg;
  }
}
