.__styles__cart__identification__f525f .controls {
  flex-flow: column;

  * {
    @include vertical-spacing-half;
  }
}

.__styles__cart__identification__f525f .ids-accept-header {
  margin-bottom: 4px;
}

.__styles__cart__identification__f525f .id-confirm-prompt {
  ul {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    li {
      list-style-type: disc;
    }
  }
}

.__styles__cart__identification__f525f .banner {
  $screen-gutter: 15px;
  margin-left: -$screen-gutter;
  margin-right: -$screen-gutter;
  margin-bottom: 38px;

  .banner-content {
    display: flex;
    gap: 12px;
  }

  .banner-icon {
    // Prevent layout shift when image is loading
    // by setting the width
    width: 31px;
  }
}
