.__styles__survey__29d3c .ui-patient-btn.survey-question-button {
  border-width: 1px;
  font-weight: $font-weight-normal;
  margin-top: $unit-reg;
  min-height: 50px;

  &.unselected {
    border-color: grayscale-color("light-gray");
    color: grayscale-color("light-gray");
  }

  &.selected {
    border-color: grayscale-color("black");
    color: grayscale-color("black");
  }

  // Note: Specifying this last to have it
  // take precedence over the other states
  &.no-selection,
  .none-selected {
    border-color: grayscale-color("light-gray");
    color: grayscale-color("black");
  }
}
