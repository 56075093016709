// app-wide base styling

// -- allows inertial scroll in mobile
.inertia-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.nurx_body.takeover-content {
  .close-takeover {
    margin: 32px 32px 84px;

    @media (max-width: $phone) {
      margin: 16px 16px 42px;
    }

    .close-button-icon-only {
      color: #adaeb6;
      position: absolute;
      right: 2em;
      top: 3em;
      z-index: 1;
    }

    .close-button {
      float: right;

      a {
        color: #adaeb6;
        font-size: 1.1em;
      }
    }
  }
}
