/* Topic-specific Styles
 *
 * Each class in this section uses `topic.code` as the class name
 * (which allows us to dynamically choose the appropriate styles.
 * –––––––––––––––––––––––––––––––––––––––––––––––––– */
.__lets-get-started__c7ef8 .acne_treatment {
  @include request-intro(pastel-color("blue-40"));
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__lets-get-started__c7ef8 .bc {
  @include request-intro(pastel-color("peach-40"));
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__lets-get-started__c7ef8 .sti {
  @include request-intro(pastel-color("peach-40"));
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__lets-get-started__c7ef8 .covid_19_testing {
  @include request-intro(pastel-color("green-40"));
  @include request-intro-numbered-list(grayscale-color("black"));
}
