.__admin-promo-campaign__f64c9 .applicable-to {
  .list-group {
    display: flex;
  }

  .list-group-item {
    cursor: pointer;
    height: 40px;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:not(:last-child) {
      border-right: 1px solid grayscale-color("white");
    }
  }
}
