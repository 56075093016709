.__product-page__ffea9 .color-overlay--bg {
  background-color: pastel-color("pink-80");
  height: 540px;
  left: $vertical-spacing-none;
  position: absolute;
  top: $vertical-spacing-none;
  width: 100%;
}

.__product-page__ffea9 .color-overlay--content {
  z-index: 1;
}

.__product-page__ffea9 .cta {
  margin-top: $vertical-spacing-double;
}

.__product-page__ffea9 .header {
  overflow: hidden;
  position: relative;
}

.__product-page__ffea9 .select-payment-buttons {
  margin-bottom: $vertical-spacing;
}

.__product-page__ffea9 .video-wrapper {
  margin: $vertical-spacing-double $vertical-spacing-none;
  padding-top: 56.25%;
  position: relative;
}

.__product-page__ffea9 .video {
  height: 100%;
  left: $vertical-spacing-none;
  position: absolute;
  top: $vertical-spacing-none;
  width: 100%;
}
