.__styles__account__insurance__confirm__606e7 .card-thumbnail {
  margin-right: 10px;
  max-height: 40px;
  max-width: 65px;
}

.__styles__account__insurance__confirm__606e7 .cancel {
  margin-right: 20px;
}

.__styles__account__insurance__confirm__606e7 .contact-us {
  color: grayscale-color("gray");

  a {
    text-decoration: underline;
  }
}
