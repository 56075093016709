.__button-link__e311b {
  @include vertical-spacing;
  cursor: pointer;
  user-select: none;
}

.__button-link__e311b.is-disabled {
  cursor: not-allowed;
}

.__button-link__e311b.danger {
  color: nurx-color("danger");
}
