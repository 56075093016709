.__admin-permission__05345 p {
  font-size: 0.9em;
  margin: 0;
}

.__admin-permission__05345 .border-right {
  border-right: 1px solid grayscale-color("light-gray");
}

.__admin-permission__05345 label {
  display: none;
}
