.__cross-care-list-item__78113 {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.__cross-care-list-item__78113 .service-line-icon,
.__cross-care-list-item__78113 .service-line-icon > ul {
  height: 32px;
  width: 32px;
  display: flex;

  img {
    max-height: 32px;
    max-width: 32px;
  }
}

.__cross-care-list-item__78113 .title {
  padding-top: 14px;
  flex-grow: 4;
  margin-left: 12px;
  font-size: 1em;
  display: flex;
}

.__cross-care-list-item__78113 .arrow-icon {
  margin: 0;
  padding: 0;
  width: 24px;
  display: flex;
}
