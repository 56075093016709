.__auto-refill-consent-opt-out__ac3f6 .text-center:first-child {
  margin-top: 11px;
}

.__auto-refill-consent-opt-out__ac3f6 .text-center {
  margin-bottom: 11px;
}

.__auto-refill-consent-opt-out__ac3f6 .text-center:last-child {
  margin-bottom: 24px;
}
