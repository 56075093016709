.__admin__user__labs-results__969bc .table {
  font-size: 0.94em;
}

.__admin__user__labs-results__969bc .lab-block {
  display: block;
}

.__admin__user__labs-results__969bc .upload {
  display: flex;
}
