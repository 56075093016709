.__checkout__addon-selection__ad393.addon-selection {
  & > .title {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 32px;
    margin-top: 64px;
  }

  .footer {
    color: grayscale-color("gray");
    margin-bottom: 48px;
  }

  .addon-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  // Note: This grouping of styling can be extracted
  // from this component if a dedicated card component
  // is created.
  .addon-selection-group-card {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    .img-container {
      border-top-left-radius: 70px;
      border-top-right-radius: 70px;
      position: relative;
      max-height: 180px;
      min-width: 74px;
      max-width: 120px;

      &.img-bg {
        &-green {
          background-color: pastel-color("green");
        }

        &-blue {
          background-color: pastel-color("blue");
        }

        &-peach {
          background-color: pastel-color("peach");
        }

        &-pink {
          background-color: pastel-color("pink");
        }
      }
    }

    .img {
      border-top-left-radius: 70px;
      border-top-right-radius: 70px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      width: 100%;
    }

    .header {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  // Note: This grouping of styling can be extracted
  // from this component if a dedicated card component
  // is created.
  .addon-selection-card {
    align-items: center;
    color: grayscale-color("black");
    display: flex;
    gap: 14px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid grayscale-color("light-gray");

    &:has(input:checked) {
      border: 1px solid grayscale-color("black");
    }

    .radio-btn {
      accent-color: grayscale-color("black");
      width: 14px;
    }

    .radio-label {
      color: grayscale-color("black");
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title, .price {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
