.__button-main__591e0 .btn-pink {
  background: #ea5395;
  border: 1px solid #ea5395;
  color: nurx-color("white");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: #e73c87;
    border: 1px solid #e73c87;
    color: nurx-color("white");
  }
}
