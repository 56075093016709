.__styles__account__address__edit__67669 .autocomplete-container {
  position: relative;
}

.__styles__account__address__edit__67669 .autocomplete-list {
  position: absolute;
  top: -$vertical-spacing;
  width: 100%;
}

.__styles__account__address__edit__67669 .address-form-actions {
  justify-content: flex-end;
}

.__styles__account__address__edit__67669 .address-form-component {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.__styles__account__address__edit__67669 .form-input-label {
  margin-bottom: $vertical-spacing-half;
}
