// card-default
.new-card.card-default {
  &:not(:only-of-type) {
    margin-bottom: $unit-md;
  }

  .card-block {
    padding: $new-card-internal-padding;
  }
}

// BLOCK STACKING
.new-card.card-default {
  .card-block {

    // If there is more than one .card-block
    &:not(:only-of-type) {

      // Add styles to all but first child
      &:not(:first-child) {
        padding-top: 0;
      }

      // Add styles to all but last child
      &:not(:last-child) {
        border-bottom: 1px solid nurx-color-level("black", 9);
        margin-bottom: $unit-reg;
      }
    }
  }
}

// CARD CTA as FOOTER
.new-card {
  .card-block {
    .card-cta-footer {
      @include text-bold;
      border-bottom-left-radius: $border-radius-standard;
      border-bottom-right-radius: $border-radius-standard;
      color: nurx-color("white");
      font-size: 1.1em;
      margin: -#{$new-card-internal-padding};
      padding: $new-card-internal-padding;
      text-align: center;

      &.card-cta-footer-primary {
        background-color: nurx-color("accent");
      }

      &.card-cta-footer-success {
        background-color: nurx-color("success");
      }
    }
  }
}

// Product LOGO + TITLE
.product-block {
  .product-block-image {
    display: block;
    margin: 0 auto;
    max-height: 80px;
    max-width: 120px;
  }

  .product-block-title {
    @include text-bold;
    font-size: 1.3em;
    margin-bottom: $unit-reg;
    margin-top: $unit-xs;
    text-align: center;
  }
}
