.__styles__cart__shipping__54cb0 .collapsible-note {
  background-color: pastel-color("peach");
  padding: 20px 20px 0;
}

.__styles__cart__shipping__54cb0 .autocomplete-container {
  position: relative;
}

.__styles__cart__shipping__54cb0 .autocomplete-list {
  position: absolute;
  top: -$vertical-spacing;
  width: 100%;
}

.__styles__cart__shipping__54cb0 .form-input-label {
  margin-bottom: $vertical-spacing-half;
}

.__styles__cart__shipping__54cb0 .form-submit-button {
  margin-top: 40px;
}

.__styles__cart__shipping__54cb0 .shipping-preference-container {
  margin-top: 30px;

  h2 {
    font-size: 18px;
    margin-bottom: 23px;
  }
}
