.app-profile-nav {
  .nav-item {
    cursor: pointer;
    font-size: $unit-reg;
    padding-bottom: $unit-reg;
    padding-left: 0;
    padding-right: 0;
    padding-top: $unit-reg;

    &:not(:last-child) {
      border-bottom: 1px solid #e1e2ea;
    }

    .pe-icon {
      color: #c7c9d6;
      font-size: 24px;
      margin-right: $unit-reg;
      position: relative;
      top: $unit-xs;
    }
  }
}
