$bright-colors: (
  "red": #ff463d,
  "blue": #007aff,
  "green": #50b322,
  "grey": #ddd,
  "nurx-blue": #329eef,
  "white": #fff,
  "black": #000,
  "dark-grey": #494d55,
);

@function bright-color($key) {
  @return get-color($bright-colors, $key, "bright-color");
}
