// These styles don't _really_ matter because this question type is no longer
// used.

.__question__multiple-answer-counseling__2ebb2 .btn {
  margin: 5px;

  &.selected {
    background-color: $text-muted;
    color: nurx-color("white");
  }
}
