////////////////////////////////////////
//           Accordion
////////////////////////////////////////

$accordion-inside-padding: $unit-reg;
$accordion-indicator-width: $unit-reg;

.accordion-item-header {
  overflow: auto;
  padding: $accordion-inside-padding;

  .accordion-item-title {
    float: left;
    width: calc(100% - #{$accordion-indicator-width});
  }

  .accordion-item-indicator {
    background-image: url("images/ios-app/arrow-left-black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    float: left;
    height: 20px;
    transform: rotate(270deg);
    width: $accordion-indicator-width;
  }
}

.accordion {
  .accordion-item {
    border-bottom: 1px solid nurx-color-level("black", 9);

    &:first-child {
      border-top: 1px solid nurx-color-level("black", 9);
    }
  }
}

.accordion-item {
  .accordion-item-header {
    padding: $unit-reg;

    .accordion-item-title {
      @include text-bold;
      font-size: 1.1em;
    }
  }

  .accordion-item-body {
    max-height: 0;
    overflow: hidden;
    padding: 0 $accordion-inside-padding;
    transition: max-height 0.25s ease-in-out, padding 0.25s ease-in-out;

    &.open {
      max-height: 100vh;
      padding-bottom: $accordion-inside-padding;
      padding-top: $accordion-inside-padding;
    }
  }

  &.active {
    .accordion-item-indicator {
      transform: rotate(90deg);
    }
  }
}
