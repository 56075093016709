$height-row: 42px;

.__multi-item-selector__a3ccf .form-control {
  margin: 0;
}

.__multi-item-selector__a3ccf .header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
}

.__multi-item-selector__a3ccf .multi-item-selector-rows {
  min-height: $height-row;
}

.__multi-item-selector__a3ccf .multi-item-selector-rows.many {
  min-height: calc(#{$height-row} * 3);
}

.__multi-item-selector__a3ccf .multi-item-selector-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.__multi-item-selector__a3ccf .multi-item-selector-column {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 4px;
  min-height: $height-row;
  width: 160px;
}

.__multi-item-selector__a3ccf .multi-item-selector-column.multi-item-selector-empty {
  justify-content: center;
  margin: 0;
}

.__multi-item-selector__a3ccf .multi-item-selector-column.multi-item-selector-empty a {
  transition: none;
  user-select: none;
}

.__multi-item-selector__a3ccf .multi-item-selector-column.multi-item-selector-empty.invalid a {
  color: nurx-color("danger");
}

.__multi-item-selector__a3ccf .form-control.invalid {
  border-color: nurx-color("danger");
}
