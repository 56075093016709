$elevation-values: (
  "elevation-2": (0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)),
);

@function shadow($key) {
  @if not map-has-key($elevation-values, $key) {
    @error "Shadow value for `#{$key}` not found.";
  }
  @return map-get($elevation-values, $key);
}
