// These animation styles adapted from Font Awesome to work with IconifyIcons

.icon-spin {
  animation: icon-spin 2s infinite linear;

  &.fast {
    animation: icon-spin 0.5s infinite linear;
  }
}

.icon-pulse {
  animation: icon-spin 1s infinite steps(8);
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
