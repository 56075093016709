.__admin__patient-encounter__9ed66 .patient-encounter-header {
  align-items: baseline;
  display: flex;
  margin-bottom: $unit-reg;
  padding: $unit-sm;

  // This class is not in the patient-encounter template, it is generated by EncounterIcon
  .encounter-icon {
    margin-right: $unit-xs;
  }
}
