// Optional Filters
// style-namespace "admin/optional-filters"
.__admin__optional__filters__6c1c9 {
  border: 1px solid nurx-color("accent");
  border-radius: 3px;
  font-size: 11px;
  font-weight: normal;
  margin-bottom: $unit-sm;
  overflow: hidden;

  .optional-filters-title {
    align-items: center;
    color: nurx-color("white");
    cursor: pointer; // visual indicator to user this is a clickable header
    display: flex;
    justify-content: space-around;
    padding: $unit-xs 0 6px;
    text-align: center;
    width: 100%;

    &.expanded {
      border-bottom: 2px solid nurx-color("accent");
    }

    .dropdown-icon {
      align-self: flex-end;
    }

    // visual indicator to user the view is filtered
    .filter-indicator {
      &.active {
        color: nurx-color("accent");
      }

      &.inactive {
        opacity: 0;
      }
    }
  }

  .optional-filters-list {
    background-color: nurx-color("white");
    padding-bottom: $unit-xs;

    p {
      border-bottom: 1px solid nurx-color("black");
      margin: 0 4px;
      padding: 4px 0;
    }
  }

  p {
    font-size: 11px;
    font-weight: 500;
    line-height: 1; // fix weird spacing issue
    margin: 0;
    padding: 0;
  }

  label {
    color: bright-color("dark-grey");
    font-weight: normal;
    text-transform: capitalize;

    &.filter-header {
      clear: both;
      display: block;
      width: 100%;
    }
  }

  // Checkbox filters create a 2-column grid
  .checkbox-grid {
    align-items: center;
    background-color: nurx-color("white");
    display: grid;
    grid-template-columns: 10% 40% 10% 40%;
    justify-items: start;
    padding: $unit-xs $unit-xs $unit-sm $unit-xs;

    // Checkbox filters create a 1/n
    &.single-row {
      font-size: 1rem;
      grid-row-gap: 2px;
      grid-template-columns: 10% 90%;
    }

    // styles for checkbox labels
    label {
      margin-left: 3px;
    }
  }

  // Filters with Select
  .filters-with-select {
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    text-align: left;
    width: 100%;

    label {
      width: 100%;
    }

    select {
      color: nurx-color("black");
      font-weight: 400;
      margin-bottom: 3px;
      width: 100%;
    }
  }
}
