.__order-fulfillment__1e5b0 .unselectable {
  user-select: none;
}

.__order-fulfillment__1e5b0 .left-border {
  border-left: 3px solid nurx-color("accent");
}

.__order-fulfillment__1e5b0 .label {
  letter-spacing: 0.1em;
}

.__order-fulfillment__1e5b0 .admin-order-fulfillment-summary .panel-heading {
  display: block; /* overrides display: flex to fix alignment issues */
}
