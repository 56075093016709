.__question__video-recording__55d5d .instruction-text {
  li {
    list-style: inherit;
  }

  ol {
    padding-left: $vertical-spacing;
  }
}

.__question__video-recording__55d5d .previous-response-message,
.__question__video-recording__55d5d .recording-action,
.__question__video-recording__55d5d .video-container,
.__question__video-recording__55d5d .video-playback {
  margin-bottom: $vertical-spacing;
}

.__question__video-recording__55d5d .video-upload {
  display: none;
}
