.__admin__encounter-icon__3cdec {
  $icon-size: 14px;
  $icon-path: "/assets/images/admin-dashboard/icons/";

  @include icon($icon-size);
  vertical-align: bottom;

  &.photo {
    background-image: url("#{$icon-path}photo.svg");
  }

  &.survey {
    background-image: url("#{$icon-path}survey.svg");
  }

  &.video {
    background-image: url("#{$icon-path}video.svg");
  }
}
