.__styles__layout__spacing__03a28 .flex {
  display: flex;
}

.__styles__layout__spacing__03a28 .flex-1 {
  flex: 1;
}

.__styles__layout__spacing__03a28 .w-auto {
  width: auto;
}

.__styles__layout__spacing__03a28 .w-10 {
  width: 10%;
}

.__styles__layout__spacing__03a28 .w-20 {
  width: 20%;
}

.__styles__layout__spacing__03a28 .w-25 {
  width: 25%;
}

.__styles__layout__spacing__03a28 .w-33 {
  width: 33%;
}

.__styles__layout__spacing__03a28 .w-100 {
  width: 100%;
}

.__styles__layout__spacing__03a28 .w-66-md {
  @media (min-width: $screen-md-min) {
    width: 66%;
  }
}

.__styles__layout__spacing__03a28 .w-50-lg {
  @media (min-width: $screen-lg-min) {
    width: 50%;
  }
}

.__styles__layout__spacing__03a28 .mb-12 {
  margin-bottom: 3rem;
}

.__styles__layout__spacing__03a28 .whitespace-pre-wrap {
  white-space: pre-wrap;
}

.__styles__layout__spacing__03a28 .whitespace-nowrap {
  white-space: nowrap;
}
