.pref-btn-label-group-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 6px 6px 16px;
}

.pref-btn-label {
  margin-bottom: 6px;
  margin-left: 6px;
}
