.__journal-opened-tickets-with-manual-topics__cc00a .opened-ticket-container {
  .message-header {
    align-items: center;
    color: nurx-color("black");
    display: flex;
    justify-content: space-between;
    padding: $unit-reg;

    .topic-ticket {
      align-items: center;
      display: flex;
    }
  }
}
