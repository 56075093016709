.__styles__request__start__contact-information__704c6 p {
  font-size: 14px;
  font-weight: 500;
}

.__styles__request__start__contact-information__704c6 .form-background {
  background-color: grayscale-color("lighter-gray");
  border-radius: 3px;
  padding: 15px;
}

.__styles__request__start__contact-information__704c6 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.__styles__request__start__contact-information__704c6 label {
  margin-bottom: $unit-sm;
}

.__styles__request__start__contact-information__704c6 input {
  padding: $unit-xs $unit-sm;
}

.__styles__request__start__contact-information__704c6 .subtext {
  margin-bottom: 12px;
}
