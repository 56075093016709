.__styles__cart__next-steps__1afb7 .rx-image {
  padding-top: 10px;
  padding-bottom: 10px;
}

.__styles__cart__next-steps__1afb7 .content-group {
  padding-left: 10px;
  margin-bottom: 100px;

  li::marker {
    font-size: 2.2rem;
  }

  li {
    margin-bottom: 30px;
    list-style-type: disc;

    .action-step-blurb {
      font-weight: bold;
      font-size: 2.2rem;
      margin-bottom: 10px;
    }

    .action-step-description {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    .action-step-clarification {
      color: grayscale-color("gray");
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
  }
}
