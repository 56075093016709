// breakpoints
@mixin bp($point) {
  @if $point == medium {
    @media (min-width: 568px) and (max-width: 768px) {
      @content;
    }
  }
  @if $point == medium-large {
    @media (min-width: 568px) {
      @content;
    }
  }
  @else if $point == large {
    @media (min-width: 800px) {
      @content;
    }
  }
}
