.__exam-view__grid__d8e4c .fa-check-circle {
  color: nurx-color("accent");
}

.__exam-view__grid__d8e4c .exam-result-indicator {
  &.pass {
    color: nurx-color("success");
  }

  &.incomplete {
    color: nurx-color("warning");
  }

  &.fail {
    color: nurx-color("danger");
  }
}

.__exam-view__grid__d8e4c .grid {
  display: grid;
  grid-gap: 38px;
  grid-template-columns: repeat(4, 1fr);
}

.__exam-view__grid__d8e4c .panel-heading {
  background-color: grayscale-color("lighter-gray");

  .panel-title {
    .encounter-icon {
      margin-right: $unit-xs;
    }
  }
}

.__exam-view__grid__d8e4c .exam-assessment {
  justify-content: space-around;
}

.__exam-view__grid__d8e4c .exam-assessment-copy {
  color: #333;
  font-size: 18px;
}

.__exam-view__grid__d8e4c .patient-assessment {
  margin-bottom: 20px;
  margin-top: 10px;

  span {
    @include text-bold;
    margin-right: 5px;
  }
}
