.__tree-view__05ecf {
  list-style: none;
  margin: 0;
  padding: 0;

  .row {
    &.spec {
      cursor: pointer;
      flex-wrap: inherit;
      margin-left: 0;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    margin-left: 1em;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    position: relative;

    ul {
      padding-top: 10px;
    }

    &::before {
      border-left: 1px solid;
      bottom: 0;
      color: bright-color("nurx-blue");
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
    }
  }

  li {
    color: bright-color("nurx-blue");
    font-weight: 700;
    line-height: 2em;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0 1em;
    position: relative;

    &::before {
      border-top: 1px solid;
      content: "";
      display: block;
      height: 0;
      left: 0;
      margin-top: -1px;
      position: absolute;
      top: 1em;
      width: 10px;
    }

    &:last-child {
      &::before {
        background: bright-color("white");
        bottom: 0;
        height: auto;
        top: 1em;
      }
    }
  }
}

.__tree-view__05ecf .indicator {
  color: bright-color("nurx-blue");
  font-size: 20px;
  margin-left: 7px;
  padding-top: 2px;
}

.__tree-view__05ecf .root {
  color: nurx-color("black");
  cursor: pointer;
  font-weight: 300;
  padding-left: 3px;
}

.__tree-view__05ecf .divided {
  align-items: baseline;
  color: bright-color("dark-grey");
  display: flex;
  font-weight: 500;
  padding-right: 0;
}

.__tree-view__05ecf .divider {
  border-bottom: 1px solid bright-color("nurx-blue");
  flex-grow: 1;
  margin-left: 7px;
  position: relative;
  top: -5px;
}

.__tree-view__05ecf .leaf-pos {
  position: relative;
}

.__tree-view__05ecf .leaf-ext {
  padding-left: 0;
}

.__tree-view__05ecf .change-event-font {
  font-weight: bold;
}

/* Only display max precision for dev use. */
.__tree-view__05ecf .timestamp {
  display: none;
}
