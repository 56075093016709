.standardized-assessment-results-container {
  display: flex;
  flex-direction: column;
  margin: $vertical-spacing auto;
  padding: 0 10px;
  width: 100%;

  table {
    width: 100%;

    &.assessment-results {
      border-bottom: 1px solid grayscale-color("light-gray");

      tr.assessment-result-row {
        border-top: 1px solid grayscale-color("light-gray");
        height: 2em;
        user-select: none;

        &:hover {
          background-color: $light-blue-gray;
        }
      }
    }
  }

  thead {
    border-bottom: 1px solid grayscale-color("light-gray");
  }

  hr {
    width: 100%;
  }

  .assessment-data {
    margin: 10px;

    .assessment-datum-row {
      display: flex;

      span {
        &:first-child {
          width: 62%;
        }

        &:last-child {
          width: 38%;
        }
      }

      &:nth-child(odd) {
        background-color: grayscale-color("lightest-gray");
      }

      &:hover {
        background-color: grayscale-color("light-gray");
      }
    }
  }

  .assessment-result-placeholder {
    padding: 10px;
    text-align: center;
  }

  .assessment-filters {
    display: flex;
    margin-bottom: $vertical-spacing;
    z-index: 1;
  }

  // --- Table-specific styles - Start ---
  // Note: These can be removed once a shared
  // table component is implemented
  .bottom-toolbar {
    margin-bottom: $vertical-spacing;
    margin-top: $vertical-spacing;
  }

  th:not(.is-sortable) {
    cursor: auto;
  }
  // --- Table-specific styles - End ---
}
