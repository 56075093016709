$row-padding: 20px;

.__account__nav-row__2ef7f {
  border-bottom: 1px solid grayscale-color("lighter-gray");
}

.__account__nav-row__2ef7f .account-item {
  @include uniform-padding($row-padding);
  color: grayscale-color("black");
  display: block;
}

.__account__nav-row__2ef7f .progression-icon {
  color: grayscale-color("light-gray");
}

.__account__nav-row__2ef7f .indicator-icon {
  width: 24px;
}
