// TODO(jason 2020-03-23): can we move this to components/user-order-progress-bar/styles?

$order-action-panel-margin: 20px;

.component-order {
  .alert {
    font-size: 1.4rem;

    i {
      color: nurx-color("danger");
      padding-right: $unit-xs;
    }

    .iconify {
      color: nurx-color("danger");
      margin-right: $unit-sm;
    }

    h3 {
      font-size: 1.5rem;
    }

    a {
      color: nurx-color("accent");
    }
  }

  a:not(.text-blue) {
    color: inherit;
  }

  .component-header {
    align-items: center;
    border-bottom: 1px solid $border-color;
    display: flex;

    .order-graphic-container {
      margin-right: $unit-reg;

      .order-graphic {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
        border: 1px solid $border-color;
        border-radius: $border-radius-standard;
        height: 70px;
        padding: $unit-reg $unit-sm;
        width: 80px;

        @media (min-width: $tablet) {
          height: 80px;
          width: 100px;
        }
      }
    }

    .order-text-container {
      .order-text {
        width: 200px;

        h2 {
          font-size: 1.5rem;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }

    .action-required-badge {
      align-self: flex-start;
      background-color: nurx-color("danger");
      border: 0;
      border-radius: 8.5px;
      color: nurx-color("white");
      font-size: 11px;
      margin: $unit-xs $unit-sm 0 0;
      padding: 2px 7px;
      text-align: center;
      width: 150px;
    }

    // TODO: Remove with font awesome
    >i {
      color: #d1d1d6;
      height: 10px;
      margin-left: auto;
      position: absolute;
      right: 20px;
      top: 36px;
      width: 10px;
    }
  }
}

// Orders List
.order-list {
  .button-container {
    a {
      background-color: grayscale-color("black");
      border: 2px solid grayscale-color("black");
      border-radius: 3px;
      color: nurx-color("white");
      font-size: 14px;
      max-width: 350px;
      padding: 15px;
      width: 100%;
    }
  }

  .component-order {
    border: 1px solid grayscale-color("light-gray");

    .component-body {
      .btn {
        margin: 0;

        @media (max-width: $phone) {
          margin-top: $unit-sm;
        }
      }
    }
  }

  .order-status-text {
    color: $text-muted;
    font-size: 16px;
  }
}
