.animation-fade-out {
  animation: fadeout 0.2s ease-in-out forwards;
}

@keyframes fadeout {
  from {
    @include opacity(0);
  }

  to {
    @include opacity(1);
  }
}
