$border-color: grayscale-color("light-gray");

.__user-subscription-list-item-v2__5d566 .card {
  border: 1px solid $border-color;
  border-radius: 6px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.__user-subscription-list-item-v2__5d566 .item {
  display: flex;
  padding: {
    top: 18px;
    bottom: 18px;
    left: 16px;
    right: 10px;
  }
}

.__user-subscription-list-item-v2__5d566 .item-content {
  flex: 1;
}

.__user-subscription-list-item-v2__5d566 .item-icon {
  color: grayscale-color("black");
}

.__user-subscription-list-item-v2__5d566 .item-title {
  position: relative;
  font-size: 14px;
  color: grayscale-color("black");
  line-height: 20px;
}

.__user-subscription-list-item-v2__5d566 .item-description {
  font-size: 12px;
  line-height: 20px;
  color: grayscale-color("gray");
  margin-bottom: 8px;
}

.__user-subscription-list-item-v2__5d566 .item-refill {
  font-size: 12px;
  line-height: 20px;
  color: grayscale-color("black");
  margin-bottom: 0;
}

.__user-subscription-list-item-v2__5d566 .button-wrapper {
  border-top: 1px solid $border-color;
  padding: 12px 16px;
}
