.__admin__zen-task__insurance-ocr-display__77245 .insurance-ocr-controls {
  align-items: center;
  display: flex;
  justify-content: space-evenly;

  button {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.3;
  }
}
