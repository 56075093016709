.__styles__follow-up__survey__intro__752aa .header-container {
  $gutter-spacing: 15px;
  background-color: pastel-color("blue");
  margin: 0 (-$gutter-spacing) $vertical-spacing; // negative margins to allow background to cover gutters
  padding: 0 $gutter-spacing $vertical-spacing; // compensate for negative left and right margin to retain horizontal alignment

  .title-img {
    padding: $vertical-spacing-double;
  }
}

.__styles__follow-up__survey__intro__752aa .body-container {
  flex-grow: 1;
  margin-bottom: $vertical-spacing;
}
