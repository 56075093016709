.__admin__user__alert-modal__4d5ab .alert-modal {
  background-color: rgba(0, 0 , 0 , 0.4); /* Fallback color */
  display: block;
  height: 100%; /* Full height */
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  position: fixed; /* Stay in place */
  top: 0;
  width: 100%; /* Full width */
  z-index: 1; /* Sit on top */
}

/* Modal Content */
.__admin__user__alert-modal__4d5ab .modal-container {
  background-color: nurx-color("white");
  border: 1px solid grayscale-color("dark-gray");
  border-radius: 20px;
  margin: auto;
  padding: 25px 50px 50px 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
}

.__admin__user__alert-modal__4d5ab .modal-top {
  display: block;
  font-weight: 500;

  .close-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .close {
      background-color: transparent;
      border: hidden;
      font-size: 20px;
      padding: 10px;
    }
  }

  .header-container {
    text-align: center;
  }
}

.__admin__user__alert-modal__4d5ab .footer-container {
  display: flex;
  justify-content: flex-end;

  button {
    align-items: center;
    display: flex;
  }
}
