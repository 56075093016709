// style-namespace "checkout/cart-item-box-v3"

.__checkout__cart-item-box-v3__ffd48 {
  background-color: grayscale-color("lighter-gray");
  border: 1px solid grayscale-color("light-gray");
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  hr {
    border-color: grayscale-color("gray-five");
    width: 100%;
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;

    .col-1 {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 20%;
      padding: 0 12px 0 0;

      .timeline-bar {
        border-left: 1px solid grayscale-color("light-gray");
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 1px;

        &.gap-fix {
          flex-grow: inherit;
          height: 16px;
          margin-top: -16px;
        }
      }

      .timeline-img {
        border: 1px solid grayscale-color("light-gray");
        border-radius: 100%;
        height: 50px;
        overflow: hidden;
        width: 50px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

    }

    >.row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;
      width: 100%;

      &:nth-child(2) {
        margin-top: 16px;
      }

      .col-2 {
        flex: auto;
        max-width: 100%;

        p {
          color: grayscale-color("gray");
          font-size: 12px;
          margin-bottom: 0;
        }

        .sub-heading {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }

      >.col-3 {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        padding: 0;
        width: fit-content;

        .price-sub-heading {
          margin-bottom: 0;
          text-align: right;
        }

        p {
          font-size: 10px;
          color: grayscale-color("gray");
          text-align: right;
        }
      }
    }
  }
}
