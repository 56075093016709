.es-control {
  z-index: 2;
}

.es-input > input {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}

.ember-select.static-dropdown .es-options {
  position: static;
}

.ember-select[data-disabled] input {
  background: #f5f5f5;
  color: #c3c3c3;
}

.ember-select[data-disabled] .es-control {
  background: #f5f5f5;
  border: 1px solid #ddd;
}

table .ember-select {
  border-collapse: initial;
}
