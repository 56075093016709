@include text-input-component(input);

.__controlled-input__efe3c .input-wrapper {
  margin-bottom: 20px;

  label {
    @include vertical-spacing-half;
    color: nurx-color("black");
    display: block;

    p.hint-text {
      font-size: 11px;
      font-weight: normal;
    }
  }

  input {
    // border-bottom-color: intentionally left empty to allow for customization inside of component stylesheets. Default color is #ddd.
    border-bottom-style: solid;
    border-radius: 3px 3px 0 0;
    margin: 0;
    min-height: 40px;
    padding: 0 3px;
    width: 100%;
  }
}
