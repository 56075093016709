.__ui-patient__button__ac22d.ui-patient-btn {
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: $font-weight-bold;
  padding: 15px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  &.color-black {
    background-color: grayscale-color("black");
    border-color: grayscale-color("black");
    color: grayscale-color("white");

    &:focus {
      background-color: grayscale-color("gray");
      border-color: grayscale-color("gray");
    }

    &:active,
    &:hover {
      background-color: grayscale-color("medium-gray");
      border-color: grayscale-color("medium-gray");
    }

    // Note: Specifying disabled last to have it
    //   take precedence over the other states
    &:disabled {
      background-color: grayscale-color("light-gray");
      border-color: grayscale-color("light-gray");
      opacity: 1;
    }
  }

  &.color-white {
    background-color: grayscale-color("white");
    border-color: grayscale-color("black");
    color: grayscale-color("black");

    &:focus {
      border-color: grayscale-color("gray");
      color: grayscale-color("gray");
    }

    &:active,
    &:hover {
      border-color: grayscale-color("medium-gray");
      color: grayscale-color("medium-gray");
    }

    &:disabled {
      background-color: grayscale-color("light-gray");
      border-color: grayscale-color("light-gray");
      opacity: 1;
    }
  }

  &.color-blue {
    background-color: pastel-color("blue");
    border-color: pastel-color("blue");
    color: grayscale-color("white");

    &:focus {
      background-color: pastel-color("blue-80");
      border-color: pastel-color("blue-80");
    }

    &:active,
    &:hover {
      background-color: pastel-color("blue-60");
      border-color: pastel-color("blue-60");
    }

    &:disabled {
      background-color: pastel-color("blue-40");
      border-color: pastel-color("blue-40");
    }
  }

  &.color-green {
    background-color: pastel-color("green");
    border-color: pastel-color("green");
    color: grayscale-color("white");

    &:focus {
      background-color: pastel-color("green-80");
      border-color: pastel-color("green-80");
    }

    &:active,
    &:hover {
      background-color: pastel-color("green-60");
      border-color: pastel-color("green-60");
    }

    &:disabled {
      background-color: pastel-color("green-40");
      border-color: pastel-color("green-40");
    }
  }

  &.color-peach {
    background-color: pastel-color("peach");
    border-color: pastel-color("peach");
    color: grayscale-color("white");

    &:focus {
      background-color: pastel-color("peach-80");
      border-color: pastel-color("peach-80");
    }

    &:active,
    &:hover {
      background-color: pastel-color("peach-60");
      border-color: pastel-color("peach-60");
    }

    &:disabled {
      background-color: pastel-color("peach-40");
      border-color: pastel-color("peach-40");
    }
  }

  &.color-pink {
    background-color: pastel-color("pink");
    border-color: pastel-color("pink");
    color: grayscale-color("white");

    &:focus {
      background-color: pastel-color("pink-80");
      border-color: pastel-color("pink-80");
    }

    &:active,
    &:hover {
      background-color: pastel-color("pink-60");
      border-color: pastel-color("pink-60");
    }

    &:disabled {
      background-color: pastel-color("pink-40");
      border-color: pastel-color("pink-40");
    }
  }

  &.color-purple {
    background-color: pastel-color("purple");
    border-color: pastel-color("purple");
    color: grayscale-color("white");

    &:focus {
      background-color: pastel-color("purple-80");
      border-color: pastel-color("purple-80");
    }

    &:active,
    &:hover {
      background-color: pastel-color("purple-60");
      border-color: pastel-color("purple-60");
    }

    &:disabled {
      background-color: pastel-color("purple-40");
      border-color: pastel-color("purple-40");
    }
  }

  &.color-transparent {
    background-color: transparent;
    border-color: transparent;
    color: grayscale-color("black");

    &:focus {
      border-color: grayscale-color("gray");
      color: grayscale-color("gray");
    }

    &:active,
    &:hover {
      background-color: transparent;
      border-color: grayscale-color("medium-gray");
    }

    &:disabled {
      background-color: grayscale-color("light-gray");
      border-color: grayscale-color("light-gray");
      opacity: 1;
    }
  }
}
