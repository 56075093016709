$progress-color: pastel-color("green");
$background-color: #f1f1f1;
$bar-radius: 2px;
$bar-height: 4px;
$label-color: grayscale-color("gray");

.__progress-bar__6f60d {
  display: flex;
  margin-bottom: 4px;

  li {
    flex-grow: 15;
    margin-right: 4px;

    &.active {
      &:first-of-type {
        flex-grow: 70;
      }
    }
  }

  label {
    color: $label-color;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.67;
  }

  // this needs some old fashioned cross browser css hacks
  progress {
    appearance: none;

    // firefox styling
    // firefox has a single progress bar pseudoelement, with the progress element
    // acting as the container for the background. firefox also comes with a default
    // border effect on the progress box.
    appearance: none;
    background-color: $background-color;
    border: 0;
    border-radius: $bar-radius;

    // IE styling - no pseudoelement selectors are available to style the progress bar.
    // instead, IE uses the color/background-color to style the colors of the bar
    color: $progress-color;
    height: $bar-height;
    margin: 0;
    padding: 0;
    width: 100%;

    &::-webkit-progress-value {
      background-color: $progress-color;
      border-radius: $bar-radius;
    }

    &::-webkit-progress-bar {
      background-color: $background-color;
      border-radius: $bar-radius;
    }

    &::-moz-progress-bar {
      background-color: $progress-color;
      border-radius: $bar-radius;
    }
  }
}
