.__anti-aging-intro__13e83 {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__anti-aging-intro__13e83 .intro {
  @include request-intro(pastel-color("pink-40"), 0);
}

.__anti-aging-intro__13e83 .intro-image {
  height: auto;
  margin: 0 auto;
  max-width: 210px;
}

.__anti-aging-intro__13e83 .intro-footer {
  text-align: center;
}

.__anti-aging-intro__13e83 li {
  padding: $unit-reg 0;
}
