.__patient-redirect__f678d .submit-button {
  border-radius: 3px;
  height: 44px;
  margin: auto;
  margin-bottom: 20px;
  width: 292px;
}

.__patient-redirect__f678d .description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 15px;
  padding: 5px;
  text-align: left;
}
