.__pharmacy-progress-bar__fc737 .flex-progress {
  display: flex;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}

.__pharmacy-progress-bar__fc737 .step-wrapper {
  flex: 1;
}

.__pharmacy-progress-bar__fc737 .step {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.__pharmacy-progress-bar__fc737 .progress-circle {
  align-items: center;
  background-color: nurx-color("white");
  border: 1px solid #b6bddc;
  border-radius: 1000px;
  color: #354052;
  display: flex;
  font-size: 17.5px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 35px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1.49;
  width: 35px;

  &.done {
    background-color: nurx-color("success");
    border-color: nurx-color("success");
    color: nurx-color("white");
  }
}

.__pharmacy-progress-bar__fc737 h3 {
  color: #354052;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-left: 5px;
}

.__pharmacy-progress-bar__fc737 .pharm-workflow-header-panel {
  padding: 18px;
}
