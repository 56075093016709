.__dispense-verify__f373b .input-btn {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  width: 140px;
}

.__dispense-verify__f373b .multi-item {
  flex-basis: 100%;
}
