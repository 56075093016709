/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// NOTE(jason 2019-03-03): This file is taken almost verbatum from the Boostrap 4 source code bootstrap-grid.scss file.
// All included files have the same name as their source code file or have "-min-for-grid" appended to the name.

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "functions-min-for-grid";
@import "variables-min-for-grid";

@import "mixins/breakpoints";
@import "mixins/grid-framework";
@import "mixins/grid";

@import "grid";
@import "utilities/display";
@import "utilities/flex";
@import "utilities/spacing";
