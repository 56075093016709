.__admin__user__ticket__cb96b .admin-user-ticket {
  padding: 0;

  .panel {
    margin-bottom: 0;

    .panel-heading {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;

      .panel-title,
      .panel-actions {
        padding: 4px 0;
      }

      .panel-title {
        align-items: flex-start;
        display: flex;
        flex-grow: 2;
        line-height: 1.2;
      }

      .panel-actions {
        .btn {
          align-items: center;
          display: flex;
          line-height: 1.2;
          margin: 0 4px 0 0;
          min-height: 24px;

          svg {
            min-height: 12px;
            min-width: 12px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .panel-body {
      padding: 15px 0;

      h4 {
        align-items: center;
        display: flex;
      }

      .tag {
        margin: 0 0 4px 0;
        padding: 10px;
        width: 100%;
      }

      .topic-grid {
        display: grid;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        grid-template-columns: 1fr 1fr 1fr;

        .btn {
          margin: 0;
        }
      }
    }
  }
}

.__admin__user__ticket__cb96b.urgent {
  border-left: 5px solid nurx-color("danger");
}
