.__admin__labs__order__73925 .btn-selectable {
  background: nurx-color("white");
  border: 1px solid #e6e7ea;
  color: #a2a6af;

  &:hover,
  &.hover {
    background: #f9f9fb;
    border-color: #e6e7ea;
    box-shadow: none;
    color: #6c727e;
  }
}

.__admin__labs__order__73925 .btn-selected {
  background: $primary-blue;
  border: 1px solid $primary-blue;
  color: nurx-color("white");

  &:hover {
    background: nurx-color-level("accent", -1);
    border: 1px solid nurx-color-level("accent", -1);
    color: nurx-color("white");
  }
}

.__admin__labs__order__73925 button[disabled] {
  color: nurx-color("white");
  pointer-events: none;
}

.__admin__labs__order__73925 .iframe-container {
  height: 500px;
  width: 100%;
}
