%iphone-padding-top {
  padding-top: $iphonex-top-spacing;
}

%iphone-padding-right {
  padding-right: $iphone-right-spacing;
}

%iphone-padding-bottom {
  padding-bottom: $iphone-bottom-spacing;
}

%iphone-padding-left {
  padding-left: $iphone-left-spacing;
}
