$nurx-colors: (
  "primary"    : #fc6855,
  "secondary"  : #ff6846,
  "accent"     : #319eef, // blue from Nurx.com wordpress
  "black"      : #484b5d,
  "white"      : #fff,

  // color states
  "success"    : #51e7ab,
  "info"       : #3ea2ff,
  "warning"    : #ffd03e,
  "danger"     : #ff463d
);

@function nurx-color($key: "primary") {
  @return get-color($nurx-colors, $key, "nurx-color");
}

// Example:
// div {
//  $header-background: nurx-color("primary");
// }

// For tinting + shading:

$color-interval: 10% !global;

@function nurx-color-level($color-name: "primary", $level: 0) {
  $color: nurx-color($color-name);
  $color-base: if($level < 0, #000, #fff);
  $leveler: if($level < 0, -1, 1);

  @return mix($color-base, $color, $level * $color-interval * $leveler);
}

// Example: 30% tint and 55% shade primary colour:
// div {
//  $footer-background: nurx-color-level("primary", 3);
//  $header-background: nurx-color-level("primary", -5.5);
// }
