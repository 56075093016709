.__acne-guardian-consent-terms__bd618 ol {
  padding-left: 20px;

  li {
    list-style-type: auto;
  }
}

.__acne-guardian-consent-terms__bd618 ol.lower-alpha {
  li {
    list-style-type: lower-alpha;
  }
}

.__acne-guardian-consent-terms__bd618 ul {
  padding-left: 20px;

  li {
    list-style-type: disc;
  }
}
