.admin-jurisdictions {
  .add-jurisdiction-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .add-jurisdiction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    div {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;

      input {
        height: 30px;
        margin: 0 10px;
      }
    }
  }

  .jurisdictions-highlight {
    background-color: #fff2e6;
  }

  .jurisdictions-sharp-highlight {
    background-color: #fda34e;
  }
}
