.__user-follow-up-create__a6365 .user-follow-up-create {
  border: 4px solid #add8e6;
  border-radius: 5px;
  margin: 0 10px 10px;
  padding: 10px 0 0;

  .controls-wrapper {
    display: flex;
    flex-direction: row;

    .option-container {
      flex-grow: 1;
      max-width: 30%;
      min-width: 85px;
    }

    .recurring-option-container {
      flex-grow: 1;
      max-width: 50%;
      min-width: 85px;
    }

    .form-control {
      height: 36.78px;
    }
  }

  .actions-container {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;

    button {
      align-self: flex-end;
      height: 36.78px;
      padding: 7px;
      text-align: center;
      width: 48%;
    }
  }
}
