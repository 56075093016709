.__styles__guardian-consent-verification__f02dc header {
  h1 {
    font-size: 36px;
    padding-top: $unit-md;
  }
}

.__styles__guardian-consent-verification__f02dc .guardian-information {
  background-color: grayscale-color("lighter-gray");
  padding: $unit-reg;
}

.__styles__guardian-consent-verification__f02dc .set-alignment {
  align-items: flex-start;
}
