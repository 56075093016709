.__insurance-uploads__3981e ul li {
  list-style: disc inside;
}

.__insurance-uploads__3981e .photo-container {
  position: relative;
  width: 90%;

  img {
    width: 100%;
  }

  .upload-icon {
    background-color: grayscale-color("black");
    bottom: 20px;
    color: grayscale-color("white");
    font-size: 20pt;
    padding: 10px;
    position: absolute;
    right: -20px;
  }
}
