.__admin-messenger__f4ed5 {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs {
    background: nurx-color("white");
    display: flex;
    height: 29px; // remove once children are sized properly
  }
}
