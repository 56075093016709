.__styles__pages__login-template__bbe6f .nurx_body.login-template {
  padding-top: $unit-md;

  @include media-breakpoint-down(xs) {
    height: auto;
    min-height: 80vh;
    padding-bottom: $unit-md;
    padding-top: $unit-reg;
  }

  .container {
    @extend %iphone-padding-top;
    max-width: 560px;
  }

  .card {
    border-bottom: 4px solid #eeeef3;
    border-radius: $border-radius-standard;
  }

  .card-content {
    border: 0;
    padding: $unit-md $unit-lg;

    @include media-breakpoint-down(xs) {
      padding: $unit-md $unit-reg;
    }
  }

  &:not(.is-regular-font-size) .card-content {
    font-size: 1em;
  }

  h1 {
    @include text-bold;
    color: #646475;
    font-size: 1.64em;
    line-height: $unit-md;
    margin-bottom: $unit-md;
    margin-top: $unit-reg;
    text-align: center;

    @include media-breakpoint-down(xs) {
      font-size: 1.5em;
      line-height: 1.3em;
      margin-bottom: 24px;
      padding: 0 5%;
    }
  }

  h3 {
    color: #646475;
  }

  .content-container {
    p.instructions {
      @include text-bold;
      color: #585858;
      display: inline-block;
      font-size: 1.3em;
      padding-bottom: $unit-reg;
      width: 100%;

      @include media-breakpoint-down(xs) {
        margin-bottom: $unit-reg;
        padding: 0;
        text-align: center;
      }

      span.user-email {
        // TODO – Long emails still break shit. Set a white-space wrap.
        display: block;
        margin-top: $unit-sm;
      }

      &.center {
        // TODO #1125 – Replace all instances of these with utility class .text-center
        text-align: center;
      }

      &.padding-bottom {
        // TODO – Replace all instances of these with utility class .padding-bottom-md/sm or .margin-bottom-md/sm
        padding-bottom: $unit-md;
      }
    }

    label {
      color: #646475;
      display: inline-block;
      font-size: 1.1em;
      line-height: 1.5em;
      margin-top: $unit-reg;
      width: 100%;

      span {
        color: #a6a6ad;
      }
    }

    input {
      border: 2px solid rgb(238, 238, 243);
      border-radius: $border-radius-standard;
      box-shadow: none;
      color: #555;
      font-size: 1.1em;
      height: 42px;
      margin-bottom: 12px;
      padding-bottom: 7px;
      padding-left: $unit-reg;
      padding-right: $unit-reg;
      padding-top: $unit-sm;
      width: 100%;

      @include media-breakpoint-down(xs) {
        margin-bottom: $unit-sm;
      }

      @include media-breakpoint-down(xs) {
        font-size: 1.16em;
        padding: 13px 8px 12px;
      }

      &:focus {
        border: 2px solid nurx-color-level("accent", 7);
      }
    }
  }

  // ORDER SUMMARY - PREVIEW
  .order-summary-preview {
    .order-details {
      border-radius: $border-radius-standard;
      display: table;
      margin: -$unit-reg auto;
      max-width: 370px;
      padding-bottom: $unit-reg;
      padding-left: $unit-md;
      padding-right: $unit-reg;
      padding-top: 0;
      text-align: center;
      width: 100%;

      @include media-breakpoint-down(xs) {
        padding: 0 $unit-reg;
      }

      &.has-logo {
        span {
          text-align: left;

          @include media-breakpoint-down(xs) {
            text-align: center;
          }
        }
      }

      .brand-details {
        display: table-cell;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        width: 55%;

        @include media-breakpoint-down(xs) {
          display: block;
          font-size: 1.2em;
          padding-bottom: $unit-reg;
          padding-top: $unit-sm;
          width: 100%;
        }

        span {
          @include text-bold;
          color: nurx-color-level("black", 5.5);
          display: block;
          font-size: 1.2em;
          line-height: 1.4em;

          &.brand-name {
            @include text-bold;
            color: nurx-color-level("black", 2.5);
          }
        }
      }

      .brand-logo {
        display: table-cell;
        vertical-align: middle;

        @include media-breakpoint-down(xs) {
          display: block;
        }

        img {
          display: block;
          height: auto;
          margin: 0 auto;
          max-width: 170px;
          width: 100%;

          @include media-breakpoint-down(xs) {
            margin: 0 auto;
            max-height: 80px;
            max-width: 150px;
          }
        }
      }
    }
  }

  .security-badge-container {
    .badges {
      text-align: center;

      img {
        height: 54px;
        margin: 0 10px;
        width: auto;
      }
    }

    .text-disclaimer {
      color: nurx-color-level("black");
      font-size: 1em;
      line-height: 1.6em;
      margin-top: $unit-sm;
      text-align: center;

      a {
        display: inline-block;

        &:hover,
        &:focus,
        &:active {
          color: nurx-color-level("black", 2.5);
        }
      }
    }
  }
}
