.__migraine-intro__98640 {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__migraine-intro__98640 .intro {
  @include request-intro(pastel-color("green-40"), 0);
  height: 400px;
}

.__migraine-intro__98640 .intro-image {
  bottom: 0;
  height: 180px;
  left: 0;
  margin: 0;
  object-fit: contain;
  position: absolute;
  top: 180px;
}

.__migraine-intro__98640 .list-item-header {
  color: grayscale-color("black");
  font-weight: $font-weight-bold;
  padding-bottom: 4px;
}

.__migraine-intro__98640 .intro-footer {
  text-align: center;
}

.__migraine-intro__98640 li {
  padding: $unit-reg 0;
}
