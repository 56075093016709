@font-face {
  font-family: "Pe-icon-7-stroke";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("/assets/fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("/assets/fonts/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("/assets/fonts/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("/assets/fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c [class^="pe-7s-"],
.__styles__dashboard-internal__scss-all__pe-7-icons__b687c [class*=" pe-7s-"] {
  display: inline-block;
  font-family: "Pe-icon-7-stroke";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-album::before {
  content: "\e6aa";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-arc::before {
  content: "\e6ab";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-back-2::before {
  content: "\e6ac";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bandaid::before {
  content: "\e6ad";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-car::before {
  content: "\e6ae";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-diamond::before {
  content: "\e6af";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-door-lock::before {
  content: "\e6b0";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-eyedropper::before {
  content: "\e6b1";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-female::before {
  content: "\e6b2";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-gym::before {
  content: "\e6b3";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-hammer::before {
  content: "\e6b4";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-headphones::before {
  content: "\e6b5";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-helm::before {
  content: "\e6b6";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-hourglass::before {
  content: "\e6b7";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-leaf::before {
  content: "\e6b8";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-magic-wand::before {
  content: "\e6b9";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-male::before {
  content: "\e6ba";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-map-2::before {
  content: "\e6bb";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-next-2::before {
  content: "\e6bc";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-paint-bucket::before {
  content: "\e6bd";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-pendrive::before {
  content: "\e6be";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-photo::before {
  content: "\e6bf";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-piggy::before {
  content: "\e6c0";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-plugin::before {
  content: "\e6c1";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-refresh-2::before {
  content: "\e6c2";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-rocket::before {
  content: "\e6c3";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-settings::before {
  content: "\e6c4";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-shield::before {
  content: "\e6c5";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-smile::before {
  content: "\e6c6";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-usb::before {
  content: "\e6c7";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-vector::before {
  content: "\e6c8";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-wine::before {
  content: "\e6c9";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cloud-upload::before {
  content: "\e68a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cash::before {
  content: "\e68c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-close::before {
  content: "\e680";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bluetooth::before {
  content: "\e68d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cloud-download::before {
  content: "\e68b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-way::before {
  content: "\e68e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-close-circle::before {
  content: "\e681";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-id::before {
  content: "\e68f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-up::before {
  content: "\e682";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-wristwatch::before {
  content: "\e690";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-up-circle::before {
  content: "\e683";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-world::before {
  content: "\e691";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-right::before {
  content: "\e684";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-volume::before {
  content: "\e692";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-right-circle::before {
  content: "\e685";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-users::before {
  content: "\e693";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-left::before {
  content: "\e686";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-user-female::before {
  content: "\e694";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-left-circle::before {
  content: "\e687";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-up-arrow::before {
  content: "\e695";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-down::before {
  content: "\e688";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-switch::before {
  content: "\e696";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-angle-down-circle::before {
  content: "\e689";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-scissors::before {
  content: "\e697";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-wallet::before {
  content: "\e600";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-safe::before {
  content: "\e698";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-volume2::before {
  content: "\e601";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-volume1::before {
  content: "\e602";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-voicemail::before {
  content: "\e603";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-video::before {
  content: "\e604";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-user::before {
  content: "\e605";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-upload::before {
  content: "\e606";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-unlock::before {
  content: "\e607";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-umbrella::before {
  content: "\e608";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-trash::before {
  content: "\e609";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-tools::before {
  content: "\e60a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-timer::before {
  content: "\e60b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-ticket::before {
  content: "\e60c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-target::before {
  content: "\e60d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-sun::before {
  content: "\e60e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-study::before {
  content: "\e60f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-stopwatch::before {
  content: "\e610";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-star::before {
  content: "\e611";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-speaker::before {
  content: "\e612";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-signal::before {
  content: "\e613";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-shuffle::before {
  content: "\e614";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-shopbag::before {
  content: "\e615";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-share::before {
  content: "\e616";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-server::before {
  content: "\e617";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-search::before {
  content: "\e618";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-film::before {
  content: "\e6a5";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-science::before {
  content: "\e619";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-disk::before {
  content: "\e6a6";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-ribbon::before {
  content: "\e61a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-repeat::before {
  content: "\e61b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-refresh::before {
  content: "\e61c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-add-user::before {
  content: "\e6a9";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-refresh-cloud::before {
  content: "\e61d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-paperclip::before {
  content: "\e69c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-radio::before {
  content: "\e61e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-note2::before {
  content: "\e69d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-print::before {
  content: "\e61f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-network::before {
  content: "\e69e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-prev::before {
  content: "\e620";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-mute::before {
  content: "\e69f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-power::before {
  content: "\e621";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-medal::before {
  content: "\e6a0";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-portfolio::before {
  content: "\e622";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-like2::before {
  content: "\e6a1";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-plus::before {
  content: "\e623";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-left-arrow::before {
  content: "\e6a2";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-play::before {
  content: "\e624";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-key::before {
  content: "\e6a3";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-plane::before {
  content: "\e625";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-joy::before {
  content: "\e6a4";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-photo-gallery::before {
  content: "\e626";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-pin::before {
  content: "\e69b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-phone::before {
  content: "\e627";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-plug::before {
  content: "\e69a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-pen::before {
  content: "\e628";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-right-arrow::before {
  content: "\e699";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-paper-plane::before {
  content: "\e629";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-delete-user::before {
  content: "\e6a7";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-paint::before {
  content: "\e62a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bottom-arrow::before {
  content: "\e6a8";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-notebook::before {
  content: "\e62b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-note::before {
  content: "\e62c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-next::before {
  content: "\e62d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-news-paper::before {
  content: "\e62e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-musiclist::before {
  content: "\e62f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-music::before {
  content: "\e630";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-mouse::before {
  content: "\e631";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-more::before {
  content: "\e632";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-moon::before {
  content: "\e633";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-monitor::before {
  content: "\e634";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-micro::before {
  content: "\e635";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-menu::before {
  content: "\e636";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-map::before {
  content: "\e637";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-map-marker::before {
  content: "\e638";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-mail::before {
  content: "\e639";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-mail-open::before {
  content: "\e63a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-mail-open-file::before {
  content: "\e63b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-magnet::before {
  content: "\e63c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-loop::before {
  content: "\e63d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-look::before {
  content: "\e63e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-lock::before {
  content: "\e63f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-lintern::before {
  content: "\e640";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-link::before {
  content: "\e641";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-like::before {
  content: "\e642";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-light::before {
  content: "\e643";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-less::before {
  content: "\e644";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-keypad::before {
  content: "\e645";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-junk::before {
  content: "\e646";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-info::before {
  content: "\e647";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-home::before {
  content: "\e648";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-help2::before {
  content: "\e649";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-help1::before {
  content: "\e64a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-graph3::before {
  content: "\e64b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-graph2::before {
  content: "\e64c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-graph1::before {
  content: "\e64d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-graph::before {
  content: "\e64e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-global::before {
  content: "\e64f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-gleam::before {
  content: "\e650";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-glasses::before {
  content: "\e651";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-gift::before {
  content: "\e652";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-folder::before {
  content: "\e653";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-flag::before {
  content: "\e654";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-filter::before {
  content: "\e655";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-file::before {
  content: "\e656";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-expand1::before {
  content: "\e657";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-exapnd2::before {
  content: "\e658";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-edit::before {
  content: "\e659";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-drop::before {
  content: "\e65a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-drawer::before {
  content: "\e65b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-download::before {
  content: "\e65c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-display2::before {
  content: "\e65d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-display1::before {
  content: "\e65e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-diskette::before {
  content: "\e65f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-date::before {
  content: "\e660";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cup::before {
  content: "\e661";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-culture::before {
  content: "\e662";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-crop::before {
  content: "\e663";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-credit::before {
  content: "\e664";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-copy-file::before {
  content: "\e665";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-config::before {
  content: "\e666";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-compass::before {
  content: "\e667";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-comment::before {
  content: "\e668";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-coffee::before {
  content: "\e669";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cloud::before {
  content: "\e66a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-clock::before {
  content: "\e66b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-check::before {
  content: "\e66c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-chat::before {
  content: "\e66d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-cart::before {
  content: "\e66e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-camera::before {
  content: "\e66f";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-call::before {
  content: "\e670";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-calculator::before {
  content: "\e671";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-browser::before {
  content: "\e672";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-box2::before {
  content: "\e673";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-box1::before {
  content: "\e674";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bookmarks::before {
  content: "\e675";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bicycle::before {
  content: "\e676";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-bell::before {
  content: "\e677";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-battery::before {
  content: "\e678";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-ball::before {
  content: "\e679";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-back::before {
  content: "\e67a";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-attention::before {
  content: "\e67b";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-anchor::before {
  content: "\e67c";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-albums::before {
  content: "\e67d";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-alarm::before {
  content: "\e67e";
}

.__styles__dashboard-internal__scss-all__pe-7-icons__b687c .pe-7s-airplay::before {
  content: "\e67f";
}
