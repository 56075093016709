
table {
  // Checkbox in tables
  label.table-checkbox {
    cursor: pointer;
    margin: 0;
    padding: $unit-sm;
  }

  .table-action-group {
    button.table-action {
      height: 26px;
      padding: $unit-xs $unit-sm;

      &:not(:last-child) {
        margin-right: $unit-xs;
      }

      .fa {
        margin: 0;
      }
    }

    button.table-action-blue {
      background-color: #e6edfd;
      transition: background-color 0.5s ease;

      .fa {
        color: #82a5f6;
        transition: color 0.5s ease;
      }

      &:hover {
        background-color: #cfddfb;

        .fa {
          color: #7b9cea;
        }
      }
    }
  }
}

// Vertically center data
table.vertical-center {
  td {
    vertical-align: middle !important; // !important OK
  }
}
