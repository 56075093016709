$row-padding: 20px;

.__styles__ios-nav__account__2ef2b .contextual-item {
  @include uniform-padding($row-padding);
  border-bottom: 1px solid grayscale-color("lighter-gray");
  color: grayscale-color("black");
  display: block;
}

.__styles__ios-nav__account__2ef2b .progression-icon {
  color: grayscale-color("light-gray");
}
