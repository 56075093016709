.modal-full-page {
  .section-actionable {
    .module-heading {
      border-bottom: 0;
      padding-bottom: 0;

      .module-title {
        @include text-bold;
        font-size: 18px;
        line-height: 35px;
        margin-top: 0;
      }
    }

    .module-content {
      margin-top: 0;
      padding-top: 0;

      .modal-flex-box {
        display: flex;
        flex-direction: column;
        height: 75vh;

        .section-title {
          @include text-bold;
          font-size: 18px;
          line-height: 35px;
          margin-top: 0;
        }

        .bottom-button-wrapper {
          @media (max-width: $phone) {
            margin-top: auto;
          }
        }
      }
    }

    .form-control {
      border-radius: 45px;
    }
  }
}
