.__loading-panel__0e6a0.centered-on-screen {
  bottom: calc(50% - 32px);
  left: 32px;
  position: absolute;
  width: calc(100% - 32px);
}

.__loading-panel__0e6a0:not(.centered-on-screen) {
  padding-bottom: $vertical-spacing;
  padding-top: $vertical-spacing;
}

.__loading-panel__0e6a0 .loading {
  animation: spin 1s infinite;
  color: grayscale-color("light-gray");
  margin: 0 auto;
  width: 64px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
