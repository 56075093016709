.__billing-step-card-selector__8da16 .add-card-placeholder {
  background-color: grayscale-color("lighter-gray");
  border: 1px solid grayscale-color("light-gray");
  border-radius: 10px;
  padding-top: 60%;
  position: relative;
  width: 100%;

  .content-container {
    bottom: 0;
    left: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .add-card-icon {
    background-color: $primary-blue;
    border-radius: 50%;
    height: 30px;
    width: 30px;

    .plus-icon {
      height: 10px;
      width: 10px;
    }
  }

  .add-card-label {
    margin-left: 20px;
  }
}
