.__styles__service__herpes-treatment__230c3 .back-button {
  position: relative;
  top: $vertical-spacing;
}

.__styles__service__herpes-treatment__230c3 .color-overlay--bg {
  background-color: pastel-color("blue-40");
}

.__styles__service__herpes-treatment__230c3 .header {
  position: relative;
}

.__styles__service__herpes-treatment__230c3 .header-content {
  position: absolute;
}

.__styles__service__herpes-treatment__230c3 .herpes-background-image {
  display: block;
  height: 540px;
  margin: 0 auto $vertical-spacing-double;
  width: 375px;
}

.__styles__service__herpes-treatment__230c3 .title-img {
  display: block;
  height: 176px;
  margin: $vertical-spacing-double auto 0;
}

.__styles__service__herpes-treatment__230c3 .title-subtext {
  padding: $vertical-spacing;
  text-align: center;
}
