.__styles__request__start__telehealth-consent__07c55 .img-heading {
  @include vertical-spacing-double;
  margin: 0 15px 20px;
  max-width: 600px;
  width: calc(100% - 30px);
}

.__styles__request__start__telehealth-consent__07c55 p {
  font-size: 14px;
  font-weight: 500;
}

.__styles__request__start__telehealth-consent__07c55 .disclaimer {
  a {
    display: inline;
    margin-right: -3px; // fixes bug where component adds trailing space
    text-decoration: underline;
  }
}

.__styles__request__start__telehealth-consent__07c55 .form-background {
  background-color: grayscale-color("lighter-gray");
  border-radius: 3px;
  padding: 15px;
}

.__styles__request__start__telehealth-consent__07c55 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.__styles__request__start__telehealth-consent__07c55 label {
  margin-bottom: $unit-sm;
}

.__styles__request__start__telehealth-consent__07c55 input {
  padding: $unit-xs $unit-sm;
}

.__styles__request__start__telehealth-consent__07c55 .subtext {
  margin-bottom: 12px;
}
