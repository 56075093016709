$border-color: #eaeaf1;
$hover-color: #d3d3d3;

@function custom-darken($color, $amount) {
  @return mix(black, $color, $amount);
}

.__styles__admin__user__payments__71f6b .payments-header {
  margin-bottom: 20px;

  .label {
    height: 15px;
    margin-left: 5px;
  }
}

.__styles__admin__user__payments__71f6b .payment {
  &:hover {
    background-color: $hover-color;
    cursor: pointer;
  }
}

.__styles__admin__user__payments__71f6b .payments-table {
  font-size: 12px;
  max-width: 1200px;

  tr {

    // Stripe the table
    &:nth-child(even) {
      background-color: grayscale-color("lighter-gray");
    }

    // Add border to last row
    &:last-of-type {
      border-bottom: 2px solid $border-color;
    }

    th {
      padding: 0 4px 4px; // remove padding from top of table
    }

    td {
      border-right: 1px solid $border-color;
      padding: 10px 4px;

      &:first-of-type {
        border-left: 1px solid $border-color;
      }
    }
  }
}

.__styles__admin__user__payments__71f6b .nav-tabs {
  li {
    a {
      color: #000;

      &:hover {
        color: custom-darken(#000, 10%);
      }
    }

    &.active {
      a {
        border-bottom: 2px solid #539ce8;
        color: #000;
      }
    }
  }
}
