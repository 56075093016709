.__admin__user__health-attribute__grouped-display__b23e5 .panel-heading {
  .panel-title {
    align-items: center;
    display: flex;

    .critical-health-info-group {
      display: flex;
      margin-right: $unit-sm;

      .critical-health-info-group-icon {
        display: inline-block;
        height: 100%;
        margin-right: $unit-xs;
        width: 20px;
      }
    }
  }

  // button to show/hide data history
  .all-histories {
    align-items: center;
    display: flex;

    span {
      text-align: left;
    }
  }
}
