.__egt-intro__fb345 {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__egt-intro__fb345 .intro {
  @include request-intro(pastel-color("blue"));
  color: grayscale-color("black");
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .request-intro-content {
    padding-bottom: 0; // to make hero image flush with the bottom
  }

  ul {
    margin: 0 auto;
    width: 80%;

    li {
      list-style-type: disc;
      margin: 0;
      padding-top: 0;
    }
  }
}

.__egt-intro__fb345 .intro-image {
  height: auto;
  margin: 0 auto;
  max-width: 210px;
}

.__egt-intro__fb345 .how-it-works {
  padding: 0 0 1rem;
}

.__egt-intro__fb345 .pricing {
  text-align: center;
}
