$light-blue-tint: rgb(244, 249, 255);

$primary-blue: nurx-color-level("accent", 1);
$placeholder-gray: rgb(175, 176, 183);
$info-header-blue: #d8ecf1;

// NOTE(jason 2019-02-19): these are much lighter than the pastels below. Perhaps these should use nurx-color-level?
$rebrand-light-blue-grey: rgb(219, 236, 240);
$rebrand-orange-pink: rgb(252, 104, 85);

$section-activity-list-item-actionable-icon-color: #ff6884;
$border-color: #ebebf1;

// REBRAND-CLEANUP - replace with new grayscales
// light gray:
$text-muted: #aaaab7;

// App Login page
$app-secondary-red: #963844;
$copy: grayscale-color("gray");
$charcoal: #616670;
$light-blue-gray: #f9f9fb;
