.__pill-item__1ce57 {
  background-color: #616670;
  border-radius: 3px;
  display: inline-block;
  padding-top: 0.3em;
  text-align: center;

  &:not(:first-child) {
    margin-left: 2px;
  }

  // TODO (michael.gregory}: refactor these legacy label rules
  &.label-primary {
    background-color: nurx-color-level("accent", 1);
  }

  &.label-success {
    background-color: nurx-color("success");
  }

  &.label-info {
    background-color: #58bbee;
  }

  &.label-warning {
    background-color: #f8a13f;
  }

  &.label-danger {
    background-color: nurx-color("danger");
  }

  &.label-actionable {
    background-color: #ff6884;
  }

  &.label-new {
    background-color: #75c181;
  }

  &.label-normal {
    background-color: #58bbee;
  }

  &.label-high {
    background-color: #f8a13f;
  }

  &.label-low,
  &.label-grey {
    background-color: #c3c3c3;
  }

  &.label-critical,
  &.label-pink {
    background-color: #ea5395;
  }

  &.label-purple {
    background-color: #8a40a7;
  }

  &.label-yellow {
    background-color: #f2b542;
  }

  &.label-open {
    @include text-normal;
    background-color: none;
    color: #75c181;
    font-size: 12px;
  }

  &.label-closed {
    @include text-normal;
    background-color: none;
    color: nurx-color("danger");
    font-size: 12px;
  }

  &.label-todo {
    @include text-normal;
    background-color: none;
    color: #f8a13f;
    font-size: 12px;
  }

  &.label-review {
    @include text-normal;
    background-color: none;
    color: #616670;
    font-size: 12px;
  }

  &.label-progress {
    @include text-normal;
    background-color: none;
    color: #8a40a7;
    font-size: 12px;
  }
}
