.__cross-sell-tile__0b392 {
  height: 156px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 20px;

  &.background-green {
    background: pastel-color("green");
  }

  &.background-pink {
    background: pastel-color("pink");
  }

  &.background-purple {
    background: pastel-color("purple");
  }

  &.background-blue {
    background: pastel-color("blue");
  }

  &.background-orange {
    background: pastel-color("peach");
  }
}

.__cross-sell-tile__0b392 .illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;

  img {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
}

.__cross-sell-tile__0b392 .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
}

.__cross-sell-tile__0b392 .link {
  width: 100%;
  height: 33px;
  background-color: grayscale-color("black");
  color: nurx-color("white");
  line-height: 33px;
  font-size: 12px;
  text-align: center;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
