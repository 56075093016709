.__zen-verify-task-view__98ebe .h-100 {
  height: 100%;
}

.__zen-verify-task-view__98ebe .bb-0 {
  border-bottom: 0;
}

.__zen-verify-task-view__98ebe .label {
  letter-spacing: 0.1em;
}
