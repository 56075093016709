.__services-list__a8bbb .main-offering,
.__services-list__a8bbb .single-offering {
  align-items: center;
  border-bottom: 1px solid rgba(221, 221, 221, 0.4);
  color: nurx-color("black");
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;

  .illustration,
  .illustration > div {
    align-content: center;
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    overflow: hidden;
    width: 50px;

    img {
      max-height: 50px;
      max-width: 50px;
    }
  }

  .title {
    flex-grow: 2;
    letter-spacing: 0;
    margin-left: 20px;

    h4 {
      font-size: 16px;
    }
  }

  .icon {
    margin: 0;
    padding: 0;
    width: 24px;
  }
}

.__services-list__a8bbb .services-list-legal {
  padding: 15px 0 0 0;

  .subtext {
    margin-bottom: 0;
  }
}
