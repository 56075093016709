.__navigation-logo-header__8a2e4 .nav-logo-header {
  width: 100%;
}

.__navigation-logo-header__8a2e4 .logo-wrap {
  line-height: 30px;
  margin: 0 auto;
  width: fit-content;
}

.__navigation-logo-header__8a2e4 .logo {
  height: auto;
  width: 84px;
}
