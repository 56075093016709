.__patient-status-card__5e741 {
  border: 1px solid grayscale-color("light-gray");
  margin-bottom: 2 * 10px;
}

.__patient-status-card__5e741 .status-header {
  background-color: grayscale-color("lighter-gray");
  border-bottom: 1px solid grayscale-color("light-gray");
  color: grayscale-color("black");
  cursor: pointer;
  display: flex;
}

.__patient-status-card__5e741 .status-image {
  background-color: #d7e8ec;
  background-position: center center;
  background-repeat: no-repeat;
  border-right: 1px solid grayscale-color("light-gray");
  flex: 0 0 70px;
  height: auto;
  width: 70px;
}

.__patient-status-card__5e741 .status-wrapper {
  padding: 10px;
  width: 100%;
}

.__patient-status-card__5e741 .status-title {
  font-size: 14px;
}

.__patient-status-card__5e741 .status-description {
  color: grayscale-color("gray");
  margin: 0;
}

.__patient-status-card__5e741 i {
  align-self: center;
  padding-right: 10px;
}

.__patient-status-card__5e741 .status-prompt {
  display: flex;
  margin: 0;
  padding: 10px;
}

.__patient-status-card__5e741 .status-prompt-text {
  align-self: center;
  color: grayscale-color("black");
  font-size: 14px;
  padding-left: 10px;
}
