.__cross-care-list-cta__487c6 .cta-list-container {
  height: auto;
  border-radius: 5px;
  align-content: center;
  padding: 26px 24px;
  margin-bottom: 40px;
  width: 100%;
  background: pastel-color("blue-40");
  text-align: center;
  flex-grow: 0;
}

.__cross-care-list-cta__487c6 .heart-icon {
  margin-bottom: 20px;
  padding: 0;
  width: 32px;
}

.__cross-care-list-cta__487c6 .list-item-container {
  background-color: nurx-color("white");
  border-radius: 5px;
  display: grid;
  padding: 12px 12px;
  margin-bottom: 12px;
  text-align: left;
}

.__cross-care-list-cta__487c6 .footer {
  height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    color: grayscale-color("black");
    text-decoration: underline;
  }
}
