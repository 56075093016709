.__admin__user__health-attribute__indicator-display__949f9 .indicator-display {
  border-top: solid;
  border-width: 5px;
  display: inline-block;
  padding: 2px;

  &.baseline {
    border-color: nurx-color("success");
  }

  &.danger {
    border-color: nurx-color("danger");
  }

  &.warning {
    border-color: nurx-color("warning");
  }

  &.none {
    border-top: 0;
  }
  /* stylelint-disable-next-line -- Indicator status comes from our data model, so its ok to make an exception for linting in this case. */
  &.needs_review {
    border-top: 0;
  }
}
