.__styles__user__home__6506a .clinical-hold-subscriptions-container {
  border-radius: 5px;
  box-shadow: 0 2px 0 0 grayscale-color("light-gray");

  .clinical-hold-subscriptions {
    background-color: grayscale-color("white");
    border: 1px solid grayscale-color("light-gray");
    border-radius: 0;

    .card-header {
      background-color: grayscale-color("lighter-gray");
      padding: 15px;
    }

    .card-body {
      padding: 15px;

      p {
        margin: 0;
      }
    }

    .card-footer {
      background-color: grayscale-color("lighter-gray");
      padding: 15px;
    }

    .progress-icon {
      align-items: center;
      color: pastel-color("peach-80");

    }
  }
}
