.__styles__survey-end__substance-abuse-disorders__dc704 h2,
.__styles__survey-end__substance-abuse-disorders__dc704 h3 {
  color: grayscale-color("dark-gray");
}

.__styles__survey-end__substance-abuse-disorders__dc704 ul.suggestions-list {
  margin: 0 0 20px 20px;

  li {
    list-style: disc;
    padding: 0 0 10px 10px;

    &:first-child {
      padding-top: 0;
    }
  }
}
