.__styles__follow-up__survey__complete__c2e83 .page-content {
  $gutter-spacing: 15px;
  background-color: pastel-color("green-80");
  flex-grow: 1;
  margin: 0 (-$gutter-spacing) $vertical-spacing; // negative margins to allow background to cover gutters
  padding: 0 $gutter-spacing; // compensate for negative left and right margin to retain horizontal alignment

  .title-img {
    padding: $vertical-spacing-double;
  }
}
