// Progress Bar variables
$progress-step-graphic-container-width: 20px;
$progress-step-graphic-container-gap: $unit-reg;
$progress-step-graphic-container-line-width: 2px;
$progress-step-space-below: 20px;

.vertical-progress-bar-container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &>*:first-child {
    flex: 1;
  }
}

// Container Settings
.vertical-progress-bar {
  &.order-summary-step {
    margin-bottom: 0;
    margin-top: 0;
  }

  .progress-step {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      padding-bottom: $progress-step-space-below;
    }

    .step-graphic {
      position: relative;
      width: $progress-step-graphic-container-width;

      .action-required-alert-icon {
        color: nurx-color("danger");
      }
    }

    .step-info {
      width: calc(100% - #{$progress-step-graphic-container-width} - #{$progress-step-graphic-container-gap});
    }
  }
}

// Text Content
.vertical-progress-bar {
  .step-info {
    .step-info-title {
      @include text-bold;
    }

    .step-info-description {
      color: grayscale-color("gray");
    }

    .step-info-timestamp {
      color: $text-muted;
    }

    .action-required-text {
      color: nurx-color("danger");
      font-size: 12px;
      font-weight: normal;
      width: 250px;
    }
  }
}

// Graphic -- Icon
.vertical-progress-bar {
  .progress-step {
    .step-graphic-icon {
      background-image: url("/assets/images/ios-app/incomplete-checkmark-circle.svg");
      background-size: cover;
      padding-bottom: 100%;
      position: relative;
      width: $progress-step-graphic-container-width;
      z-index: 2;
    }

    // Change the icon if state is:
    &.active,
    &.complete,
    &.complete-to-issue,
    &.complete-to-fail,
    &.resolved {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/complete-checkmark-circle.svg");
      }
    }

    &.issue {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/progress-bar-issue.svg");
      }
    }

    &.fail {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/progress-bar-fail.svg");
      }
    }

    &.hold {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/progress-bar-hold.svg");
        color: nurx-color("primary");
      }
    }

    &.pause {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/pause-checkmark-circle.svg");
      }
    }

    &.processing {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/progress-bar-processing.svg");
      }
    }

    &.resubmitted {
      .step-graphic-icon {
        background-image: url("/assets/images/ios-app/progress-bar-processing.svg");
      }
    }
  }
}

// Graphic -- Line
.vertical-progress-bar {
  .progress-step {
    margin-right: 10px;
    padding-left: 10px;

    // Default Line styling
    .step-graphic-line {
      background: #e7eaf0;
      height: calc(100% + #{$progress-step-space-below});
      left: 50%;
      margin-left: calc(#{$progress-step-graphic-container-line-width} / -2);
      position: absolute;
      top: 0;
      width: $progress-step-graphic-container-line-width;
      z-index: 1;
    }

    // Change color of Line while in-progress
    &.active {
      .step-graphic-line {
        background: grayscale-color("black");
        background: linear-gradient(to bottom, grayscale-color("black") 42%, grayscale-color("light-gray") 68%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000", endColorstr="#dddddd", GradientType=0);
      }
    }

    // Change color of Line if step is complete
    &.complete {
      .step-graphic-line {
        background: grayscale-color("black");
      }
    }

    &.complete-to-issue {
      .step-graphic-line {
        background: pastel-color("peach");
        background: linear-gradient(to bottom, grayscale-color("black") 10%, pastel-color("peach") 70%, pastel-color("peach") 70%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000", endColorstr="#efc39c", GradientType=1);
      }
    }

    // Remove Line of final step
    &:last-child {
      .step-graphic-line {
        display: none;
      }
    }
  }
}

// Vertical Progress bar toggle
.vertical-progress-bar-toggle-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
