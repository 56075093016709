@mixin text-input-component($tag-name) {
  #{$tag-name} {
    @include vertical-spacing;
    border: 0;
    border-bottom: 1px solid;
    border-bottom-color: grayscale-color("light-gray");
    border-radius: 0;
    color: grayscale-color("black");
    display: block;
    outline: 0;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 100%;

    &::placeholder {
      color: grayscale-color("gray");

      &:focus {
        color: grayscale-color("light-gray");
      }
    }
  }

  &.invalid-input {
    #{$tag-name} {
      border-bottom-color: nurx-color("danger");
    }
  }
}
