.__info-panel__dfb80 .panel-info {
  border-color: nurx-color("accent");
  display: flex;
  user-select: none;

  .panel-icon {
    align-items: center;
    background-color: nurx-color("accent");
    color: nurx-color("white");
    display: flex;
    justify-content: center;
    width: $unit-md;

    svg {
      margin: $unit-sm;
    }
  }

  .panel-heading {
    background: nurx-color("accent");
    border-color: nurx-color("accent");
    color: nurx-color("white");
  }

  .panel-body {
    color: nurx-color("accent");
  }

  .panel-footer {
    background: #58bbee;
    border-color: #58bbee;
    color: nurx-color("white");
  }
}
