$subscription-action-panel-margin: 20px;

.component-subscription {
  .component-body {
    font-size: 1.5rem;

    .panel-header-title {
      color: #616670;
      font-size: 1.5rem;
      margin: $subscription-action-panel-margin 0 $unit-xs 0;
    }

    .btn {
      margin-top: $subscription-action-panel-margin;
    }

    .panel-border {
      border-bottom: 1px solid $border-color;
      margin-top: $subscription-action-panel-margin;
      width: 100%;
    }
  }

  .component-body {
    .alert {
      font-size: 1.4rem;

      i {
        color: nurx-color("danger");
        padding-right: $unit-xs;
      }

      h3 {
        font-size: 1.5rem;
      }

      a {
        color: nurx-color("accent");
      }
    }
  }

  .component-header {
    font-size: 1.5rem;
    padding: $unit-reg;
    text-align: center;

    h2 {
      font-size: 1.7rem;

      @media (max-width: $tablet) {
        margin-top: $unit-reg;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }

    .order-graphic {
      img {
        border: 1px solid $border-color;
        border-radius: $border-radius-standard;
        padding: $unit-reg;
        width: 140px;

        @media (min-width: $tablet) {
          width: 100%;
        }
      }
    }

    @media (min-width: $tablet) {
      border-bottom: 1px solid $border-color;
      text-align: left;
    }
  }
}

// Subscriptions List
.sub-list {
  .component-subscription {
    .component-body {
      border-top: 1px solid $border-color;

      .btn {
        margin: 0;

        @media (max-width: $phone) {
          margin-top: $unit-sm;
        }
      }
    }
  }
}
