@mixin orders() {
  .title-heading {
    background-color: grayscale-color("lighter-gray");
    padding-bottom: 15px;
    padding-top: 15px;

    @include media-breakpoint-only(xs) {
      left: -1em;
      position: relative;
      width: 100vw;
    }

    h2 {
      margin: 0;
      padding-left: 20px;
    }
  }

  hr {
    border-color: grayscale-color("light-gray");

    @media (max-width: $tablet) {
      left: -15px;
      position: relative;
      width: 100vw;
    }
  }
}
