.__vaginitis-health-intro__4bccc {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__vaginitis-health-intro__4bccc .intro {
  @include request-intro(pastel-color("blue"));
  color: grayscale-color("black");
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .request-intro-content {
    padding-bottom: 0; // to make hero image flush with the bottom
  }

  ul {
    margin: 0 auto;
    width: 80%;

    li {
      list-style-type: disc;
      margin: 0;
      padding: 0;
    }
  }
}

.__vaginitis-health-intro__4bccc .intro-image {
  margin: 10px auto 0;
  width: 180px;
}

.__vaginitis-health-intro__4bccc .how-it-works {
  padding: 0 0 1rem;
}
