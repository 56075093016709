.__admin__table__table-filter-select__a86be .list-toggle {
  align-items: center;
  border: 1px solid grayscale-color("light-gray");
  border-radius: $unit-reg;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 2px 12px;
}

.__admin__table__table-filter-select__a86be .list-header {
  color: grayscale-color("gray");
  cursor: default;
  padding: 6px $unit-reg;
}

.__admin__table__table-filter-select__a86be .list-option {
  cursor: pointer;
  padding: 6px $unit-reg;
}

.__admin__table__table-filter-select__a86be .list-option:hover {
  background-color: grayscale-color("lighter-gray");
}

.__admin__table__table-filter-select__a86be .options-list-container {
  position: relative; // Absolute positioning of the options dropdown is relative to the nearest positioned ancestor
}

.__admin__table__table-filter-select__a86be .options-list {
  background-color: grayscale-color("white");
  border-radius: $unit-xs;
  box-shadow: shadow("elevation-2");
  list-style: none;
  padding: $unit-sm 0;
  position: absolute;
  top: $vertical-spacing-half;
  white-space: nowrap; // Keep each option on one line. Prevent wrapping when the parent element is narrow
}
