.nurx-brand-orange {
  color: nurx-color("primary");
}

.nurx-accent-blue {
  color: nurx-color("accent");
}

.no-right-padding {
  padding-right: 0;
}

.lab-option-list {
  margin-bottom: 0;
  margin-left: $unit-md;
}

.lab-option-bullets {
  list-style: disc;
}
