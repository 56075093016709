.__resolution-menu__42740 .resolution-text-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .resolution-text {
    h2 {
      font-size: 1.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
