.__styles__user__experiments__55828 h2 {
  margin-bottom: 20px;
}

.__styles__user__experiments__55828 .experiment-title {
  margin-bottom: 3px;
}

.__styles__user__experiments__55828 form {
  font-size: 0.92em;
}
