.__styles__cart__mental-health-interstitial__41daa .interstitial-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  justify-content: space-between;

  h1 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin: 11px 0 26px;
    text-align: center;
  }

  .interstitial-button-container {
    justify-self: flex-end;
    width: 100%;
  }
}

.__styles__cart__mental-health-interstitial__41daa .interstitial-card {
  background-color: pastel-color("blue-40");
  padding: 32px 24px;
  border-radius: 8px;
  flex: none;
  align-self: flex-start;

  h2 {
    line-height: 20px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.25px;
    text-align: center;
  }

  > img {
    margin: 24px auto;
    display: block;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
    margin: 0 auto;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      line-height: 22.4px;
      font-weight: 400;

    }
  }
}

.__styles__cart__mental-health-interstitial__41daa .info-container {
  padding: 16px;
  margin-top: 24px;
  background-color: grayscale-color("lighter-gray");
  border-radius: 8px;

  p {
    font-size: 12px;
    line-height: 23px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;

    em {
      font-weight: bold;
    }
  }

  span {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.3px;
    font-style: italic;
    color: grayscale-color("gray");
    display: block;
  }
}
