.__admin__user__alert-requests-subs__76f08 .alert-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

.__admin__user__alert-requests-subs__76f08 .list-container {
  border: 1px solid grayscale-color("gray-five");
  border-top: 0;
  margin-bottom: $vertical-spacing;
  padding: $unit-reg 0;
  width: 100%;

  h5,
  ul {
    padding: 0 $unit-reg;
  }

  li {
    display: flex;
    justify-content: space-between;

    a {
      color: bright-color("blue");
      text-align: left;

      &::before {
        color: bright-color("blue");
        content: "\2022";
        display: inline-block;
        font-weight: bold;
        width: $unit-reg;
      }

      span {
        text-decoration: underline;

        &.claim-text {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    .gray {
      color: grayscale-color("gray");
    }

    .list-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .header-container {
    align-items: center;
    background: $off-gray;
    border: 0;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px $unit-reg;
    width: 100%;

    h5 {
      padding: 0;
    }
  }
}

.__admin__user__alert-requests-subs__76f08 .request-status {
  text-transform: capitalize;
  margin-left: auto;
  padding-right: 10px;
}
