b,
strong {
  @include text-bold;
}

pre {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: $unit-reg;
}

.text-blue {
  color: bright-color("blue");
}

.text-normal {
  @include text-normal;
}

.text-bold {
  @include text-bold;
}

.text-extra-bold {
  @include text-extra-bold;
}

.text-italic {
  font-style: italic;
}

.text-small {
  font-size: 11px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
