// Styles contained are for login-page, register-page, logout

.title-container {
  margin: 32px 0;
  text-align: center;

  .title {
    height: auto;
    width: 50%;
  }
}

form {
  width: 100%;
}

.auth-container {
  background: rgba(189, 217, 224, 0.2);
  border: 1px solid rgba(189, 217, 224, 0.4);
  border-radius: 3px;
  padding: 30px;

  .input-wrapper {
    input {
      border-bottom-color: rgba(189, 217, 224, 0.8);
      border-bottom-width: 3px;
    }
  }

  .buttons * {
    @include vertical-spacing-half;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Login
.forgot {
  font-size: 10px;
  margin-top: 4px;
}

// Reset Password
.error-text {
  margin-left: 4px;
}
