.__pinned-notes-entry__5bb0a .pinned-entry-note {
  background-color: #fff9cb;
  border: 1px solid #f5efc3;
}

.__pinned-notes-entry__5bb0a .pinned-notes-entry {
  border-color: nurx-color("black");
  flex-grow: 1;
  justify-content: center;

  &.focused {
    &::after {
      background-color: #b8e1fd;
      bottom: 0;
      content: "";
      left: -8px;
      position: absolute;
      right: -8px;
      top: -8px;
      z-index: -1;
    }
  }

  .panel-body {
    flex-direction: column;
    justify-content: center;
  }

  .pinned-notes-entry-header {
    align-content: flex-end;
    display: flex;
    justify-content: space-between;
  }

  .pinned-entry-image {
    height: auto;
    max-height: 50vh;
    max-width: 22vw;
  }

  .pinned-notes-entry-journal {
    word-break: break-word;
  }

  .pinned-notes-entry-footer {
    align-content: flex-start;
    flex-direction: row;
    justify-content: space-between;

    .button-container {
      display: flex;
      justify-content: flex-start;

      button {
        align-items: center;
        display: flex;
        height: 25px;
        justify-content: space-around;
        min-width: 60px;
      }
    }

    .pinned-entry-btn-jump {
      margin-right: 5px;

      svg {
        color: nurx-color("accent");
      }
    }

    .pinned-entry-btn-pin {
      svg {
        color: #f3d11b;
      }
    }
  }
}
