.__question__video-recording-with-immediate-camera-feed__79702 .instruction-text {
  li {
    list-style: inherit;
  }

  ol {
    padding-left: $vertical-spacing;
  }
}

.__question__video-recording-with-immediate-camera-feed__79702 .video-placeholder,
.__question__video-recording-with-immediate-camera-feed__79702 .recording-action,
.__question__video-recording-with-immediate-camera-feed__79702 .video-container,
.__question__video-recording-with-immediate-camera-feed__79702 .video-playback {
  margin-bottom: $vertical-spacing;
}

.__question__video-recording-with-immediate-camera-feed__79702 .video-upload {
  display: none;
}

.__question__video-recording-with-immediate-camera-feed__79702 .video-placeholder {
  background-color: grayscale-color("light-gray");
  padding: 90px 30px;
  text-align: center;
}
