.peer-to-peer-referral-code-container {
  margin: 0 auto;
  max-width: 30em;
  text-align: center;

  .promo-image {
    img {
      height: auto;
      margin: 0 0 1em;
      width: 4.4em;
    }
  }

  .promo-description {
    @include text-bold;
    color: #788494;
    font-size: 1.14em;
    line-height: 1.5em;
    margin: 0 auto;

    @media (max-width: $tablet) {
      max-width: 530px;
    }
  }

  .promo-code {
    @include text-bold;
    color: #5b6b82;
    white-space: nowrap;
  }

  .promo-share-container {
    display: inline-block;
    margin: 1em 0 0;

    @media (max-width: $phone) {
      display: block;
    }

    img {
      margin: 0 0.25em;
      width: 1.8em;
    }
  }
}
