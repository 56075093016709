// Variables
$graphic-size: 40px;
$graphic-gap-size: $unit-reg;
$actionable-indicator-size: $unit-xs;

// Layout
.card-items-container {
  &>a {
    display: block;
  }

  &>.new-card.card-item,
  &>a {
    &:not(:only-child) {
      &:not(:last-child) {
        margin-bottom: $unit-reg;
      }
    }
  }
}

// Atoms
.new-card.card-item {
  padding: $new-card-internal-padding;

  .card-item-text {
    .card-item-title {
      @include text-bold;
      color: grayscale-color("black");
    }

    .card-item-description {
      @include text-bold;
      color: $text-muted;
    }
  }

  .card-item-graphic {
    // Note: background-image needs to be defined in the markup
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: $graphic-size;
    width: $graphic-size;
  }

  .card-item-actionable-indicator {
    color: nurx-color-level("black", 8.5);
    font-size: 0.82em;
  }
}

// IF has graphic
.new-card.card-item {
  &.has-graphic {
    .card-item-text {
      float: right;
      width: calc(100% - (#{$graphic-size} + #{$graphic-gap-size}));
    }

    .card-item-graphic {
      float: left;
    }
  }
}

// IF is actionable
.new-card.card-item {
  &.is-actionable {
    padding-right: calc(#{$new-card-internal-padding} + #{$unit-reg});
    position: relative;

    .card-item-actionable-indicator {
      cursor: pointer;
      position: absolute;
      right: $new-card-internal-padding;
      top: calc(50% - (1em / 2));
    }
  }
}
