.__admin__patient-encounters__520e5 {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 100%;
}

.__admin__patient-encounters__520e5 table {
  margin-bottom: $vertical-spacing;
  width: 100%;

  &.patient-encounters {
    border-bottom: 1px solid grayscale-color("light-gray");
    width: 100%;

    .patient-encounter-row {
      align-items: center;
      background-color: $light-blue-gray;
      border-top: 1px solid grayscale-color("light-gray");
      height: 40px;
      user-select: none;
      width: 100%;

      td {
        height: inherit;

        &:first-of-type {
          padding: 0 0 0 $unit-sm;
        }
      }

      .patient-encounter-row-icon {
        align-items: center;
        display: flex;
        height: inherit;
        justify-content: right;
        margin-right: $unit-sm;
      }
    }

    .patient-encounter-detail {
      padding-top: $unit-sm;
    }
  }
}

.__admin__patient-encounters__520e5 thead {
  tr {
    border-bottom: 2px solid grayscale-color("light-gray");

    th {
      padding-bottom: $unit-xs;
    }
  }
}

.__admin__patient-encounters__520e5 th:not(.is-sortable) {
  cursor: auto;
}
