$grayscale-colors: (
  "black"             : #000,
  "dark-gray"         : #454c5e,
  "gray"              : #666,
  "medium-gray-hover" : #616670,
  "medium-gray"       : #999,
  "light-gray"        : #ddd,
  "gray-five"         : #e9e9e9,
  "lighter-gray"      : #f8f8f8,
  "lightest-gray"     : #fafafa,
  "white"             : #fff,
);

@function grayscale-color($key) {
  @return get-color($grayscale-colors, $key, "grayscale-color");
}
