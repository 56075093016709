.__subtext__a8ab1 .subtext {
  @include text-muted;
  @include vertical-spacing;
  display: block;
  font-size: 12px;
  line-height: 20px;

  .cookie-link {
    color: nurx-color-level("accent", -1);
    cursor: pointer;
  }
}
