.__screen-heading__c1f5e {
  @include vertical-spacing;
  @include text-bold;
  color: grayscale-color("black");
  font-size: 46px;
  line-height: 48px;
}

.__screen-heading__c1f5e.responsive {
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 30px;
  }
}
