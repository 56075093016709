input.invalid-input {
  border: 1px solid nurx-color("danger");
}

.nurx_body.checkout {
  min-height: 100%;
}

.nurx_body.questions {
  overflow: hidden;
}
