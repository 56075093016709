$row-padding: 20px;

.__styles__user__account__0e443 .contextual-item {
  @include uniform-padding($row-padding);
  border-bottom: 1px solid grayscale-color("lighter-gray");
  color: grayscale-color("black");
  display: block;
}

.__styles__user__account__0e443 .progression-icon {
  color: grayscale-color("light-gray");
}

.__styles__user__account__0e443 .reminder-icon {
  color: #d7230c;
  margin-right: 6px;
}
