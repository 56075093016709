.__admin-permissions__02b24 .admin-permissions {
  margin-top: 20px;

  .table-container {
    display: block;
    height: calc(100vh - 280px); // set the height of the table body based on the viewport size and make it responsive
    margin: 0 0 20px;
    overflow: scroll;
  }

  .permissions-table {
    border-collapse: collapse;
    display: grid;
    flex: 1;
    max-height: 100%; // needed for sticky, and must be max-height
    overflow: scroll;
    position: relative;
    width: 100%;

    thead,
    tbody,
    tr {
      display: contents;
    }

    tr:nth-child(odd) > td {
      background-color: nurx-color("white");
    }

    tr:nth-child(even) > td {
      background-color: #eaeaf1;
    }

    th {
      align-self: auto;
      background-color: nurx-color("white");
      border-bottom: 1px solid #eaeaf1;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: normal;
      grid-row-end: 1; // This forces them all into one row
      grid-row-start: 1;
      justify-content: center;
      padding: 5px 15px;
      position: sticky;
      text-align: center;
      text-transform: capitalize;
      top: 0;
      z-index: 2;

      &:first-child {
        border-right: 1px solid grayscale-color("light-gray");
        left: 0;
        z-index: 3;
      }
    }

    td {
      display: flex;
      padding: 5px 15px;
      text-align: center;

      // Fixed position for the first column for easy reference
      &:first-child {
        left: 0;
        position: sticky;
        text-align: left;
        width: 320px;
        z-index: 1;
      }
    }

    .divider {
      border-left: 1px solid #e4e4e4;
    }

    tbody {
      overflow: auto;
      width: max-content;
    }

    tbody td:first-child {
      justify-content: left;
      position: sticky;
      z-index: 1;
    }

    tbody td {
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      vertical-align: middle;
      white-space: normal;
    }

    tbody td input {
      align-self: center;
      width: 20px;
    }
  }

  .controls-container {
    display: flex;
    gap: 20px;
    margin-bottom: 0;
    margin-top: 20px;
    width: 100%;
  }

  .search-user-container,
  .add-user-permissions {
    flex-grow: 1;
    max-width: 40%;

    label {
      margin-bottom: 5px;
      margin-left: 3px;
    }

    input {
      margin-bottom: 5px;
    }
  }

  .add-user-permissions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .add-user-permissions-controls {
      display: flex;

      input {
        height: 30px;
      }

      button {
        height: 30px;
        line-height: 1;
        margin-left: 10px;
        padding: 5px 15px;
        width: 150px;
      }
    }
  }

  .td-invisible {
    display: none;
  }

  .permissions-save {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.top {
      flex-grow: 1;
      justify-self: flex-end;
      margin-top: 21px;
    }

    .actions {
      display: flex;
      flex-direction: column;
    }

    button {
      height: 30px;
      margin-bottom: 5px;
      width: 150px;
    }
  }

  .loader-invisible {
    text-align: center;
    visibility: hidden;
  }
}

// Make responsive at smaller sizes
@media (min-width: 1px) and (max-width: 700px) {
  .__admin-permissions__02b24 .controls-container {
    flex-direction: column;

    .search-user-container,
    .add-user-permissions {
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
    }

    .permissions-save.top {
      margin-top: 0;
    }
  }

  .__admin-permissions__02b24 .table-container {
    height: calc(100vh - 440px) !important; // Fixes bug where browsers were not using this value
  }
}

@media (min-width: 1px) and (max-width: 450px) {
  .__admin-permissions__02b24 .permissions-table td:first-child {
    max-width: 200px;
    overflow: hidden;
  }
}
