.__styles__admin__user__2b7b9 .section-actionable {
  background-color: nurx-color("white");
}

.__styles__admin__user__2b7b9 .disabled {
  color: inherit;
  cursor: default;
}

.__styles__admin__user__2b7b9 .debug-panel {
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 10px;
  margin-top: 10px;
  font-size: 1rem;
  color: #6c757d;
  border-radius: 5px;
}

.__styles__admin__user__2b7b9 .debug-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.1rem;
}

.__styles__admin__user__2b7b9 .debug-table td {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}

.__styles__admin__user__2b7b9 .debug-table td:first-child {
  font-weight: bold;
  color: #343a40;
}

.__styles__admin__user__2b7b9 .btn-link.debug-toggle {
  font-size: 1.1rem;
  color: #6c7c8e;
  text-decoration: underline;
  transition: none;
}
