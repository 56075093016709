.__admin__patient-service-lockouts__e9832 table {
  width: 100%;
}

.__admin__patient-service-lockouts__e9832 td,
.__admin__patient-service-lockouts__e9832 th {
  padding: 8px;
}

.__admin__patient-service-lockouts__e9832 thead {
  border-bottom: 2px solid grayscale-color("light-gray");

  th {
    color: grayscale-color("gray");
    font-weight: 500;
  }
}

.__admin__patient-service-lockouts__e9832 tr:not(:last-child) {
  border-bottom: 1px solid grayscale-color("light-gray");
}

.__admin__patient-service-lockouts__e9832 tr.inactive {
  color: grayscale-color("light-gray");
}
