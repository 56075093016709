@mixin button {
  $hover-color: lighten(grayscale-color("black"), 50%);
  $light-color: grayscale-color("light-gray");

  @extend .border-box;
  @include uniform-padding(15px);
  background-color: grayscale-color("black");
  border: 2px solid grayscale-color("black");
  border-radius: 3px;
  color: grayscale-color("white");
  font-size: 14px;
  width: 100%;
  @include active-states("&") {
    background-color: $hover-color;
    border-color: $hover-color;
    color: grayscale-color("white");
    outline: none !important;
  }

  &.is-white {
    background-color: grayscale-color("white");
    border-color: grayscale-color("black");
    color: grayscale-color("black");

    &:focus,
    &:hover {
      border-color: $hover-color;
      color: $hover-color;
    }
  }

  &.danger {
    background: bright-color("red");
    border: bright-color("red");
  }

  &.thin-border {
    border-width: 1px;
  }

  &:disabled {
    background-color: grayscale-color("light-gray");
    border-color: grayscale-color("light-gray");
    opacity: 1;
  }

  &.survey-question-button {
    border-color: $light-color;
    color: $light-color;
    font-weight: $font-weight-normal;

    &.unselected {
      border-color: $light-color;
      color: $light-color;
    }

    &.selected {
      border-color: grayscale-color("black");
      color: grayscale-color("black");
    }

    &.no-selection,
    .none-selected {
      border-color: $light-color;
      color: grayscale-color("black");
    }
  }
}
