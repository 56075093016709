.__admin__user__credit__creator__d57f4 .credit-overview {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1.25fr 1.25fr 2fr;
  justify-content: center;
  margin: $unit-md 0;
  text-align: center;

  .credit-figure {
    min-height: 100px;
    padding: $unit-sm;

    &.divider {
      border-right: 1px solid grayscale-color("gray-five");
    }

    label {
      border-top: 3px solid grayscale-color("gray-five");
      margin-top: $unit-sm;
      padding: $unit-sm $unit-sm 0 $unit-sm;
    }
  }

  h1 {
    margin: 0 0 $unit-sm 0;
  }
}

@media (max-width: 1050px) {
  .__admin__user__credit__creator__d57f4 .credit-overview {
    grid-template-columns: 1fr;
    margin: 0 0 $unit-sm;

    .credit-figure {
      margin-bottom: $unit-sm;

      &.divider {
        border-right: 0;
      }
    }
  }
}
