.__billing-step-card__04e13 .credit-card-display {
  background-image: linear-gradient(149deg, grayscale-color("lighter-gray"), grayscale-color("light-gray"));
  border-radius: 10px;
  padding-top: 60%;
  position: relative;
  width: 100%;

  .content-container {
    bottom: 0;
    left: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .card-number-row {
    font-size: 20px;
    height: 25%;
  }

  .details-row {
    height: 25%;
  }

  .images-row {
    height: 50%;

    .brand {
      width: 50px;

      .credit-card-brand-image {
        margin-top: -10px; // HACK (kenny 2019-08-16): We should be using an image without built in padding
        width: 100%;
      }
    }
  }
}
