.__home__self-service-links__814ec {
  margin: 0;
  padding: 40px 0 85px;
  width: 100%;

  .self-service-links-container {
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }

  .self-service-link-button {
    background-color: grayscale-color("lighter-gray");
    border: 1px solid grayscale-color("gray-five");
    border-radius: 3px;
    color: grayscale-color("dark-gray");
    height: 125px;

    .self-service-link-icon {
      display: block;
      margin: 0 auto 8px;
    }
  }
}
