.__admin__table__table-pagination__4f6b3 .pagination-toolbar {
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    padding: 0;
  }

  button:hover {
    background-color: grayscale-color("lighter-gray");
  }

  button:disabled {
    color: grayscale-color("light-gray");
  }

  button.selected {
    background-color: grayscale-color("lighter-gray");
  }

  .pagination {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .page-number {
      height: 24px;
      width: 24px;
    }
  }
}
