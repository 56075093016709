.__message-builder-preview__27e34 {
  overflow-y: auto;
  padding: 10px;
}

.__message-builder-preview__27e34 .message-builder-request-id-container {
  margin: 10px;
}

.__message-builder-preview__27e34 .message-builder-block {
  background: nurx-color("white");
  border-color: #f3f3f7;
  border-style: solid;
  border-width: thin;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  .message-builder-text-area {
    // NOTE(michael.gregory 2021-02-08): The following is duplicated from .form-control since
    // <ControlledTextarea> renders a root DIV and cannot pass class to child <textarea>. Ideally
    // <ControlledTextarea> would render a <textarea> root elem.
    textarea {
      background-clip: padding-box;
      border: 1px solid transparent;
      border-radius: 0.3em;
      box-shadow: none;
      color: inherit;
      margin-bottom: $unit-sm;
      overflow: hidden;
      padding: 0.6em 0.7em 0.45em;

      &:focus {
        border-color: nurx-color("accent");
      }
    }
  }

  .message-builder-static-text {
    border: 1px solid transparent;
    border-radius: 5px;
    margin-bottom: 6px;
    padding: 0.6em 0.7em 0.45em;
  }

  .message-builder-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.6em 0.7em 0.45em;
    width: 100%;

    .message-builder-stop-edit {
      display: block;
      flex-grow: 1;
      height: inherit;

      a {
        cursor: move;
        display: block;
        height: 100%;
        text-decoration: none;
        width: 100%;
      }
    }
  }
}

.__message-builder-preview__27e34 .message-builder-add-block {
  display: block;
  height: 10px;
  width: 100%;

  .message-builder-add-button-container {
    display: block;
    position: absolute;
    right: 50%;
    top: -50%;
    visibility: hidden;
  }
}

.__message-builder-preview__27e34 .message-builder-add-block:hover .message-builder-add-hover {
  display: block;
  height: 10px;
  position: relative;
  width: 100%;

  .message-builder-add-button-container {
    display: block;
    position: absolute;
    right: 50%;
    top: -85%;
    visibility: visible;
    z-index: 1;
  }

  .dashed-line {
    border-bottom: 2px dashed nurx-color("accent");
    height: 60%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}
