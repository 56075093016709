// Copied over from account/nav-row/styles:

$row-padding: 20px;

.__styles__account__receipts__list__7ce07 {
  border-bottom: 1px solid grayscale-color("lighter-gray");
}

.__styles__account__receipts__list__7ce07 a {
  .account-item {
    @include uniform-padding($row-padding);
    color: grayscale-color("black");
    display: block;
  }
}

.__styles__account__receipts__list__7ce07 .progression-icon {
  color: grayscale-color("light-gray");
}
