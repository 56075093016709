.__styles__service__sti__9c9ac .title-img {
  display: block;
  height: 176px;
  margin: 80px auto 0;
}

.__styles__service__sti__9c9ac .title-subtext {
  color: grayscale-color("gray");
  line-height: $vertical-spacing;
  margin-top: $vertical-spacing;
}
