body,
html {
  @include graphik-font;
  @include text-normal;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  touch-action: manipulation;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  outline: none;
}

a {
  color: nurx-color("accent");
  text-decoration: none;

  &.neutral {
    color: nurx-color-level("black", 7);
  }

  &.danger {
    color: nurx-color("danger");
  }

  &:hover {
    cursor: pointer;
    text-decoration: none !important;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.nurx-application {
  height: 100vh;

  .nurx-body {
    min-height: calc(100vh - #{$site-header-height});
  }
}

div.inertial-scroll-body {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

div.non-elastic-wrapper {
  width: 100vw;
}

li {
  list-style: none;
  padding: 0;
}

.main-container-scroll {
  height: calc(100% - 50px);
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; // has to be scroll, not auto
  padding-bottom: $unit-md;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

img[src="Error.src"] {
  display: none;
}

.ios-page-content-heading {
  font-size: 20px;
  line-height: 1.4em;
}

body {
  &>div.application-loading-content {
    background: grayscale-color("white");
    display: block;
    font-size: 18px;
    height: 100vh;
    left: 0;
    line-height: 1em;
    padding-top: 40vh;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 5000; // TODO: Refactor
  }

  &>div.application-loaded-content {
    display: none;
  }

  &.application-loaded {
    &>div.application-loading-content {
      display: none;
    }

    &>div.application-loaded-content {
      display: block;
    }
  }

  &.mask-pii {
    [data-pii] {
      filter: blur(5px);
    }
  }

  // SC-353114: Disable click actions in the admin platform
  // for migrated patients. Prevents user data from being
  // accidentally modified after migration.
  //
  // Note: Keep the selectors as general as possible
  // on the elements that are included, to allow for
  // specific exclusions to be applied with higher specificity.
  &.disable-click-actions {
    .user-profile-summary, .admin-messenger {
      button,
      input,
      textarea,
      select {
        pointer-events: none;
        opacity: 0.5;
      }

      // Disable non standard input elements
      a[role="button"] {
        pointer-events: none;
        opacity: 0.5;
      }

      // Only disable the pointer events without
      // changing the opacity on labels
      label {
        pointer-events: none;
      }

      // Excluded elements. Must have higher specifity
      // than the general selectors above.
      button[data-disable-click-excluded],
      input[data-disable-click-excluded],
      textarea[data-disable-click-excluded],
      label[data-disable-click-excluded],
      select[data-disable-click-excluded],
      a[role="button"][data-disable-click-excluded] {
        pointer-events: auto;
        opacity: initial;
      }
    }
  }

  .osano-cm-widget {
    display: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .osano-cm-disclosure__toggle {
    display: none;
  }
}

.padding-right-only {
  @include uniform-padding(0);
  padding-right: 15px;
}

.gray-label {
  @include text-bold;
  background: #f8f8fd;
  border: 1px solid #f1f1f7;
  border-radius: 50px;
  color: #494d55;
  display: inline-block;
  font-size: 80%;
  margin-right: 3px;
  margin-top: 6px;
  padding: 5px 12px 2px;
  white-space: nowrap;
}
