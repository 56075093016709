.__question__multiple-answer-input__d3cfa .dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
}

.__question__multiple-answer-input__d3cfa .dropdown-content {
  background-color: grayscale-color("white");
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  display: block;
  padding: 12px 16px;
  position: absolute;
  z-index: 1;
}

.__question__multiple-answer-input__d3cfa .suggestion {
  padding: 5px;
}

.__question__multiple-answer-input__d3cfa .selected {
  background-color: grayscale-color("lighter-gray");
  min-height: $vertical-spacing-double;
  width: 100%;

  li {
    height: $vertical-spacing-double;
    margin-top: $vertical-spacing-half;
  }
}

.__question__multiple-answer-input__d3cfa .bubble {
  align-items: center;
  background-color: pastel-color("blue");
  border-radius: 20px;
  display: inline-flex;
  height: $vertical-spacing-double;
  justify-content: center;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.__question__multiple-answer-input__d3cfa .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
