.insurance-ocr-section {
  height: 100vh;
  overflow: scroll;
}

.ocr-wrapper {
  position: relative;
}

.ocr-anchor {
  position: relative;
}

.ocr-highlight-box {
  background-color: transparent;
  border-color: nurx-color("warning");
  border-style: solid;
  border-width: 1px;
  color: transparent;
  cursor: pointer;
  position: absolute;

  &:active {
    border-color: nurx-color("danger");
  }
}
