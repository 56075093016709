.__styles__cart__addon-selection__a1e83 .wrapper-addon-selection {
  display: flex;
  flex: 1;
  flex-direction: column;

  .header {
    background-color: pastel-color("blue");
    color: bright-color("black");
    margin: 0 -15px;
    padding: $vertical-spacing;

    &-bg-green {
      background-color: pastel-color("green");
    }

    &-bg-blue {
      background-color: pastel-color("blue");
    }

    &-bg-peach {
      background-color: pastel-color("peach");
    }

    &-bg-pink {
      background-color: pastel-color("pink");
    }

    .intro {
      font-family: "NurxDigital", "Graphik Web", arial, helvetica, sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: var(--font-weight);
      letter-spacing: 2px;
      line-height: 58px;
      margin-bottom: $vertical-spacing;
      text-align: left;
      user-select: none;
      width: 100%;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: $vertical-spacing;
    }
  }

  .addon-item-card {
    margin-bottom: 16px;
  }

  .sub-header {
    font-size: 18px;
    font-weight: 500;
    margin: $vertical-spacing 0;
  }

  .items-container {
    display: flex;
    flex-direction: column;
  }

  .footer {
    margin-top: auto;
    color: grayscale-color("gray");
  }
}
