.__styles__account__profile__2e002 .profile-pic-upload {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-height: 100px;
    max-width: 100px;
  }
}
