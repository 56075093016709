$compose-container-height: 75px;

// TODO @MRurka – Below this line: Template hacks
.app.new.messenger-template.ios-override-tmp {
  margin-left: -$unit-reg;
  margin-right: -$unit-reg;
  margin-top: -$unit-reg;

  h1.messenger-header {
    display: none;
  }

  .composer-container {
    bottom: $app-navbar-height;
    z-index: 1;
  }

  .user-messenger-scroll-body {
    height: calc(100vh - #{$app-header-height} - #{$compose-container-height} - #{$app-navbar-height});
    overflow: auto;
    padding-bottom: $vertical-spacing;
  }

  .nav-is-hidden.user-messenger-scroll-body {
    height: calc(100vh - #{$compose-container-height});
  }

  .translateToBottom {
    bottom: 0;
  }
}

// Added this below to properly style the messenger on the iphone.
// calc() errors out on other iphones because it doesn't register #{$iphone-bottom-spacing}
// Therefore, I added this styling separately so that it'll register this on iphoneX but register
// the default on lines 11-20 for other iphones
// -Eunice
.iphone-padding-top {
  margin-top: $iphonex-top-spacing;

  .app.new.messenger-template.ios-override-tmp {
    .composer-container {
      bottom: calc(#{$app-navbar-height} + #{$iphone-bottom-spacing});
    }

    .user-messenger-scroll-body {
      height: calc(100vh - #{$iphonex-top-spacing} - #{$app-header-height} - #{$iphone-bottom-spacing} - #{$compose-container-height} - #{$app-navbar-height});
    }

    .nav-is-hidden.user-messenger-scroll-body {
      height: calc(100vh - #{$iphonex-top-spacing} - #{$app-header-height} - #{$compose-container-height});
    }

    .translateToBottom {
      bottom: 0;
    }
  }
}
