.__question__instructions-question__0c125 .video-wrapper {
  margin-bottom: $vertical-spacing;

  .embed-responsive {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      border: 0;
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    // Modifier class for 16:9 aspect ratio
    .embed-responsive-16by9 {
      padding-bottom: 56.25%;
    }
  }
}

.__question__instructions-question__0c125 .instruction-text {
  li {
    list-style: inherit;
  }

  ol {
    padding-left: $vertical-spacing;
  }
}
