.__styles__cart__billing-information__43035 .collapsible-note {
  background-color: pastel-color("peach");
  padding: 20px 20px 0;
}

.__styles__cart__billing-information__43035 .autocomplete-container {
  position: relative;
}

.__styles__cart__billing-information__43035 .autocomplete-list {
  position: absolute;
  top: -$vertical-spacing;
  width: 100%;
}

.__styles__cart__billing-information__43035 .form-input-label {
  margin-bottom: $vertical-spacing-half;
}

.__styles__cart__billing-information__43035 .form-submit-button {
  margin-top: 40px;
}
