.__styles__admin__service-line-management__0a3b1 .service-line-management-topics-root {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr 4fr;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 5px;
  width: 90%;
}

.__styles__admin__service-line-management__0a3b1 .navigation-panel {
  border-right: 1px solid grayscale-color("black");
}

.__styles__admin__service-line-management__0a3b1 .service-config-main {
  margin-top: 2rem;
  text-align: center;
}

.__styles__admin__service-line-management__0a3b1 .service-config-main-error-msg {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 50px auto;

  p {
    margin: 0;
  }
}
