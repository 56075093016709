.__rosacea-intro__bf1a3 {
  @include request-intro-numbered-list(grayscale-color("black"));
}

.__rosacea-intro__bf1a3 .intro {
  @include request-intro(pastel-color("blue"), 0);
}

.__rosacea-intro__bf1a3 .intro-image {
  // The mock has the image off-center to align the medication, not the whole image, to vertical page center:
  // https://www.figma.com/file/ZlCskiMHj85Q9w8JEmq9EX/Rosacea?node-id=128%3A109
  height: auto;
  left: -6%;
  margin: 0 auto;
  max-width: 210px;
  position: relative;
}

.__rosacea-intro__bf1a3 li {
  padding: 2rem 0;
}
