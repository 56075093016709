.__bill-to-selection__04fce .panel-default {
  padding-bottom: $vertical-spacing;
  padding-top: $vertical-spacing;

  .panel-header-title {
    color: inherit;
  }

  .billing-preference {
    float: right;
  }

  .insurance-info-outer-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .insurance-info-container {
      display: inherit;

      .insurance-icon {
        background-color: nurx-color("white");
        background-image: url("/assets/images/insurance-card.svg");
        background-size: cover;
        border-radius: 3px;
        height: 33px;
        margin-right: $unit-reg;
        min-width: 48px;
        position: relative;
      }

      >h2 {
        font-size: 1.5rem;
      }
    }

    // credit card styling
    .selectable-card {
      .image {
        background-color: nurx-color("white");
        border-radius: 3px;
      }
    }
  }

  .copay-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.__bill-to-selection__04fce .edit-btn {
  padding-right: 0;
}

.__bill-to-selection__04fce .highlight {
  background-color: #f9e7d7;
}
