.__promo-code-input__b9dc2 .promo-code-input {
  align-items: center;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 3fr 1fr;

  .button {
    border: 1px solid grayscale-color("light-gray");
    border-radius: 3px;
    margin: 0 0 5px 0;
    max-height: 30px;
    padding: 3px 5px;
    text-align: center;
  }
}
