.__admin-user-nav__ba315 .side-menu {
  background-color: nurx-color("white");

  .nav {
    margin: 0 auto;

    li {
      align-items: center;
      border-bottom: 1px solid #f3f3f7;
      color: grayscale-color("gray");
      cursor: pointer;
      display: flex;
      padding: $unit-sm 10px;
      user-select: none;

      &:first-child {
        border-top: 1px solid #f3f3f7;
      }

      &:hover {
        color: grayscale-color("medium-gray-hover");
      }

      &.active {
        @include text-bold;
        color: nurx-color("accent");

        svg {
          color: nurx-color("accent");
        }

        >.label {
          background-color: nurx-color("accent");
        }
      }

      .icon-spacer {
        margin-right: 12px;
        width: 26px;

        svg {
          align-items: center;
          color: grayscale-color("gray");
          display: flex;
        }
      }

      .label {
        background-color: grayscale-color("gray");
        padding: 0.1em 0.3em 0.22em;
      }
    }
  }
}

// Phones
@media (max-width: 767px) {
  .__admin-user-nav__ba315 nav.side-menu {
    width: 100%;

    ul.nav {
      background: nurx-color("white");
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      text-align: left;
      width: 100%;

      li {
        display: flex;
        font-size: 1.2rem;
        margin: 0;
        padding-bottom: 11px;
        padding-left: 46px;
        padding-top: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid #ebebf1;
        }
      }
    }
  }
}

// Tablet and Desktop
@media (min-width: 768px) {
  .__admin-user-nav__ba315 .side-menu {
    .nav {
      display: block;

      li {
        @include text-normal;
        font-size: 12px;

        &:hover {
          background: #f9f9fb;
        }

        &.active {
          background: #f9f9fb;

          svg {
            color: nurx-color("accent");
          }
        }
      }
    }
  }
}
