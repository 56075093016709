.__checkout-accordion__d363e .accordion-item {
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 7px 0;
  text-align: left;
  width: 100%;
}

.__checkout-accordion__d363e .accordion-header {
  display: flex;
  font-weight: $font-weight-bold;
  justify-content: space-between;
  width: 100%;
}

.__checkout-accordion__d363e .accordion-item::before {
  color: grayscale-color("black");
  content: "\276F";
  display: inline-block;
  margin-right: $unit-sm;
  transform: rotate(90deg);
  transition: all 0.4s ease-out;
}

.__checkout-accordion__d363e .open::before {
  transform: rotate(270deg);
}

.__checkout-accordion__d363e .accordion-content {
  max-height: 0;
  overflow: hidden;
  padding-left: $unit-reg;
  transition: all 0.3s ease-in-out;
}

.__checkout-accordion__d363e .show-content {
  max-height: 100vh;
  overflow: visible;
}
