.__stripe-credit-card__f9691 .stripe-credit-card {
  width: 100%;

  .card-element {
    width: 100%;
  }

  .card-controls {
    width: 100%;

    .save,
    .cancel {
      border: 2px solid transparent;
      color: nurx-color("white");
      height: 40px;
      min-width: 80px;
    }

    .save {
      background-color: nurx-color("success");
    }

    .cancel {
      background-color: nurx-color("danger");
    }

    .save:hover,
    .cancel:hover {
      border: 2px solid nurx-color("black");
    }

    .save:hover {
      color: nurx-color("black");
    }

    .cancel:hover {
      color: nurx-color("white");
    }
  }
}
