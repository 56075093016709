.__styles__account__payments__list__528bf .card-item {
  border-bottom: 1px solid grayscale-color("light-gray");
  padding-left: $vertical-spacing-half;
  padding-right: $vertical-spacing-half;
  padding-top: $vertical-spacing;
}

.__styles__account__payments__list__528bf .card-item:first-of-type {
  border-top: 1px solid grayscale-color("light-gray");
}

.__styles__account__payments__list__528bf .in-use {
  color: bright-color("blue");
  line-height: 2;
}

.__styles__account__payments__list__528bf .arrow-right {
  height: auto;
  transform: rotate(180deg);
  width: 6px;
}
