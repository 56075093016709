.__styles__nurx-whats-next__c104d .nurx-whats-next-body {
  display: flex;
  align-content: center;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 30px;
  padding-top: 24px;
}

.__styles__nurx-whats-next__c104d .nurx-whats-next-heading-content {
  padding: 26px 24px;
  text-align: center;
}

.__styles__nurx-whats-next__c104d .nurx-whats-next-heading {
  font-size: 18px;
  padding-bottom: 10px;
}

.__styles__nurx-whats-next__c104d .info-card {
  height: auto;
  border-radius: 20px;
  margin-bottom: 40px;
  width: 100%;
  flex-grow: 0;
  border: 2px solid grayscale-color("black");
}

.__styles__nurx-whats-next__c104d .info-card-title {
  font: 14px;
  background-color: pastel-color("blue-60");
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: 1px solid grayscale-color("black");
  padding: 26px 24px;
  text-align: center;
}

.__styles__nurx-whats-next__c104d .info-card-body {
  padding: 26px 24px;
  text-align: left;

  .nwn-body {
    border-bottom: 1px solid bright-color("grey");
    display: flex;
    text-align: left;
    padding: 5px;
  }

  .nwn-body:last-child {
    border-bottom: 0;
  }

}

.__styles__nurx-whats-next__c104d .info-card-body-title {
  font: 14px medium grayscale-color("black");
}

.__styles__nurx-whats-next__c104d .info-card-body-text {
  font: 12px grayscale-color("black");

  .disclaimer {
    color: grayscale-color("gray");
  }
}

.__styles__nurx-whats-next__c104d .nurx-whats-next-icon {
  margin-bottom: 20px;
  padding: 10px;
  width: 60px;
  height: 60px;
}

.__styles__nurx-whats-next__c104d .nwn-text-container {
  padding: 10px;
}
