.__checkout-cart-summary-line__c5229 .tooltip-note {
  position: relative;

  .content {
    @include vertical-spacing-half;
    background-color: pastel-color("blue");
    bottom: 0;
    line-height: 1.67;
    padding: 10px;
    position: absolute;

    &::after {
      background-color: pastel-color("blue");
      bottom: 5px;
      content: "";
      height: 14px;
      left: 10px;
      margin-bottom: -10px;
      position: absolute;
      transform: rotate(45deg);
      width: 14px;
      z-index: -1;
    }
  }
}

.__checkout-cart-summary-line__c5229 .text-blue {
  color: bright-color("blue");
}

.__checkout-cart-summary-line__c5229.summary {
  @include text-bold;
  @include text-big;
  border-top: 2px solid grayscale-color("lighter-gray");
  padding-top: $vertical-spacing;

  .line-values {
    text-align: right;
  }

  &.line-light {
    border-top: 1px solid grayscale-color("light-gray");
  }
}

.__checkout-cart-summary-line__c5229.due-later,
.__checkout-cart-summary-line__c5229.subtotal {
  .line-values {
    text-align: right;
  }
}

/* stylelint-disable -- these classes are being generated by our data model */
.__checkout-cart-summary-line__c5229 .line-credits_remaining,
.__checkout-cart-summary-line__c5229 .line-credits_applied_now,
.__checkout-cart-summary-line__c5229 .line-promotion_credit,
.__checkout-cart-summary-line__c5229 .line-savings {
  color: bright-color("green");
}

.__checkout-cart-summary-line__c5229 .line-separator-line_break {
  display: flex;
  flex-direction: column;
}

/* stylelint-enable */

.__checkout-cart-summary-line__c5229 .line {
  display: flex;
  margin: $vertical-spacing-half 0;
}

.__checkout-cart-summary-line__c5229 .line-label {
  min-width: 100px;
  user-select: none;
  display: flex;
  align-items: center;

  .iconify {
    pointer-events: none;
    margin-left: 5px;
  }
}

.__checkout-cart-summary-line__c5229 .line-bold {
  @include text-bold;
}

.__checkout-cart-summary-line__c5229 .line-values {
  flex-grow: 1;
}

.__checkout-cart-summary-line__c5229 .line-value {
  @include text-bold;
}

.__checkout-cart-summary-line__c5229 .static-note {
  @include vertical-spacing;
  background-color: pastel-color("blue");
  line-height: 1.67;
  padding: 10px;

  &::before {
    background-color: pastel-color("blue");
    content: "";
    height: 14px;
    margin-top: -14px;
    position: absolute;
    transform: rotate(45deg);
    width: 14px;
    z-index: -1;
  }
}

.__checkout-cart-summary-line__c5229.sub-line {
  .line-bold {
    font-weight: $font-weight-normal;
  }

  .line-value {
    font-weight: $font-weight-normal;
  }

  .summary-card-line & {
    .line-value {
      @include text-bold;
    }
  }
}
