.__admin__patient-encounter-display-items__1b7b1 {
  display: flex;
  flex-wrap: wrap;
}

.__admin__patient-encounter-display-items__1b7b1 dt {
  @include text-bold;
  flex-basis: 50%;
  overflow: hidden;
  padding-bottom: $unit-xs / 2;
  padding-left: $unit-reg;
  padding-right: $unit-reg;
  padding-top: $unit-xs / 2;

  .parent-item {
    @include text-extra-bold;
  }
}

.__admin__patient-encounter-display-items__1b7b1 dd {
  flex-basis: 50%;
  padding-bottom: $unit-xs / 2;
  padding-left: $unit-reg;
  padding-right: $unit-reg;
  padding-top: $unit-xs / 2;
}

.__admin__patient-encounter-display-items__1b7b1 .header-item {
  @include text-extra-bold;
  padding-top: 14px;

  // Prevent excessive padding when first item is a header
  &:first-child + .header-item {
    margin-top: 0;
  }

  &:not(:first-child) {
    margin-top: 13px;
  }
}

.__admin__patient-encounter-display-items__1b7b1 .parent-item {
  margin-top: $unit-xs;
  padding-top: $unit-sm;

  &.colored {
    margin-top: 13px;
    padding-top: 14px;
  }

  .label {
    margin-right: $unit-xs;
  }
}

.__admin__patient-encounter-display-items__1b7b1 .last-child {
  margin-bottom: 14px;

  &.colored {
    margin-bottom: 13px;
    padding-bottom: 14px;
  }

  + .header-item {
    padding-top: 0;
  }
}

.__admin__patient-encounter-display-items__1b7b1 .last-child-of-expanded {
  &:not(.last-child) {
    padding-bottom: $unit-sm * 1.5;
  }
}

.__admin__patient-encounter-display-items__1b7b1 .display-item-separator {
  @include text-extra-bold;
  display: inline;
  margin-left: 1px;
  margin-right: 1px;
}
