.__user-order-summary-card-status__5b83d .rejection-body {
  align-items: center;
  background-color: #f9e7d7;
  display: flex;
  padding: 14px 10px;

  .rejection-text {
    @include text-bold;
    margin-right: $unit-reg;

    > a {
      color: $primary-blue;
    }
  }

  .rejection-button-container {
    margin-left: auto;
    min-width: 25%;
  }
}
