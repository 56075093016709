$journal-ticket-related-border: 1px solid rgba(0, 0, 0, 0.1);

.section-communication {
  padding-left: 0;
  padding-right: 0;

  // Main Container
  .section-communication-wrapper {
    background: #f7f7f7;
    border-left: 1px solid #dcdcdc;

    // Messages Feed
    .communication-feed {
      overflow-y: scroll;
    }
  }

  // Compose Box / Tabs
  .communication-tabs {
    border-bottom: 1px solid #f0f0f5;
    display: flex;
    padding: 0 $unit-reg;

    >.tab {
      background: none;
      border: 0;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      box-shadow: none;
      flex-grow: 1;
      font-size: 0.9em;
      margin-right: 4px;

      &.active,
      &:hover,
      &:focus {
        border-bottom: 2px solid nurx-color("accent");
        outline: none;
      }
    }
  }

  // Compose Box / Tab Content
  // These are the tabs at the bottom of the provider message interface
  .communication-compose {
    .communication-tabs {
      // Provide left and right padding for the tabs area and account for the scrollbar on the message container
      padding: 0 $unit-reg;

      // Give each tab space between
      .tab {
        margin: 0 4px;

        &:first-child {
          margin-left: 0;
          padding-left: 4px;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 4px;
        }
      }
    }

    .tab-content {
      display: none;

      &.active {
        display: block;
      }

      .panel-body {
        padding: $unit-sm;

        .compose-textarea {

          // Content Body
          textarea {
            background: nurx-color("white");
            border: 1px solid #dfdfdf;
            border-radius: $unit-xs;
            display: block;
            font-size: 14px;
            margin-bottom: 0;
            max-height: 50vh;
            min-height: 5.2em;
            padding: $unit-sm;
            resize: none;
            width: 100%;
          }
        }

        .compose-checkbox {
          @include text-bold;
          color: #a9afbb;
          font-size: 0.9em;
        }

        button {
          &:not(:only-child) {
            margin: 0 $unit-sm $unit-sm 0;
          }
        }
      }

      // Content Footer
      .panel-footer {
        border: 0;

        button {
          &:not(:only-child) {
            margin-left: $unit-sm;
          }

          &.pull-left {
            margin: 0 $unit-sm 0 0;
          }
        }
      }

      &.form_note {
        .panel-body {
          .compose-textarea {
            textarea {
              background: #fff9cb;
              border-color: #f0e6ab;
            }
          }
        }
      }
    }

    // TODO : @rurka Temporary, until @matt hooks up the if/unless ember stuff
    .tab-content {
      display: none;
    }
  }

  // Messages Feed
  .communication-feed {
    @include scrollbar (0.6rem, #d7d7e2, hidden);
    flex-grow: 1;

    .app-body {
      @include scrollbar (0.6rem, #d7d7e2, hidden);
    }

    .message {
      border-radius: 15px;
      font-size: 0.96em;
      max-width: 100%; // NOTE: Necessary cuz max-width:70% is coming from user messenger.scss (base stylesheet)
      overflow: hidden; // Fix for rounded corners bug
      width: 100%;

      .journal-actions {
        display: flex;
        margin-top: $unit-xs;
        max-height: 120px;
        opacity: 0;
        // transition will actually be quicker, b/c the actual height is less than the max-height defined in hover state
        transition: max-height 0.6s ease;

        button {
          align-items: center;
          display: flex;
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &:hover {
        .journal-actions {
          opacity: 1;
        }
      }

      .message-body {
        line-height: 20px;
        min-height: $unit-md;
        padding: $unit-reg; // 16px all around
      }

      .message-footer {
        color: #454545;
      }

      &.message-by-me {
        background-color: grayscale-color("light-gray");

        .legacy-message-container {
          width: 100%;
        }
      }

      &.message-by-them {
        .legacy-message-container {
          background-color: nurx-color("white");

          &::after {
            background-color: nurx-color("white");
          }
        }
      }
    }

    .now-talking-to {
      $avatar-size: 32px;

      .avatar {
        background-color: grayscale-color("light-gray");
        border-radius: calc(#{$avatar-size} / 2);
        height: $avatar-size;
        margin-left: 0;
        overflow: hidden;
        width: $avatar-size;
      }

      .text {
        float: left;
        font-size: 1.2em;
        line-height: $avatar-size - 1px;
        padding: 0 0 0 12px;
        width: calc(100% - (#{$avatar-size}));
      }
    }

    // Note
    .message-note {
      background-color: #fff9cb;
      border: 1px solid #f5efc3;

      img {
        border-radius: $bubble-radius;
        height: auto;
        margin-bottom: -1px;
        max-height: 700px;
        max-width: 300px;
        width: 100%;
      }

      .message-body {
        border: 0;
      }
    }

    .message-automated .message-body {
      border: 2px dashed nurx-color-level("black", 0.5);
    }

    .has-unread-message {
      bottom: 180px;
    }
  }

  // Highlight Message in the messenger column by #ID
  .legacy-messenger-row {
    &.focused {
      &::after {
        background-color: #b8e1fd;
        bottom: 0;
        content: "";
        left: -8px;
        position: absolute;
        right: -8px;
        top: -8px;
        z-index: -1;
      }
    }
  }


  .communication-feed {

    // ––––––––––––––––––––––––––––––––––––-–––––– //
    // –––––––– Action: Pin Messages/Notes –––––– //
    // ––––––––––––––––––––––––––––––––––––––––– //

    // Fav button base styles
    .pin-msg-btn-container {
      position: absolute;
      right: 0;
      top: 0;
      width: auto;

      .pin-msg-btn {
        color: #f3d11b;
        font-size: 0.8em;
        padding-top: $unit-xs;
      }
    }

    // ––––––––––––––––––––––––––––––––––––-––––––––– //
    // –––––––– Pined Messages/Notes Display ––––––– //
    // –––––––––––––––––––––––––––––––––––––––––––– //

    .pinned-messages-container {
      box-shadow: 0 2px 8px rgba(72, 80, 96, 0.1);
      position: fixed;
      z-index: 1;

      .pinned-message {
        background-color: nurx-color("white");
        border-left: 6px solid #f3d11b;
        font-size: 0.96em;
        line-height: 2em;
        max-height: $unit-md;
        overflow: hidden;
        padding: $unit-xs $unit-reg;
        padding-right: $unit-md;
        position: relative;
        transition: max-height 0.5s ease;

        &:not(:only-child) {
          &:not(:last-child) {
            border-bottom: 1px solid #eee;

          }
        }

        &:hover {
          height: auto;
          max-height: 100vh;

          img {
            border-radius: $bubble-radius;
            display: block;
            height: auto;
            margin-bottom: -1px;
            max-height: 700px;
            max-width: 300px;
            width: 100%;
          }
        }

        .pin-msg-btn-container {
          opacity: 1;
          width: auto;
        }
      }

      img {
        height: auto;
        max-height: $unit-md;
        max-width: $unit-xl;
        width: auto;
      }

      button {
        align-self: flex-start;
        display: flex;
      }
    }

    .message-builder-toggle-position-below {
      top: 76.6px;
    }
  }
}
