.__styles__cart__treatment-info__851d9 .background-fill {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: pastel-color("blue-40");
  z-index: -1;
}

.__styles__cart__treatment-info__851d9 .main-content {
  margin-top: 120px;
  margin-bottom: 100px;
  text-align: left;
  box-sizing: border-box;

  .bold-text {
    font-weight: bold;
  }
}

.__styles__cart__treatment-info__851d9 .nice-work {
  width: calc(100% - 20px);
  margin: 0 auto;
  padding-bottom: 20px;

  .header {
    font-family: "Graphik", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding-bottom: 5px;
    color: pastel-color("dark-blue");
  }

  .body {
    font-family: "Graphik", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.2px;
  }
}

.__styles__cart__treatment-info__851d9 .social-proof-frame, .__styles__cart__treatment-info__851d9 .medication-efficacy-frame {
  background-color: grayscale-color("white");
  border-radius: 8px;
  border: 1px solid pastel-color("blue");
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto 0;

  .social-proof-assets-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    width: 100%;

    .social-proof-stars {
      height: 19.25px;
    }

    .social-proof-tag {
      height: 26px;
    }
  }

  .medication-image-wrapper {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    background-color: pastel-color("blue");
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .tretinoin-medication {
      max-width: 100%;
      height: auto;
    }
  }

  .testimonial-text {
    font-family: "Graphik", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: grayscale-color("black");

    .bold-text {
      font-weight: bold;
    }

    .testimonial-text-header {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  .medication-factoid {
    font-family: "Graphik", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.2px;
    color: grayscale-color("black");

    .bold-text {
      font-weight: bold;
    }
  }

  .attribution {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    padding-bottom: 20px;
  }

  .source-info {
    font-family: "Graphik", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #666;
  }
}
