@import "summary";

.__styles__cart__addons__20df8 [class|=addon]:not(dialog) {
  display: flex;
}

.__styles__cart__addons__20df8 [class|=addon] {
  --gap: 16px;
  --padding: 20px;
  --line-height: 20px;
  --font-weight: 400;
  color: grayscale-color("dark-gray");
  flex-direction: column;
  font-family: "Graphik Web", arial, helvetica, sans-serif;
  font-style: normal;
  gap: var(--gap);
  padding: var(--padding) 0;

  .intro {
    font-family: "NurxDigital", "Graphik Web", arial, helvetica, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: var(--font-weight);
    letter-spacing: 2px;
    line-height: 58px;
    margin-bottom: 0;
    text-align: left;
    user-select: none;
    width: 100%;
  }


  h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
  }

  p {
    font-size: 14px;
    font-weight: var(--font-weight);
    line-height: var(--line-height);
  }

  h3 {
    color: grayscale-color("dark-gray");
    font-size: 14px;
    line-height: var(--line-height);
  }
}

.__styles__cart__addons__20df8 .addon {
  &-header {
    background-color: pastel-color("pink-40");
    padding: var(--padding);
  }

  &-product {
    img {
      background-color: pastel-color("green");
      width: 100%;
    }
  }

  &-confirm {
    padding-top: 0;

    label {
      color: grayscale-color("dark-gray");
      display: flex;
      font-size: 14px;
      gap: var(--gap);
      line-height: var(--line-height);
    }
  }

  &-insurance {
    font-size: 12px;
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    padding-top: 0;

    p {
      font-size: 12px;
    }
  }
}
