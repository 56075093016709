.__user-order-summary-card-item-v1__ffd6c {
  background-color: grayscale-color("lighter-gray");
}

.__user-order-summary-card-item-v1__ffd6c .order-graphic {
  // TODO (bree 2019-03-29): This should get merged with the existing
  // order-graphic details (minus `important`) when the other uses of it are
  // brought up to rebrand.
  background-color: grayscale-color("white");
  border: 0 !important;
  border-radius: 0 !important;
  padding: 10px;
}

.__user-order-summary-card-item-v1__ffd6c.component-header {
  border: 0;
  padding: 10px;
}

.__user-order-summary-card-item-v1__ffd6c .payment {
  float: right;
}

.__user-order-summary-card-item-v1__ffd6c .drug-name {
  float: left;
}

.__user-order-summary-card-item-v1__ffd6c .order-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
