.__styles__pages__app-home__82524 .app-home {
  .app-circle-avatar {
    background-color: nurx-color("white");
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .ios-home-request-btn {
    color: #fc6855;
  }

  .user-section {
    display: inline-block;

    .user-photo-image {
      @extend .app-circle-avatar;
      height: 35px;
      width: 35px;
    }

    .user-name {
      color: nurx-color("white");
      display: inline-block;
      font-size: $unit-reg;
      line-height: $unit-md;
      padding-left: $unit-xs;
      vertical-align: middle;
    }
  }

  // Unread Journal Styles

  // New Message Timestamp
  .new-message-timestamp {
    color: grayscale-color("medium-gray");
    font-size: 12px;
    margin: 0 auto 8px;
    text-align: center;
  }

  // Message Preview Container
  .new-message-preview {
    background-color: grayscale-color("lighter-gray");
    border: 1px solid grayscale-color("gray-five");
    border-radius: $unit-xs;
    color: nurx-color("black");
    display: flex;
    padding: $unit-sm;

    .module-content-inner {
      text-align: left;
      width: 90%;

      // Message Sender Container
      .message-sender-info {
        align-items: center;
        display: flex;
        margin-bottom: $unit-sm;

        .sender-avatar {
          @extend .app-circle-avatar;
          height: $unit-md;
          width: $unit-md;

          &.no-picture {
            background-image: url("/assets/images/admin-dashboard/avatar-no-profile-picture.svg");
            background-position-x: 50%;
            background-position-y: 50%;
            background-size: 125%;
            border: 1px solid grayscale-color("medium-gray");
          }
        }

        .sender-name {
          display: inline-block;
          padding-left: $unit-xs;
          vertical-align: middle;
        }
      }

      // Message Body Container
      .message-preview {
        color: grayscale-color("dark-gray");
        display: flex;
      }
    }

    // Chevron Indicator
    .new-message-chevron {
      align-items: center;
      display: flex;
      width: 10%;
    }
  }
}
