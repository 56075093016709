.__styles__photo-exam__completed__7729a .page-content {
  $gutter-spacing: 15px;
  background-color: pastel-color("green-80");
  flex-grow: 1;
  margin: 0 (-$gutter-spacing) $vertical-spacing; // negative margins to allow background to cover gutters
  padding: 0 $gutter-spacing; // compensate for negative left and right margin to retain horizontal alignment

  div.text-left {
    margin: 24px;
  }

  ul.next-steps {
    margin: 0 0 20px 20px;

    li {
      list-style: disc;
      padding: 0 0 10px 10px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 10px;
      }
    }
  }

  .title-img {
    padding: $vertical-spacing-double;
  }
}
