$changing-subscription-item-padding: $grid-gutter-width / 4;

.__admin__user__subscription__subscription__aa2d9 .removal-switch {
  padding-right: $changing-subscription-item-padding;
}

.__admin__user__subscription__subscription__aa2d9 .changing-medications .subscription-item-panel-wrapper {
  &:not(:first-child) {
    margin-left: 20px;
  }

  .is-being-removed {
    border-color: #f66553;
  }
}

.__admin__user__subscription__subscription__aa2d9 .pastel-blue-background {
  background: pastel-color("blue-40");
}

.__admin__user__subscription__subscription__aa2d9 .subscription-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-holds {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 9px;
  width: 100%;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-hold-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-hold-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2.5px 0;
  min-height: 36px;
  width: 100%;

  &.first {
    margin: 0 0 2.5px;
  }
}

.__admin__user__subscription__subscription__aa2d9 .subscription-hold-description {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  min-width: 240px;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-hold-hounding {
  font-size: 0.9em;
  font-weight: normal;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-hold-clear {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-panel-heading-warning .panel-title div {
  color: #f66553;
}

.__admin__user__subscription__subscription__aa2d9 .subscription-refill-input {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 5px;
  max-width: 90px;
}

.__admin__user__subscription__subscription__aa2d9 .compose-template-list {
  background: nurx-color("white");
  border-radius: $unit-reg;
  box-shadow: 0 0 15px rgba(185, 185, 208, 0.25);
  color: nurx-color("black");
  min-width: 460px;
  position: absolute;
  z-index: 9;

  .template-list-header {
    padding: $unit-reg $unit-md $unit-sm;

    h4 {
      color: #eef8ff;
      margin: 0;
    }
  }

  .template-list-body {
    background: nurx-color("white");
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    height: 280px;
    overflow-y: auto;
    padding: $unit-reg $unit-md $unit-sm;
  }

  button {
    background: nurx-color("accent");
    color: nurx-color("white");
    font-size: 0.9em;
    margin: 0 0 $unit-sm;
    padding: $unit-sm $unit-reg $unit-xs;

    .close {
      position: absolute;
      right: $unit-sm;
      top: $unit-sm;
    }
  }

  .dropdown-menu {
    padding: 0;
  }
}

.__admin__user__subscription__subscription__aa2d9 .fill-date-override-title-accent {
  color: nurx-color("accent");
}

.__admin__user__subscription__subscription__aa2d9 .is-editing {
  transition: none;
  visibility: hidden;
}

.__admin__user__subscription__subscription__aa2d9 .fill-date-override-title {
  &.disabled {
    .panel-title {
      opacity: 0.65;
    }
  }
}

.__admin__user__subscription__subscription__aa2d9 .edit-subscription-link {
  display: inline-flex;
  flex-direction: row;
}

.__admin__user__subscription__subscription__aa2d9 .children-margin-bottom-md>* {
  &:not(:last-child) {
    margin-bottom: $unit-reg;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.__admin__user__subscription__subscription__aa2d9 .local-fill-lasts-until {
  margin-bottom: 20px;
}

.__admin__user__subscription__subscription__aa2d9 .refill-text {
  color: grayscale-color("medium-gray");
}

// NOTE(jason 2020-07-18): .row-like-margin is an okay-at-best solution for negative margin on platform.
// Please try to avoid using this, especially on PX side.
.__admin__user__subscription__subscription__aa2d9 .row-like-margin {
  $margin: 0 - ($grid-gutter-width / 2);
  margin-left: $margin;
  margin-right: $margin;
}

.__admin__user__subscription__subscription__aa2d9 .font-setting {
  font-size: medium;
  font-weight: 500;
}
