$sticky-note-pale-yellow: #fcfcef;
$sticky-note-bright-yellow: #ffb400;
$pushpin-blue: #2196f3;
$message-jump-gray: rgba(0, 0, 0, 0.54);
$dark-gray: rgba(0, 0, 0, 0.87);
$medium-gray: rgba(0, 0, 0, 0.6);
$pale-gray: rgba(0, 0, 0, 0.34);
$unread-green: #4caf50;
$white: #fff;
$awaiting-spec: #ff69b4;

$gray-header-bg: #f0f0fb;
$sidebar-border-gray: #dee2e6;
$demon-gray: #666;
$mid-blue: #0d6efd;
$in-the-grayme: #eaeaea;
$off-gray: #f9f9fb;
$light-blue: #51a5eb;
$confirmation-border-gray: #ced4da;
$confirmation-body-text: #6c757d;
$fake-hyperlink: #2c8ed7;
$read-only-gray: #d3d3d3;
$warning-bg: #fee6e4;
$warning-border: #fdc1cc;
$warning-text: #d7230c;

$missing-requirements-banner-coming-soon: #fff9cb;

// Panels
$panel-background-color: rgba(bright-color("nurx-blue"), 0.12);

