.product-description {
  p,
  ul {
    @include text-bold;
    font-size: 15px;
  }

  ul.fa-ul {
    margin-left: $unit-reg;

    li {
      margin-bottom: $unit-sm;
    }
  }

  ul li i.fa-li {
    font-size: 7px;
    left: -$unit-reg;
    position: absolute;
    text-align: left;
    top: $unit-sm;
  }
}
