$_back-button-width: 46px;
$_action-height: $app-header-height;
$_heading-margin-top: $unit-sm;

.__header__mobile__840ae {
  @extend %iphone-padding-top;
  // NOTE(jason 2019-03-15): needed for %box-sizing-initial needed for iPhone X notch:
  @extend %box-sizing-initial;
  background: grayscale-color("white");
  border-bottom-color: grayscale-color("light-gray");
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: grayscale-color("black");
  height: $app-header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}

.__header__mobile__840ae.app-non-home-header {
  button.header-back-btn {
    display: inline-block;
  }
}

// TODO(jason 2019-03-15): Use flexbox instead of absolute positioning

.__header__mobile__840ae.app-home-header {
  height: 140px;
  position: absolute;
  z-index: 0;
}

.__header__mobile__840ae .app-header-heading {
  @include text-bold;
  bottom: $vertical-spacing-half;
  display: block;
  font-size: 17px;
  left: $_back-button-width;
  line-height: 1em;
  position: absolute;
  text-align: center;
  width: calc(100% - (2 * #{$_back-button-width}));
}

.__header__mobile__840ae .back-button {
  $icon-height: 18px;
  background-color: transparent;
  background-image: none;
  border: 0;
  bottom: 0;
  display: block;
  height: $app-header-height;
  left: 0;
  position: absolute;
  // top: calc(50% - ($icon-height / 2));
  width: $_back-button-width;

  img {
    bottom: 9px;
    height: $icon-height;
    left: 16px;
    position: absolute;
    width: auto;
  }
}
