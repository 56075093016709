.__safety-statement__83ab9 .safety-statement {
  background: pastel-color("purple-40");
  border-radius: $unit-sm;
  margin-bottom: $vertical-spacing;
  padding: $unit-reg;
  width: auto;

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .safety-ul {
    padding-left: 5%;
    padding-top: 2%;
    position: relative;

    li {
      list-style-type: numbers;
      margin-bottom: $unit-reg;
      padding: 0; // ensures / removes possible padding imported from other components
    }
  }
}
