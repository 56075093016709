.__pharmacy__pack-verify__4d8a5 .button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 1140px;
}

.__pharmacy__pack-verify__4d8a5 .btn-input {
  height: 50px;
  width: 140px;
}
