@include text-input-component(textarea);

.__controlled-textarea-box__d6735 {
  textarea {
    background-color: grayscale-color("lighter-gray");
    border-bottom-color: transparent;
    padding: 10px;
    resize: none;
  }
}
