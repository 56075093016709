.__toggle__9125d {
  $control-height: 24px;
  $control-width: 40px;
  $border-width: 2px;
  $border-color: grayscale-color("light-gray");
  $animation-timing: 100ms ease-in-out;
  background: grayscale-color("light-gray");
  border-radius: $control-height;
  height: $control-height;
  position: relative;
  transition: background $animation-timing;
  width: $control-width;

  &::after {
    background: grayscale-color("white");
    border-radius: 50%;
    content: "";
    height: 20px;
    margin-top: $border-width;
    position: absolute;
    top: 0;
    transition: left $animation-timing, margin-left $animation-timing;
    width: 20px;
  }

  // Place toggle circle to the left
  &[aria-checked="false"] {
    background: grayscale-color("light-gray");

    &::after {
      left: 0;
      margin-left: $border-width;
      margin-top: $border-width;
    }
  }

  // Place toggle circle to the right
  &[aria-checked="true"] {
    background: grayscale-color("black");

    &::after {
      left: 100%;
      margin-left: -($control-height - $border-width);
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  // hide the html input
  input {
    &[type="checkbox"] {
      appearance: none;
      display: none;
      height: 0;
      opacity: 0;
      width: 0;
    }
  }
}
