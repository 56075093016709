// As of 02012022 we are using ember-cli-notifications for toast messages in our app
// The styles in this file override the default styles for that component, in order to match our global styles and make it feel cohesive
// More info on the component and configurations: https://github.com/mansona/ember-cli-notifications

// Disabling the below lint rule because we do not control classnames in this component
/* stylelint-disable selector-class-pattern */

// Toast styles
.ember-cli-notifications-notification__container--top {
  max-width: 960px;
  top: $unit-lg !important;

  .c-notification__icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
  }

  .c-notification__content {
    padding: 15px;
  }
}

// Notification states
.ember-cli-notifications-notification__container {
  .c-notification--error {
    background-color: nurx-color("danger"); // Make error state color match our designs
  }

  .c-notification--info {
    background-color: nurx-color("info"); // Make info state color match our designs
  }

  .c-notification--success {
    background-color: nurx-color("success"); // Make success state color match our designs
  }

  .c-notification--warning {
    background-color: nurx-color("warning"); // Make warning state color match our designs
  }
}
