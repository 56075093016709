/*
 * Template Name: AppKit
 * Version: 1.0
*/
/* Theme default */

.__styles__dashboard-internal__scss-all__styles__a7619 html {
  height: 100%;
}

.__styles__dashboard-internal__scss-all__styles__a7619 button.close:focus {
  outline: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .close {
  text-shadow: none;
}

/* ======= Dropdown ======= */

.__styles__dashboard-internal__scss-all__styles__a7619 .dropdown-toggle {
  cursor: pointer;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .dropdown-menu {
  @include scrollbar (0.5em, #eff0f3, hidden);
  background-clip: padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  cursor: default;
  max-height: 33.3rem;
  overflow: auto;

  >li,
  >div li {
    font-size: 13px;
    margin-bottom: 0;

    >a {
      color: #494d55;
      padding: 10px 15px;

      &:hover {
        background: #f9f9fb;
      }

      .icon {
        display: inline-block;
        font-size: 20px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .dropdown-footer {
    background: #f9f9fb;
    color: #616670;
    padding: 15px;
    text-align: center;

    a {
      color: #616670;

      &:hover {
        color: #494d55;
      }
    }
  }
}

/* Override metisMenu CSS */

.__styles__dashboard-internal__scss-all__styles__a7619 .active>a>.fa.arrow::before {
  content: "\f0d8";
}

.__styles__dashboard-internal__scss-all__styles__a7619 .fa.arrow::before {
  content: "\f0d7";
}

/* ===== Content Wrapper ===== */

.__styles__dashboard-internal__scss-all__styles__a7619 .content-wrapper {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  transition: margin 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.__styles__dashboard-internal__scss-all__styles__a7619 .compact {
  .content-wrapper {
    margin-left: 0;
  }

  &.nav-toggled .content-wrapper {
    margin-left: 180px;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .mobile .content-wrapper {
  margin-left: 0;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled {
  &.mobile .content-wrapper,
  &:not(.compact):not(.mobile) .content-wrapper {
    margin-left: 40px;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .content-wrapper .view-title {
  @include text-bold;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 0;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav .nav-label,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav .nav-label,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav .nav-label,
.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav .label,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav .label,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav .label,
.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav li .badge,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav li .badge,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav li .badge,
.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav .fa.arrow,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav .fa.arrow,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav .fa.arrow,
.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav .sub-menu,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav .sub-menu,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav .sub-menu {
  display: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .compact:not(.nav-toggled) .main-nav>ul>li:hover .sub-menu,
.__styles__dashboard-internal__scss-all__styles__a7619 .mobile.nav-toggled .main-nav>ul>li:hover .sub-menu,
.__styles__dashboard-internal__scss-all__styles__a7619 .nav-toggled:not(.compact):not(.mobile) .main-nav>ul>li:hover .sub-menu {
  display: block;
  left: 40px;
  margin-top: -44px;
  position: absolute;
  width: 180px;
  z-index: 10;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .mobile .main-nav {
  ul.sub-menu>li .nav-label {
    display: block !important;
  }

  .collapse,
  .collapsing,
  .in {
    height: auto !important;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .nav-filter {
  cursor: pointer;
}

/* ===== Layout No Nav ===== */

.__styles__dashboard-internal__scss-all__styles__a7619 .layout-no-leftnav {
  background: #f9f9fb;

  .header {
    background: none;
    box-shadow: none;
    height: auto;
  }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .__styles__dashboard-internal__scss-all__styles__a7619 .content-wrapper {
    border-left: 0;

    .view-title {
      font-size: 16px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .__styles__dashboard-internal__scss-all__styles__a7619 .header {
    .main-nav-toggle {
      margin-left: 5px;
    }

    .utilities-container {
      .item {
        margin-right: 15px;
      }
    }

    .search-container {
      position: static;

      .icon {
        &.fa-search {
          position: relative;
        }

        &.clear-search {
          color: #494d55;
          right: 15px;
          top: 15px;
        }
      }
    }
  }
}

/* Extra small devices (phones, less than 768px) */
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */

.__styles__dashboard-internal__scss-all__styles__a7619 .admin-dashboard.pharmacy-page {

  // override to allow vertical scroll in pharmacy
  .container-fluid>.row .module-wrapper {
    overflow-y: auto;
  }

  .module .module-heading {
    border: 0;
    padding: 0;

    h3.module-title {
      @include text-bold;
      border-bottom: 2px solid #6fbeff;
      color: #40a1f0;
      font-size: 1.06em;
      padding: 0 0 0.8em;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .module {
  background-clip: padding-box;
  background-color: nurx-color("white");
  border: 1px solid #f3f3f3;
  border-bottom-width: 2px;
  border-radius: 4px;
  height: 100%;
  margin-bottom: 30px;
  position: relative;

  &.module-no-heading .module-content-inner {
    padding-top: 0;
  }

  &.module-has-footer .module-inner {
    padding-bottom: 0;
  }

  .module-inner {
    padding: 30px;

    &.module-inner-wider {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .module-content-has-chart {
    padding-bottom: 30px;
  }

  .module-heading {
    border-bottom: 1px solid #f3f3f7;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;

    .module-title {
      @include text-bold;
      font-size: 14px;
      margin: 0;
    }

    .actions {
      line-height: 1;
      position: absolute;
      right: 0;
      top: 0;

      .action-group {
        display: inline-block;
        float: left;

        &:not(:only-child) {
          &:not(:last-child) {
            margin-right: 3rem;
          }
        }
      }

      li {
        vertical-align: middle;

        a {
          color: #9499a3;

          &:hover {
            color: #616670;
            text-decoration: none;
          }
        }

        .icon {
          font-size: 18px;
        }
      }
    }
  }

  .module-content-inner {
    padding-bottom: 30px;
    padding-top: 2rem;
  }

  .module-content {
    h4.has-divider {
      border-bottom: 1px dotted #f3f3f7;
      margin-bottom: 15px;
      padding-bottom: 5px;
    }

    .dropdown-menu {
      li {
        margin-bottom: 0;
      }

      >li>a {
        padding: 5px 20px;
      }
    }

    .list {
      li {
        margin-bottom: 10px;
      }

      ul,
      ol {
        margin-top: 10px;
      }
    }

    dl dd {
      margin-bottom: 10px;
    }

    blockquote {
      font-size: 14px;
    }

    .module-content-inner .custom-quote {
      border-color: #b1e3e5;

      p {
        color: #616670;
        font-size: 14px;
        line-height: 1.5;
      }

      .fa {
        color: nurx-color-level("accent", 1);
        margin-right: 10px;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .module {
  &.module-full-page {
    margin-bottom: 0;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .module .module-content .module-content-inner .custom-quote .people {
  color: #494d55;
  font-size: 12px;
  font-style: normal;
  margin-top: 10px;

  .name {
    color: nurx-color-level("accent", -1);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .module .module-footer {
  background: nurx-color("white");
  border-top: 2px solid #f3f3f7;
  margin-top: 1em;
  padding: 2rem 0 0;

  a {
    color: #9499a3;

    &:hover {
      color: nurx-color-level("accent", 1);
    }
  }

  button {
    &:not(:only-child) {
      &:not(:first-child) {
        margin-left: 0.5em;
      }
    }
  }

  .meta {
    color: #9499a3;
    font-size: 12px;
    margin-bottom: 0;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .module-content-inner.white-background {
  background-color: nurx-color("white");
  border: nurx-color("white");
  border-radius: 0.5em;
  box-shadow: 0 2px 25px 0 rgba(181, 181, 205, 0.2);
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .__styles__dashboard-internal__scss-all__styles__a7619 .module {
    .module-inner {
      padding: 20px 15px;
    }

    .module-heading {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
}

/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ======= Typography ========= */

.__styles__dashboard-internal__scss-all__styles__a7619 p {
  line-height: 1.5;
  margin-bottom: 15px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 a {
  color: nurx-color-level("accent", -1);
  transition: all 0.4s ease-in-out;
}

.__styles__dashboard-internal__scss-all__styles__a7619 a {
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .list-inline .fa {
  margin-right: 5px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .text-muted {
  color: grayscale-color("gray");
}

.__styles__dashboard-internal__scss-all__styles__a7619 .text-primary {
  color: nurx-color("accent") !important;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .text-theme {
  color: nurx-color-level("accent", 1);
}

.__styles__dashboard-internal__scss-all__styles__a7619 .text-success {
  color: nurx-color("success");
}

.__styles__dashboard-internal__scss-all__styles__a7619 .text-danger {
  color: nurx-color("danger");
}

.__styles__dashboard-internal__scss-all__styles__a7619 .bg-primary {
  background-color: #494d55;
  color: rgba(255, 255, 255, 0.8);
}

/* ======= Buttons ====== */

.__styles__dashboard-internal__scss-all__styles__a7619 .btn,
.__styles__dashboard-internal__scss-all__styles__a7619 a.btn {
  @include text-bold;
  // font-size: 13px;
  line-height: 1.5;
  transition: all 0.4s ease-in-out;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-link:hover {
  text-decoration: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn,
.__styles__dashboard-internal__scss-all__styles__a7619 a.btn {
  .fa {
    &:not(:only-child) {
      margin-right: 5px;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-oval {
  background-clip: padding-box;
  border: 0;
  border-radius: 60px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-group-sm>.btn,
.__styles__dashboard-internal__scss-all__styles__a7619 .btn-sm {
  padding: 4px 8px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-group-xs>.btn,
.__styles__dashboard-internal__scss-all__styles__a7619 .btn-xs {
  padding: 2px 4px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-lg {
  font-size: 16px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-sm {
  font-size: 12px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-xs {
  font-size: 10px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-default {
  background: nurx-color("white");
  border: 1px solid #e6e7ea;
  color: #a2a6af;

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus,
  &.hover {
    background: #f9f9fb;
    border-color: #e6e7ea;
    box-shadow: none;
    color: #6c727e;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-selector {
  @extend .btn-default;
  text-align: left;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-selector-wrapper {
  margin-top: 8px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .open>.dropdown-toggle.btn-default {
  border-color: #e6e7ea;

  &:focus,
  &:active,
  &:hover {
    background: #f9f9fb;
    border-color: #e6e7ea;
    box-shadow: none;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-primary {
  background: $primary-blue;
  border: 1px solid $primary-blue;
  color: nurx-color("white");

  @media (hover:hover) {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.hover,
    &.active:hover {
      background: nurx-color-level("accent", -1);
      border: 1px solid nurx-color-level("accent", -1);
      color: nurx-color("white");
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-transparent {
  background: transparent;
  border: 1px solid transparent;
  color: nurx-color("black");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: transparent;
    border: 1px solid nurx-color("black");
    color: nurx-color("black");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-success {
  background: #75c181;
  border: 1px solid #75c181;
  color: nurx-color("white");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: #63b971;
    border: 1px solid #63b971;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-warning {
  background: #f8a13f;
  border: 1px solid #f8a13f;
  color: nurx-color("white");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: #f79526;
    border: 1px solid #f79526;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-danger {
  background: nurx-color("danger");
  border: 1px solid nurx-color("danger");
  color: nurx-color("white");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: #f66553;
    border: 1px solid #f66553;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-yellow {
  background: #f2b542;
  border: 1px solid #f2b542;
  color: nurx-color("white");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    background: #f0ac2a;
    border: 1px solid #f0ac2a;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-link {
  color: nurx-color("accent");

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    color: nurx-color-level("accent", -1);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-primary-alt {
  background: none;
  border: 1px solid nurx-color-level("accent", 1);
  color: nurx-color-level("accent", 1);

  &.btn-gray-border {
    border: 1px solid rgba(0, 0, 0, 0.13);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-primary-alt {
  @media (hover:hover) {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.hover {
      background: nurx-color-level("accent", 1);
      border-color: nurx-color-level("accent", 1);
      color: nurx-color("white");
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .open>.dropdown-toggle {
  &.btn-primary {
    background: nurx-color-level("accent", -1);
    border-color: nurx-color-level("accent", -1);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .open>.dropdown-toggle {
  &.btn-success {
    background: #63b971;
    border-color: #63b971;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    color: nurx-color("white");
  }

  &.btn-warning {
    background: #f79526;
    border-color: #f79526;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    color: nurx-color("white");
  }

  &.btn-danger {
    background: #f66553;
    border-color: #f66553;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-group {
  &.open .dropdown-toggle {
    transition: none;

    .caret {
      transform: rotate(180deg);
    }

    &.btn-default {
      box-shadow: none;
    }
  }

  >.btn+.dropdown-toggle {
    &.btn-primary {
      background: nurx-color-level("accent", -1);
      color: nurx-color("white");
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-group>.btn+.dropdown-toggle.btn-primary:hover {
  background: nurx-color-level("accent", -1);
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-group>.btn+.dropdown-toggle {
  &.btn-success {
    background: #63b971;
    color: nurx-color("white");

    &:hover {
      background: #52b161;
    }
  }

  &.btn-warning {
    background: #f79526;
    color: nurx-color("white");

    &:hover {
      background: #f6890e;
    }
  }

  &.btn-danger {
    background: #f66553;
    color: nurx-color("white");

    &:hover {
      background: #f4503b;
    }
  }

  &.btn-yellow {
    background: #f0ac2a;
    color: nurx-color("white");

    &:hover {
      background: #efa212;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-default {
  &.disabled {
    background: #f9f9fb;
    color: #9499a3;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #f9f9fb;
      color: #9499a3;
    }
  }

  &[disabled] {
    background: #f9f9fb;
    color: #9499a3;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #f9f9fb;
      color: #9499a3;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .btn-default {
  background: #f9f9fb;
  color: #9499a3;

  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background: #f9f9fb;
    color: #9499a3;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-primary {
  &.disabled {
    background: nurx-color-level("accent", 1);
    border: 1px solid nurx-color-level("accent", 1);

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: nurx-color-level("accent", 1);
      border: 1px solid nurx-color-level("accent", 1);
    }
  }

  &[disabled] {
    background: nurx-color-level("accent", 1);
    border: 1px solid nurx-color-level("accent", 1);

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: nurx-color-level("accent", 1);
      border: 1px solid nurx-color-level("accent", 1);
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .btn-primary {
  background: nurx-color-level("accent", 1);
  border: 1px solid nurx-color-level("accent", 1);

  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background: nurx-color-level("accent", 1);
    border: 1px solid nurx-color-level("accent", 1);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-success {
  &.disabled {
    background: #75c181;
    border: 1px solid #75c181;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #75c181;
      border: 1px solid #75c181;
    }
  }

  &[disabled] {
    background: #75c181;
    border: 1px solid #75c181;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #75c181;
      border: 1px solid #75c181;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .btn-success {
  background: #75c181;
  border: 1px solid #75c181;

  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background: #75c181;
    border: 1px solid #75c181;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-warning {
  &.disabled {
    background: #f8a13f;
    border: 1px solid #f8a13f;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #f8a13f;
      border: 1px solid #f8a13f;
    }
  }

  &[disabled] {
    background: #f8a13f;
    border: 1px solid #f8a13f;

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: #f8a13f;
      border: 1px solid #f8a13f;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .btn-warning {
  background: #f8a13f;
  border: 1px solid #f8a13f;

  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background: #f8a13f;
    border: 1px solid #f8a13f;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-danger {
  &.disabled {
    background: nurx-color("danger");
    border: 1px solid nurx-color("danger");

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: nurx-color("danger");
      border: 1px solid nurx-color("danger");
    }
  }

  &[disabled] {
    background: nurx-color("danger");
    border: 1px solid nurx-color("danger");

    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background: nurx-color("danger");
      border: 1px solid nurx-color("danger");
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .btn-danger {
  background: nurx-color("danger");
  border: 1px solid nurx-color("danger");

  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background: nurx-color("danger");
    border: 1px solid nurx-color("danger");
  }
}

/* ======= Panels ========= */

.__styles__dashboard-internal__scss-all__styles__a7619 .panel {
  box-shadow: none;

  .panel-heading {
    padding: 1.05rem 1.5rem 0.9rem;

    .panel-title {
      @include text-bold;
      font-size: 14px;

      a {
        @include text-bold;
        color: #616670;
        transition: none;

        &.active {
          color: nurx-color-level("accent", 1);
        }
      }

      .icon-right {
        float: right;
        right: 0;
      }
    }
  }

  .panel-actions {
    display: flex;
    justify-content: flex-end;

    >* {
      margin-left: $unit-xs;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel .panel-heading .panel-title a .fa {
  color: nurx-color-level("accent", 1);
  margin-right: 5px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel .panel-heading.icon-right .panel-title {
  position: relative;

  .fa {
    position: absolute;
    right: 0;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-default {
  >.panel-heading {
    background: #f9f9fb;
    color: #494d55;
  }

  .panel-footer {
    background: #f9f9fb;
    color: #616670;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-theme {
  border-color: #616670;

  >.panel-heading {
    background: #616670;
    color: nurx-color("white");
  }

  .panel-footer {
    background: #616670;
    border-color: #616670;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-primary {
  border-color: nurx-color-level("accent", 1);

  >.panel-heading {
    background: nurx-color-level("accent", 1);
    border-color: nurx-color-level("accent", 1);
    color: nurx-color("white");
  }

  .panel-footer {
    background: nurx-color-level("accent", 1);
    border-color: nurx-color-level("accent", 1);
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-warning {
  border-color: #f8a13f;

  >.panel-heading {
    background: #f8a13f;
    border-color: #f8a13f;
    color: nurx-color("white");
  }

  .panel-footer {
    background: #f8a13f;
    border-color: #f8a13f;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-yellow {
  border-color: #f2b542;

  >.panel-heading {
    background: #f2b542;
    border-color: #f2b542;
    color: nurx-color("white");
  }

  .panel-footer {
    background: #f2b542;
    border-color: #f2b542;
    color: nurx-color("white");
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .panel-heading-hoverable {
  @extend .panel-heading;
  cursor: pointer;

  &:hover {
    background: #eeeef5;
  }
}

/* ======= Tabs ========= */

.__styles__dashboard-internal__scss-all__styles__a7619 .nav-tabs {
  border-color: #eaeaf1;
  margin-bottom: 15px;

  >li {
    &.active>a {
      background-color: nurx-color("white");
      border: 1px solid #eaeaf1;
      border-bottom-color: transparent;
      color: #494d55;

      &:focus,
      &:hover {
        background-color: nurx-color("white");
        border: 1px solid #eaeaf1;
        border-bottom-color: transparent;
        color: #494d55;
      }
    }

    >a {
      @include text-bold;
      color: #9499a3;

      &:hover {
        background: #f9f9fb;
        border-color: #f9f9fb;
        color: #494d55;
      }
    }
  }
}

/* ======= Alerts ========= */

.__styles__dashboard-internal__scss-all__styles__a7619 .alert-theme {
  background: #d0d3d7;
  border: 1px solid #b5b8bf;
  color: #494d55;

  a {
    @include text-bold;
    color: #020203;

    &:hover {
      color: grayscale-color("black");
    }
  }

  &.alert-success {
    background: #dff0e1;
    border: 1px solid #cde9d1;
    color: #40904d;

    a {
      @include text-bold;
      color: #295b31;

      &:hover {
        color: #214927;
      }
    }
  }

  &.alert-info {
    background: #f5faff;
    border: 1px solid #d4e5ff;
    color: nurx-color-level("black", 0);

    a {
      @include text-bold;
      color: #0e5d86;

      &:hover {
        color: #0b4d6f;
      }
    }
  }

  &.alert-warning {
    background: #feeedc;
    border: 1px solid #fde4c9;
    color: #b16106;

    a {
      @include text-bold;
      color: #804605;

      &:hover {
        color: #673804;
      }
    }
  }

  &.alert-danger {
    background: #fee7e4;
    border: 1px solid #fcd1cc;
    color: #d7230c;

    a {
      @include text-bold;
      color: #a71b09;

      &:hover {
        color: #8e1708;
      }
    }
  }

  &.alert-reminder {
    background: #f5faff;
    border: 1px solid #d4e5ff;
    color: #d7230c;

    strong {
      color: nurx-color-level("black", 0);
    }

    p {
      color: nurx-color-level("black", 0);
    }

    a {
      @include text-bold;
      color: #0e5d86;

      &:hover {
        color: #0b4d6f;
      }
    }
  }
}

/* ======= Progress ========= */

.__styles__dashboard-internal__scss-all__styles__a7619 .progress-container {
  display: block;
  overflow: hidden;

  .big {
    @include text-bold;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 6px 12px;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .progress {
  background: #f3f3f7;
  background-clip: padding-box;
  border-radius: 0;
  box-shadow: none;
  display: block;
  height: auto;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .progress-bar {
  @include text-bold;
  background: #616670;
  box-shadow: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .progress-sm {
  height: 15px;

  .progress-bar {
    font-size: 12px;
    line-height: 15px;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .progress-bar-success {
  background: #75c181;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .progress-bar-readable {
  color: #333;
}

/* ======= List Group ======= */

.__styles__dashboard-internal__scss-all__styles__a7619 a.list-group-item {
  border-color: #eaeaf1;
  color: #616670;

  .list-group-item-heading {
    @include text-bold;
    color: #616670;
  }

  &:focus,
  &:hover {
    background: #f9f9fb;
    color: #494d55;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .list-group-item.active {
  background: nurx-color-level("accent", 1);
  border-color: nurx-color-level("accent", 1);

  &:focus,
  &:hover {
    background: nurx-color-level("accent", 1);
    border-color: nurx-color-level("accent", 1);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .list-group-item.active {
  .list-group-item-text,
  &:focus .list-group-item-text,
  &:hover .list-group-item-text {
    color: rgba(255, 255, 255, 0.8);
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .list-group-item-success,
.__styles__dashboard-internal__scss-all__styles__a7619 a.list-group-item-success {
  background: #dff0e1;
  border: 1px solid #cde9d1;
  color: #40904d;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .list-group-item-success:hover,
.__styles__dashboard-internal__scss-all__styles__a7619 a.list-group-item-success:hover,
.__styles__dashboard-internal__scss-all__styles__a7619 .list-group-item-success:focus,
.__styles__dashboard-internal__scss-all__styles__a7619 a.list-group-item-success:focus {
  background: #bbe1c1;
  border: 1px solid #bbe1c1;
  color: #316c3a;
}

/* ====== Table ======== */

.__styles__dashboard-internal__scss-all__styles__a7619 .table-responsive {
  border: 0;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table {
  border-color: #eaeaf1;

  > {
    thead>tr>,
    tbody>tr> {
      th {
        @include text-bold;
        border-color: #eaeaf1;
        color: #616670;
      }

      td {
        border-color: #eaeaf1;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-small {
  font-size: 0.9em;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-striped>tbody>tr:nth-of-type(odd),
.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr:hover {
  background: #f9f9fb;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table> {
  tbody>tr {
    &.active> {
      td,
      th {
        background: #f9f9fb;
      }
    }

    > {
      td.active,
      th.active {
        background: #f9f9fb;
      }
    }
  }

  tfoot>tr {
    &.active> {
      td,
      th {
        background: #f9f9fb;
      }
    }

    > {
      td.active,
      th.active {
        background: #f9f9fb;
      }
    }
  }

  thead>tr {
    &.active> {
      td,
      th {
        background: #f9f9fb;
      }
    }

    > {
      td.active,
      th.active {
        background: #f9f9fb;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr {
  &.active:hover> {
    td,
    th {
      background: #f0f0f5;
    }
  }

  &:hover>.active {
    background: #f0f0f5;
  }

  > {
    td.active:hover,
    th.active:hover {
      background: #f0f0f5;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table> {
  tbody>tr {
    &.success> {
      td,
      th {
        background: #dff0e1;
      }
    }

    > {
      td.success,
      th.success {
        background: #dff0e1;
      }
    }
  }

  tfoot>tr {
    &.success> {
      td,
      th {
        background: #dff0e1;
      }
    }

    > {
      td.success,
      th.success {
        background: #dff0e1;
      }
    }
  }

  thead>tr {
    &.success> {
      td,
      th {
        background: #dff0e1;
      }
    }

    > {
      td.success,
      th.success {
        background: #dff0e1;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr {
  &.success:hover> {
    td,
    th {
      background: #bbe1c1;
    }
  }

  &:hover>.success {
    background: #bbe1c1;
  }

  > {
    td.success:hover,
    th.success:hover {
      background: #bbe1c1;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table> {
  tbody>tr {
    &.info> {
      td,
      th {
        background: #daf0fb;
      }
    }

    > {
      td.info,
      th.info {
        background: #daf0fb;
      }
    }
  }

  tfoot>tr {
    &.info> {
      td,
      th {
        background: #daf0fb;
      }
    }

    > {
      td.info,
      th.info {
        background: #daf0fb;
      }
    }
  }

  thead>tr {
    &.info> {
      td,
      th {
        background: #daf0fb;
      }
    }

    > {
      td.info,
      th.info {
        background: #daf0fb;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr {
  &.info:hover> {
    td,
    th {
      background: #abddf6;
    }
  }

  &:hover>.info {
    background: #abddf6;
  }

  > {
    td.info:hover,
    th.info:hover {
      background: #abddf6;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table> {
  tbody>tr {
    &.warning> {
      td,
      th {
        background: #feeedc;
      }
    }

    > {
      td.warning,
      th.warning {
        background: #feeedc;
      }
    }
  }

  tfoot>tr {
    &.warning> {
      td,
      th {
        background: #feeedc;
      }
    }

    > {
      td.warning,
      th.warning {
        background: #feeedc;
      }
    }
  }

  thead>tr {
    &.warning> {
      td,
      th {
        background: #feeedc;
      }
    }

    > {
      td.warning,
      th.warning {
        background: #feeedc;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr {
  &.warning:hover> {
    td,
    th {
      background: #fcd6ab;
    }
  }

  &:hover>.warning {
    background: #fcd6ab;
  }

  > {
    td.warning:hover,
    th.warning:hover {
      background: #fcd6ab;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table> {
  tbody>tr {
    &.danger> {
      td,
      th {
        background: #fee7e4;
      }
    }

    > {
      td.danger,
      th.danger {
        background: #fee7e4;
      }
    }
  }

  tfoot>tr {
    &.danger> {
      td,
      th {
        background: #fee7e4;
      }
    }

    > {
      td.danger,
      th.danger {
        background: #fee7e4;
      }
    }
  }

  thead>tr {
    &.danger> {
      td,
      th {
        background: #fee7e4;
      }
    }

    > {
      td.danger,
      th.danger {
        background: #fee7e4;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .table-hover>tbody>tr {
  &.danger:hover> {
    td,
    th {
      background: #fbbcb4;
    }
  }

  &:hover>.danger {
    background: #fbbcb4;
  }

  > {
    td.danger:hover,
    th.danger:hover {
      background: #fbbcb4;
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 table {
  &.dataTable {
    &.display tbody tr.odd>.sorting_1,
    &.order-column.stripe tbody tr.odd>.sorting_1,
    &.display tbody tr:hover>.sorting_1,
    &.order-column.hover tbody tr:hover>.sorting_1 {
      background: inherit;
    }
  }

  &.table thead th {
    &.sorting::after,
    &.sorting_asc::after,
    &.sorting_desc::after {
      top: 10px;
    }

    &.sorting::after,
    &.sorting_asc::after,
    &.sorting_desc::after {
      top: 12px;
    }
  }

  &.dataTable thead th {
    border-bottom: 2px solid #eaeaf1;
  }
}

/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ===== Forms (basic) ===== */

.__styles__dashboard-internal__scss-all__styles__a7619 label {
  @include text-bold;
  color: #494d55;

  .help-block {
    @include text-normal;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .help-block {
  color: #a2a6af;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .form-group-striped .form-group {
  margin-bottom: 0;
  padding: 15px 0;

  &:nth-child(odd) {
    background: #f9f9fb;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .form-control {
  background-clip: padding-box;
  border-color: #dadae7;
  border-radius: 0.3em;
  box-shadow: none;
  font-size: 1.04em;
  padding: 0.6em 0.7em 0.45em;

  &::input-placeholder {
    /* WebKit browsers */
    color: #afb3bb;
  }

  &::placeholder {
    /* Mozilla Firefox 19+ */
    color: #afb3bb;
  }

  &:focus {
    border-color: #bcbcd2;
    box-shadow: none;
  }

  &[disabled],
  &[readonly] {
    background-color: #f5f5f5;
    color: #c3c3c3;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] .form-control {
  background-color: #f5f5f5;
  color: #c3c3c3;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .checkbox.disabled label,
.__styles__dashboard-internal__scss-all__styles__a7619 .radio.disabled label {
  color: #9499a3;
}

.__styles__dashboard-internal__scss-all__styles__a7619 fieldset[disabled] {
  .checkbox label,
  .radio label {
    color: #9499a3;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 input {
  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="submit"],
  &[type="button"] {
    appearance: none;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 textarea {
  appearance: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .input-group-addon {
  background: #f9f9fb;
  color: #616670;
}

/* ====== Override FullCalendar ======= */

.__styles__dashboard-internal__scss-all__styles__a7619 .fa button {
  @include text-bold;
  background: nurx-color("white");
  border: 1px solid #cbcdd2;
  box-shadow: none;
  color: #868c97;
  display: inline-block;
  font-size: 13px;
  height: inherit;
  line-height: 1.5;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .fa button:hover,
.__styles__dashboard-internal__scss-all__styles__a7619 .fa button:focus,
.__styles__dashboard-internal__scss-all__styles__a7619 .fa button:active {
  background: #f9f9fb;
  border-color: #cbcdd2;
  box-shadow: none;
  color: #6c727e;
}

.__styles__dashboard-internal__scss-all__styles__a7619 body.dragging {
  cursor: move !important;

  * {
    cursor: move !important;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .dragged {
  opacity: 0.8;
  position: absolute;
  top: 0;
  z-index: 2000;
}

.__styles__dashboard-internal__scss-all__styles__a7619 ul.sortable {
  list-style: none;
  position: relative;

  li {
    >span {
      padding: 10px 10px 10px 24px;
    }

    &:hover .fa {
      color: #9499a3;
    }
  }

  .fa {
    color: #afb3bb;
    margin-left: 10px;
    margin-top: 14px;
    position: absolute;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .modal button.close {
  @include text-normal;
  color: #494d55;
  font-size: 28px;
  margin: 0;
  opacity: 0.35;
  position: absolute;
  right: 15px;
  text-shadow: none;
  top: 11px;

  &:hover {
    opacity: 0.6;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .modal-content {
  background-clip: padding-box;
  border-radius: 4px;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .modal-title {
  text-align: center;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .modal-header {
  border-bottom: 0;
  padding: 30px;
  padding-bottom: 0;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .modal-body {
  padding: 30px 60px;
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .__styles__dashboard-internal__scss-all__styles__a7619 .modal-body {
    padding: 30px;
  }
}

/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* ======= Tags ======== */

.__styles__dashboard-internal__scss-all__styles__a7619 .tags {
  bottom: -12px;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  right: 24px;

  .tag {
    margin-bottom: 15px;

    a {
      @include text-bold;
      background: #616670;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      color: nurx-color("white");
      margin-left: 20px;
      padding: 0 10px 0 12px;
      text-decoration: none;

      &:hover {
        background: #555963;

        &::before {
          border-color: transparent #555963 transparent transparent;
        }
      }

      &::before {
        border-color: transparent #616670 transparent transparent;
        border-style: solid;
        border-width: 12px 12px 12px 0;
        content: "";
        float: left;
        height: 0;
        left: -12px;
        position: absolute;
        top: 0;
        width: 0;
      }

      &::after {
        background: nurx-color("white");
        border-radius: 2px;
        content: "";
        float: left;
        height: 4px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 4px;
      }
    }
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .tags {
  .tag {
    float: left;
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    position: relative;
  }

  a {
    float: left;
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    position: relative;
  }
}

/* ======= Helper ======== */

.__styles__dashboard-internal__scss-all__styles__a7619 .display-block {
  display: block;
}

/* Large devices (large desktops, 1200px and up) */
@media print {
  .__styles__dashboard-internal__scss-all__styles__a7619 .content-wrapper {
    margin-left: 0;
  }
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn.btn-link:active:focus {
  // TODO: This is a temporary fix for strange browser focus/active styles being applied to newly added btn-link styles in login template.
  // &:active:focus combine pseudo is coming from 3325 scss-all/bootstrap.scss
  outline: none;
}

.__styles__dashboard-internal__scss-all__styles__a7619 .btn-link {
  color: nurx-color-level("accent", 1);

  &.disabled {
    color: #b7b7bb;
    opacity: 1 !important;
    outline: none;

    &:hover {
      color: #b7b7bb;
    }

    &:active {
      outline: none;
    }
  }
}
