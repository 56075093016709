.__styles__survey-end__survey-terminal__a3163 ul.suggestions-list {
  margin: 0 0 0 20px;

  li {
    list-style: disc;
    padding: 0 0 10px 10px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}
