.__nurx-contact-form__ed3c5 input {
  &::placeholder {
    color: grayscale-color("light-gray");
  }
}

.__nurx-contact-form__ed3c5 .address-form-actions,
.__nurx-contact-form__ed3c5 .address-form,
.__nurx-contact-form__ed3c5 form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.__nurx-contact-form__ed3c5 p:last-of-type {
  margin-bottom: -20px;
}
