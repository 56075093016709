.__styles__pages__category__4d139 .category-page {

  // ALL / Top Section
  .top-section {
    background: nurx-color("black");
    // FIX - Temporary fix. Weird overflow bug.
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 42px;
    padding-top: 42px;
    text-align: center;

    @media (max-width: $tablet) {
      padding-bottom: 28px;
      padding-top: 28px;
    }

    @media (max-width: $phone) {
      padding-bottom: 21px;
      padding-top: 21px;
    }

    h1.page-header {
      @include text-bold;
      border: 0;
      color: nurx-color("white");
      font-size: 3em;
      line-height: 1.2em;
      margin: $unit-md auto 21px;
      max-width: 800px;
      padding: 0 5%;

      &.page-header-smaller {
        font-size: 2em;

        @media (max-width: $tablet) {
          font-size: 1.7em;
        }

        @media (max-width: $phone) {
          font-size: 5vw;
          margin-bottom: 20px;
          margin-top: 0;
        }
      }

      @media (max-width: $tablet) {
        font-size: 2.4em;
      }

      @media (max-width: $phone) {
        font-size: 8.6vw;
      }
    }

    h2 {
      @include text-bold;
      color: nurx-color-level("white", -3);
      font-size: 1.5em;
      margin-bottom: $unit-xs;
      margin-top: $unit-sm;

      @media (max-width: $tablet) {
        margin-top: 0;
      }

      @media (max-width: $phone) {
        font-size: 1.3em;
        line-height: 1em;
      }
    }

    .btn {
      margin: $unit-reg $unit-xs;

      @media (max-width: $phone) {
        font-size: 1.1em;
        margin: 10px $unit-xs;
        padding-left: $unit-xs;
        padding-right: $unit-xs;
        width: 40%;
      }
    }

    // Category Jurisdiction Selector
    .select-jurisdiction-container {
      display: inline-block;
      width: 400px;

      @media (max-width: $tablet) {
        padding-bottom: 2px;
        padding-top: 12px;
        width: 100%;
      }

      form {
        @media (max-width: $tablet) {
          text-align: center;
        }
      }

      label {
        @include text-bold;
        display: inline-block;
        font-size: 16px;
      }

      select {
        border: nurx-color("white");
        height: 35px;
        margin-left: 8px;
      }

      .select-jurisdiction-dropdown {
        display: inline-block;
        width: 200px;

        @media (max-width: $tablet) {
          width: 45%;
        }
      }
    }
  }

  .search-section {
    background: #f6f6f7;

    @media (max-width: $phone) {
      background: #f5f5f8;
      border-bottom: 2px solid #e7e7ec;
      border-top: 2px solid #e7e7ec;
    }
  }

  // Main Section Container
  .main-section {
    background: nurx-color-level("black", 9.5);
    padding-bottom: 64px;
    padding-top: 32px;

    @media (max-width: $phone) {
      padding-bottom: $unit-md;
      padding-top: 0;
    }
  }
}

.__styles__pages__category__4d139 .drug-search-field {
  position: relative;

  .fa-search {
    @extend .non-selectable;
    color: rgb(71, 151, 239);
    content: "";
    font-size: 18px;
    left: 15px;
    position: absolute;
    top: 11px;
  }

  input {
    @include text-bold;
    background: nurx-color("white");
    border-radius: 30px;
    border-style: none;
    border-width: 0;
    font-size: 18px;
    padding: 10px 10px 9.5px 43px;
    width: 100%;

    &::input-placeholder {
      /* WebKit, Blink, Edge */
      color: $placeholder-gray;
    }

    &:placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $placeholder-gray;
      opacity: 1;
    }

    &::placeholder {
      /* Mozilla Firefox 19+ */
      color: $placeholder-gray;
      opacity: 1;
    }

    &:placeholder-shown {
      /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
      color: $placeholder-gray;
    }

    @media (max-width: $phone) {
      font-size: 16px;
    }
  }
}


// Contraceptive Cards Display
.__styles__pages__category__4d139 .brand-listing {
  display: inline-block;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $unit-lg;

    @media (max-width: $phone) {
      margin-bottom: 0;
    }
  }

  .brand-listing-header {
    position: relative;
    width: 100%;

    @media (max-width: $phone) {
      display: inline-block;
      margin-top: $unit-md;
    }

    .title {
      @include text-bold;
      border-bottom: 2px solid #e8eaf1;
      color: nurx-color("black");
      font-size: 1.8em;
      line-height: 1.4em;
      margin-bottom: 12px;
      padding-bottom: 12px;

      @media (max-width: $phone) {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .description {
      @include text-normal;
      color: #636363;
      font-size: 1.26em;
      line-height: 1.5em;
      margin-bottom: $unit-sm;
      max-height: initial;
      transition: max-height 0.5s;
      width: 100%;

      @media (max-width: $phone) {
        @include text-normal;
        color: #737373;
        line-height: 1.4em;
        margin-bottom: 0;
        max-height: 0;
        overflow: hidden;

        &.active {
          display: inline-block;
          margin-bottom: $unit-sm;
          margin-top: $unit-reg;
          max-height: 100%;
        }
      }
    }

    // Hide/Show Desciption on Mobile
    a.toggle-description {
      @include text-bold;
      border: 1px solid nurx-color("accent");
      border-radius: $border-radius-standard;
      color: nurx-color("accent");
      display: none;
      float: left;
      font-size: 1.1em;
      margin-bottom: $unit-sm;
      margin-top: $unit-sm;
      padding: 6px 12px 4.5px;

      span.active {
        display: none;
      }

      // If user tapped it
      &.active {
        span.default {
          display: none;
        }

        span.active {
          display: inline-block;
        }
      }

      @media (max-width: $phone) {
        display: inline-block;
      }
    }

    .category-legend {
      $generic-brand-tag-size: 16px;
      $generic-brand-tag-color: #59c5ff;
      // TEMPORARY: Hide legends because legends aren't displaying on drug cards.
      display: none;
      position: absolute;
      right: calc((#{$generic-brand-tag-size}) / 2);
      top: 8px;

      span {
        @include text-bold;
        color: nurx-color("black");
        font-size: 1.2em;
        padding-right: 16px;
        position: relative;

        &::before {
          background-color: $generic-brand-tag-color;
          border-radius: 100%;
          box-shadow: 0 3px rgba(0, 0, 0, 0.1);
          content: "";
          height: $generic-brand-tag-size;
          position: absolute;
          right: calc((#{$generic-brand-tag-size}) / (-2));
          top: calc(50% - ((#{$generic-brand-tag-size}) / 2));
          width: $generic-brand-tag-size;

          @media (max-width: $phone) {
            right: 0;
          }
        }

        @media (max-width: $phone) {
          color: #696969;
          font-size: 1.2em;
          padding-right: 23px;
        }
      }

      @media (max-width: $tablet) {
        left: 0;
        margin: $unit-sm 0;
        position: relative;
        right: initial;
      }

      @media (max-width: $phone) {
        float: right;
        margin-bottom: $unit-sm;
        margin-top: $unit-xs;
      }
    }
  }

  .brand-listing-items {
    margin: 0 -$unit-reg;

    @media (max-width: $phone) {
      margin: 0 -$unit-sm;
    }

    .item {
      padding: 20px;

      @media (max-width: $tablet) {
        padding: $unit-reg;
      }

      @media (max-width: $phone) {
        padding: $unit-sm;
      }
    }

    .card {
      background: nurx-color("white");
      border-bottom: 2px solid #dedede;
      border-radius: $border-radius-standard;
      box-shadow: none;
      overflow: visible;
      position: relative;
      transition: transform 0.2s ease;

      @media (max-width: 320px) {
        font-size: 0.95em;
      }

      &:hover {
        box-shadow: 0 2px 0 #dedede;
        transform: scale(1.02, 1.02);

        @media (max-width: $tablet) {
          // default (from above)
          box-shadow: none;
          transform: scale(1, 1);
        }
      }

      .card-content {
        border: 0;
        padding: 0 20px $unit-sm;

        @media (max-width: $phone) {
          padding: $unit-sm $unit-sm 0;
        }

        &.generic-brand-info {
          img {
            height: auto;
            margin-top: 10px;
            max-height: 50px;
            width: 100%;
          }
        }
      }

      .card-header {
        background: nurx-color("white");
        border-radius: 0.5em;
        height: 5em;
        width: 100%;

        div.brand-logo-substitute-container {
          display: table;
          height: 100%;
          width: 100%;
        }

        p.brand-logo-substitute {
          @include text-bold;
          color: #31629f;
          display: table-cell;
          font-size: 1.28em;
          line-height: 1.2em;
          text-align: center;
          vertical-align: middle;
          width: 100%;

          @media (max-width: $tablet) {
            font-size: 1.35em;
          }

          @media (max-width: $phone) {
            font-size: 1.2em;
          }
        }

        img.brand-logo {
          display: block;
          height: calc(100% + 10px);
          margin: 0 auto;
          max-width: 80%;
          position: relative;
          top: -10px;
          width: auto;

          @media (max-width: $tablet) {
            max-width: 110px;
          }

          @media (max-width: $phone) {
            max-width: 100%;
          }
        }

        p.brand-name {
          @include text-bold;
          color: #6b6b6b;
          font-size: 1.1em;
          position: relative;
          text-align: center;
          top: -12px;

          @media (max-width: $phone) {
            top: -10px;
          }
        }
      }

      .card-body {
        height: 275px;
        padding-top: $unit-reg;
      }

      &.generic {
        $generic-brand-tag-size: 16px;
        $generic-brand-tag-color: #59c5ff;

        .card-header::after {
          background-color: $generic-brand-tag-color;
          border-radius: $border-radius-circle;
          box-shadow: 0 3px rgba(0, 0, 0, 0.1);
          content: "";
          height: $generic-brand-tag-size;
          position: absolute;
          right: calc((#{$generic-brand-tag-size}) / (-2));
          top: calc(50% - ((#{$generic-brand-tag-size}) / 2));
          width: $generic-brand-tag-size;
        }
      }
    }
  }
}

.__styles__pages__category__4d139 .category-page,
.__styles__pages__category__4d139 .single-template {
  .card {
    .card-header {
      border: 0;
    }

    .brand-specs {
      span.label {
        @include text-bold;
        color: nurx-color-level("black", 5.5);
        font-size: 0.9em;
        padding: 0;

        @media (max-width: $phone) {
          @include text-bold;
        }
      }

      p,
      ul {
        @include text-bold;
        color: nurx-color-level("black", 2.5);
        font-size: 1em;
        line-height: 1.3em;
      }
    }
  }
}

@keyframes fadein {
  from {
    @include opacity(0);
  }

  to {
    @include opacity(1);
  }
}
