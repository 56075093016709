.__checkout__header-banner__f20c2 {
  &.bg-blue {
    background-color: pastel-color("blue-40");
  }

  &.bg-green {
    background-color: pastel-color("green-40");
  }

  &.bg-peach {
    background-color: pastel-color("peach-40");
  }

  &.bg-pink {
    background-color: pastel-color("pink-40");
  }

  &.bg-purple {
    background-color: pastel-color("purple-40");
  }
  padding: 24px 22px;
}
