// Default Variables
$viewport-height: 100vh;

// App Specific Variables
$app-header-height: 56px;
$app-navbar-height: 50px;

// Device Viewports
$laptop: 1024px;
$tablet: 768px;
$phone: 500px;
$phone-landscape-height: 380px;

// Layout
$site-header-height: 40px;
$admin-site-header-height: 40px;

// Box Shadow
$box-shadow-standard: 0 2px 20px rgba(17, 36, 150, 0.1);
