.__checkout-addon-item__56f57.group-addon {
  padding: 15px;

  & .product-description-box {
    color: grayscale-color("black");
    padding: 0 15px 0 30px;
  }


  .description {
    p {
      margin-bottom: 10px;
    }
  }

  .img {
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
  }

  .price {
    font-size: 14px;
  }

  .price-label {
    margin-left: 5px;
  }

  .product-box-name {
    margin-bottom: 4px;
  }

  .product-img-box {
    flex: 0 0 20%;
    position: relative;
    min-width: 55px;
    max-height: 120px;
  }
}

.__checkout-addon-item__56f57.sub-addon {
  & .product-description-box {
    background-color: grayscale-color("lighter-gray");
    padding: 10px;
  }

  .description {
    color: grayscale-color("gray");
  }

  .price {
    background-color: bright-color("white");
    font-size: 14px;
    padding: 5px 10px;
  }

  .product-box-price {
    bottom: 0;
    left: 10px;
    position: absolute;
  }

  .product-img-box {
    flex: 0 0 30%;
    position: relative;
  }
}

.__checkout-addon-item__56f57.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;

  .product-description-box {
    display: flex;
    flex-direction: column;
    width: 100%;

    .product-box-name {
      color: bright-color("black");
    }

    .product-box-price {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }

    .disclaimer-text {
      font-size: 12px;
      margin-bottom: $vertical-spacing-half;
      color: grayscale-color("gray");
    }
  }

  .img-bg {
    &-green {
      background-color: pastel-color("green");
    }

    &-blue {
      background-color: pastel-color("blue");
    }

    &-peach {
      background-color: pastel-color("peach");
    }

    &-pink {
      background-color: pastel-color("pink");
    }
  }

  .img-bg.img-rounded {
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
  }

  .img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;

    &-contain {
      object-fit: contain;
    }

    &-cover {
      object-fit: cover;
    }
  }

  .remove-button {
    background: none;
    border: 0;
    color: grayscale-color("gray");
    text-decoration: underline;
  }

  .bold {
    font-weight: 500;
  }

  .btn {
    font-size: 12px;
  }

  .add-button {
    background-color: grayscale-color("white");
    color: grayscale-color("black");
    border: grayscale-color("black") solid 1px;
  }

  .selected-button {
    background-color: pastel-color("green-40");
  }
}
