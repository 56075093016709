.__styles__survey-end__cssrs-unsafe-or-inappropriate__d0f4c h2,
.__styles__survey-end__cssrs-unsafe-or-inappropriate__d0f4c h3 {
  color: grayscale-color("dark-gray");
}

.__styles__survey-end__cssrs-unsafe-or-inappropriate__d0f4c ul.suggestions-list {
  margin: 0 0 20px 20px;

  li {
    list-style: disc;
    padding: 0 0 10px 10px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}
