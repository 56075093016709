.__admin__user__appointments__appointments__df4ee .section-header {
  border-bottom: 1px solid grayscale-color("light-gray");
  display: flex;
  justify-content: space-between;
}

.__admin__user__appointments__appointments__df4ee .top-nav-div {
  display: flex;
}

.__admin__user__appointments__appointments__df4ee table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}

.__admin__user__appointments__appointments__df4ee td,
.__admin__user__appointments__appointments__df4ee th {
  border-bottom: 1px solid grayscale-color("light-gray");
  padding: $unit-sm;
  text-align: left;
}

.__admin__user__appointments__appointments__df4ee td > p {
  margin-bottom: 0;
}

.__admin__user__appointments__appointments__df4ee .form-group {
  width: fit-content;
}

/* Styles for child component: appointment-item */

.__admin__user__appointments__appointments__df4ee .status-div p {
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
  padding: 2px 10px;
}

.__admin__user__appointments__appointments__df4ee .status-requested,
.__admin__user__appointments__appointments__df4ee .status-pending {
  background-color: nurx-color-level("black", 8);
  color: nurx-color("black");
}

.__admin__user__appointments__appointments__df4ee .status-scheduled {
  background-color: nurx-color-level("accent", 6);
  color: nurx-color("black");
}

.__admin__user__appointments__appointments__df4ee .status-canceled,
.__admin__user__appointments__appointments__df4ee .status-missed {
  background-color: nurx-color-level("danger", -0.5);
  color: nurx-color("white");
}

.__admin__user__appointments__appointments__df4ee .status-completed {
  background-color: nurx-color-level("success", -2);
  color: nurx-color("white");
}

.__admin__user__appointments__appointments__df4ee .status-actions {
  background: transparent;
  border: 0;
  color: nurx-color("accent");
  margin: 0;
}

.__admin__user__appointments__appointments__df4ee .status-actions:hover {
  background-color: nurx-color("accent");
  border-radius: 20px;
  color: nurx-color("white");
  margin: 0;
}

.__admin__user__appointments__appointments__df4ee .status-div {
  display: flex;
  justify-content: flex-start;
}

.__admin__user__appointments__appointments__df4ee .appointment-time-date-display p {
  margin-bottom: 0;
}
