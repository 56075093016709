.selectable-card {
  $selectable-card-padding: $unit-sm;

  button {
    background: nurx-color("white");
    border: 2px solid #e9e9f3;
    border-radius: $border-radius-standard;
    float: left;
    min-height: 55px;
    padding: $selectable-card-padding;
    text-align: left;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $unit-sm;
    }

    ul {
      margin: 0;
    }

    &.selected,
    &:hover {
      border-color: nurx-color("accent");
    }
  }

  &.has-image {
    min-height: 32px;
    position: relative;

    .image {
      height: 33px;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      width: 48px;
    }

    button {
      position: relative;

      .image {
        left: $selectable-card-padding;
        top: $selectable-card-padding;
      }
    }

    ul {
      margin-left: calc(50px + #{$selectable-card-padding});
    }
  }
}
