.__question__multiple-answer__6b7d4 .options-container {
  display: flex;
  flex-direction: column;
}

.__question__multiple-answer__6b7d4 .is-last-option {
  margin: -20px 0 10px;
  order: 1;
}

// NOTE(hitesh 2021-05-26): I think this fixes both T12889 and T15717.
// PM agreed to make the hover state only change text color so the
// border color only reflects the selected/deselected state
.__question__multiple-answer__6b7d4 .multiple-answer-button {
  color: grayscale-color("black");
  @media (hover: hover) {
    &:hover {
      color: grayscale-color("gray");
    }
  }
}
