.component-loading-animation {
  padding-top: $unit-md;
  text-align: center;
  width: 100%;

  i {
    color: #dcdce6;
    font-size: 36px;
  }
}

.element-loading-animation {
  text-align: center;
  width: 100%;

  i {
    color: #dcdce6;
    font-size: 36px;
  }
}
