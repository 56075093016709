.__styles__cart__refill-consent__782a9 .refill-consent-description {
  margin-bottom: 36px;
}

.__styles__cart__refill-consent__782a9 .refill-consent-buttons :first-child {
  margin-bottom: 8px;
}

.__styles__cart__refill-consent__782a9 .refill-consent-buttons :last-child {
  margin-bottom: 16px;
}

.__styles__cart__refill-consent__782a9 .refill-consent-signature {
  @include text-muted;
  @include vertical-spacing;
  display: block;
  font-size: 14px;
  line-height: 20px;
}
