.__radio-button-component__92d02 .radio-option {
  border: 1px solid bright-color("grey");
  border-radius: 8px;
  cursor: pointer;
  color: bright-color("black");

  label {
    color: bright-color("black");
  }

  &:hover {
    opacity: 0.8;
  }

  &.recommended {
    background-color: #F3FBF4;
  }

  input[type="radio"] {
    accent-color: bright-color("black");
    width: inherit;
  }

  .radio-text {
    display: flex;
    flex-direction: column;

    .radio-header {
      font-size: 16px;
    }

    .radio-description {
      font-weight: normal;
    }

    .radio-label {
      background-color: #FDE3CD;
      border-radius: 4px;
      width: max-content;

      img {
        margin-right: 8px;
      }
    }
  }

  >label {
    display: flex
  }
}
