.__admin-doctor__2ba69 .admin-doctor {
  ul.dropdown {
    font-size: 0.95em;
    position: absolute;
    width: 100%;
    z-index: 4;

    &:not(.active) {
      display: none;
    }

    li {
      background: nurx-color("white");
      border: 1px solid #e3e3e3;
      border-top: 0;
      color: grayscale-color("black");
      cursor: pointer;
      display: block;
      padding: 0.4em 0.8em;

      &:not(:last-child) {
        border-bottom: 0;
      }

      &:last-child {
        border-bottom: 1px solid #add8e6;
        border-bottom-left-radius: 0.2em;
        border-bottom-right-radius: 0.2em;
      }
    }
  }
}
