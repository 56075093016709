.__zen-fulfill-order-manager__1ad4c .unselectable {
  user-select: none;
}

.__zen-fulfill-order-manager__1ad4c .left-border {
  border-left: 3px solid nurx-color("accent");
}

.__zen-fulfill-order-manager__1ad4c .label {
  letter-spacing: 0.1em;
}
