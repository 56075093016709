.__pharmacy-drug-inventory__3ce3a .inventory-navigation-options {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  max-width: 1364px; // to match table
}

.__pharmacy-drug-inventory__3ce3a .helper-text {
  flex-grow: 2;
  text-align: center;
  width: auto;
}

.__pharmacy-drug-inventory__3ce3a .drug-inventory-table {
  // Sum of the height of header items above the table is 269px + 30px bottom margin
  // Drug inventory table should occupy remaining vertical space
  height: calc(100vh - 299px);
  margin: 0 0 30px;
  overflow: hidden;

  .ember-table {
    .ember-table-overflow {
      height: 100%;
      overflow: scroll;

      table {
        thead {
          border-bottom: 3px solid grayscale-color("dark-gray");

          .et-tr {
            text-transform: capitalize;

            th {
              background-color: nurx-color("white");
              border-bottom: 3px solid grayscale-color("dark-gray");
              margin-bottom: 5px;
              padding: 0 3px 5px 3px;

              &.is-sortable {
                cursor: pointer;
              }
            }
          }

          .et-sort-indicator {
            margin-left: $unit-sm;

            &::before {
              border: 0;
              content: "\25be"; // down triangle
              display: inline-block;
              line-height: 1; // This prevents the icon from affecting the base-line of the surrounding text, so it doesn't move around
              transform: rotate(0deg);
            }

            &.is-ascending::before {
              transform: rotate(180deg);
            }
          }
        }

        tbody {
          tr {
            td {
              border-right: 1px solid grayscale-color("dark-gray");
              padding: 3px;

              &.is-first-column {
                border-left: 1px solid grayscale-color("dark-gray");
              }
            }
          }

          tr:nth-child(odd) {
            background-color: grayscale-color("light-gray");
          }
        }
      }
    }
  }
}
