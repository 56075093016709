@mixin survey-markdown {
  // normalize the spacing of list elements
  ul,
  ol {
    margin-bottom: $vertical-spacing-half;
    padding-left: $vertical-spacing;

    li {
      margin-left: $vertical-spacing-none;
      padding: $vertical-spacing-none;
    }
  }

  // restoring the bullet point for unordered lists
  ul {
    li {
      list-style: disc;
    }
  }

  // override styles that removes all list-item styling.
  ol {
    padding: $vertical-spacing-none;

    li {
      counter-increment: number;
      margin-left: 16px;
    }

    // overrides the nurx number counter font
    li::marker {
      color: grayscale-color("gray");
      // restore the `.` period after the number counter
      content: counter(number) ". ";
      font-family: "Graphik Web", Arial, Helvetica, sans-serif;
      font-size: 14px;
      margin-left: 5px;
      min-width: auto;
      width: 14px;
    }

    li:first-child {
      counter-reset: number;
    }
  }

  p {
    margin-bottom: $vertical-spacing-half;
  }
}
