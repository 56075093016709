.__exam-view__item-edit__5ae13 .assessment {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, auto);
  margin-top: 20px;
}

.__exam-view__item-edit__5ae13 .assessment-item {
  text-align: center;
}

.__exam-view__item-edit__5ae13 .fa-check-circle {
  color: nurx-color("accent");
  font-size: 2em;
}
