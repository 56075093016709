.__styles__guardian-consent-verification__completed__4eaa3 header {
  h1 {
    font-size: 36px;
    padding-top: $unit-md;
  }
}

.__styles__guardian-consent-verification__completed__4eaa3 .prescribed-info {
  background-color: pastel-color("blue");
  background-image: url("/assets/images/app/acne-upsell.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  color: grayscale-color("gray");
  margin: 0;
  padding: 4rem 1rem 50%;
  text-align: center;

  h1 {
    @include nurx-digital-font;
    color: bright-color("black");
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 48px;
    margin-bottom: $vertical-spacing;
  }
}
