/* Theme default */
/* ======= User Profile ========= */

.view-account {
  .pro-label {
    display: inline-block;
    font-size: 13px;
    margin-left: 10px;
    padding: 4px 5px;
    position: relative;
    top: -5px;
  }

  .side-bar {
    background-color: grayscale-color("lightest-gray");
    min-width: 145px;
    overflow-y: auto;
    padding-bottom: 1em;
    width: 145px;

    .user-info {
      background-color: nurx-color("white");
      color: nurx-color("black");
      padding-bottom: $unit-sm;
      text-align: center;

      .img-profile {
        height: 120px;
        margin-bottom: $unit-sm;
        width: 120px;
      }

      .meta li {
        margin-bottom: 10px;

        span {
          display: inline-block;
          margin-right: 5px;
          text-align: right;
          width: 100px;
        }

        a {
          color: #616670;
        }

        &.activity {
          color: #a2a6af;
        }
      }
    }
  }
}

// NOTE(jason): .fieldset-title used to be nested under .view-account > .content-panel. Taking it out enables us to use
// the class without relying on parent divs, which is good because we use this class in Ember components.
.fieldset-title {
  border-bottom: 1px solid #eaeaf1;
  color: #616670;
  font-size: 16px;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.view-account {
  .content-panel {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5em 1.5em 2em;

    // Add extra top padding if there's an account level warning
    &.account-level-warning {
      padding: 4em 1.5em 2em;
    }

    @media (max-width: 767px) {
      overflow-y: visible;
    }

    @media (max-width: $phone) {
      padding: 1em;
      padding-top: 1.4em;
    }

    .title {
      font-size: 18px;
      margin-bottom: 15px;
      margin-top: 0;
    }

    .avatar .figure img {
      float: right;
      width: 64px;
    }

    .content-header-wrapper {
      margin-bottom: 30px;
      position: relative;

      .actions {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .content-utilities {
      margin-bottom: 30px;
      position: relative;

      .btn-group {
        margin-bottom: 15px;
        margin-right: 5px;
      }

      .fa {
        font-size: 16px;
        margin-right: 0;
      }

      .page-nav {
        position: absolute;
        right: 0;
        top: 0;

        .btn-group {
          margin-bottom: 0;
        }

        .indicator {
          color: #a2a6af;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .mails-wrapper .mail-item {
      border-bottom: 1px solid #f3f3f3;
      color: #616670;
      overflow: hidden;
      padding: 10px;
      position: relative;

      >div {
        float: left;
      }

      .icheck {
        background-color: nurx-color("white");
      }

      &:hover {
        background: #f9f9fb;
      }

      &:nth-child(even) {
        background: #fcfcfd;

        &:hover {
          background: #f9f9fb;
        }
      }

      a {
        color: #616670;

        &:hover {
          color: #494d55;
          text-decoration: none;
        }
      }

      .checkbox-container {
        display: inline-block;
        margin-right: 5px;
      }

      .star-container {
        display: inline-block;
        margin-right: 5px;

        .fa {
          color: #a2a6af;
          font-size: 16px;
          vertical-align: middle;

          &.fa-star {
            color: #f2b542;
          }

          &:hover {
            color: #868c97;
          }
        }
      }

      .mail-to {
        display: inline-block;
        margin-right: 5px;
        min-width: 120px;
      }

      .mail-subject {
        display: inline-block;
        margin-right: 5px;

        .label {
          margin-right: 5px;

          &:last-child {
            margin-right: 10px;
          }

          a {
            color: nurx-color("white");

            &:hover {
              color: nurx-color("white");
            }
          }
        }

        .label-color-1 {
          background: nurx-color("danger");
        }

        .label-color-2 {
          background: #58bbee;
        }

        .label-color-3 {
          background: #f8a13f;
        }

        .label-color-4 {
          background: #ea5395;
        }

        .label-color-5 {
          background: #8a40a7;
        }
      }

      .time-container {
        color: #a2a6af;
        display: inline-block;
        position: absolute;
        right: 10px;
        text-align: left;
        top: 10px;

        .attachment-container {
          color: #a2a6af;
          display: inline-block;
          margin-right: 5px;
        }

        .time {
          display: inline-block;
          text-align: right;

          &.today {
            @include text-bold;
            color: #494d55;
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .view-account {
    .content-panel .title {
      text-align: center;
    }

    .side-bar .user-info {
      display: none;
    }

    .side-bar {
      padding: 2rem;
    }

    .content-panel {
      .content-header-wrapper .actions {
        margin-bottom: 30px;
        position: static;
      }

      .content-utilities .page-nav {
        margin-bottom: 15px;
        position: static;
      }
    }
  }

  .view-account {
    .content-panel .avatar .figure img {
      float: none;
      margin-bottom: 15px;
    }

    .file-uploader {
      margin-bottom: 15px;
    }

    .mail-subject {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .content-panel .mails-wrapper .mail-item .time-container {
      position: static;

      .time {
        text-align: left;
        width: auto;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .view-account {
    .mail-subject .subject {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 768px) {
  .view-account {
    .content-panel {
      border-left: 1px solid #f3f3f7;
    }

    .mail-subject .subject {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
