.__admin__items__admin-item-family-item__d2e27 tr {
  &.modified {
    background-color: #d9ffb4;

    td {
      background-color: #ffffe0 !important;
    }
  }
}

.__admin__items__admin-item-family-item__d2e27 td {
  // remove standard botom margin on global input and textarea
  input,
  textarea {
    margin: 0;
  }

  input {
    border-bottom-color: grayscale-color("gray");
    width: 100%;
  }

  textarea {
    border: 1px solid grayscale-color("light-gray"); // help users understand text is editable
    height: 100%; // fix overflow issue where text was being hidden
  }

  .small {
    width: 30px;
  }
}

.__admin__items__admin-item-family-item__d2e27 ul.dropdown {
  font-size: 0.95em;
  position: absolute;
  width: 100%;
  z-index: 4;

  li {
    background: nurx-color("white");
    border: 1px solid #e3e3e3;
    border-top: 0;
    color: grayscale-color("black");
    cursor: pointer;
    display: block;
    padding: 0.4em 0.8em;
  }

  li:not(:last-child) {
    border-bottom: 0;
  }

  li:last-child {
    border-bottom: 1px solid #add8e6;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
  }

}

.__admin__items__admin-item-family-item__d2e27 ul.dropdown:not(.active) {
  display: none;
}

.__admin__items__admin-item-family-item__d2e27 textarea {
  &[readonly] {
    background-color: $read-only-gray;
  }
}

.__admin__items__admin-item-family-item__d2e27 .prescribing-message-count {
  color: $fake-hyperlink;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  text-decoration-line: underline;
}
