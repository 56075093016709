.__patient-todo__a63b2 .todo-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: auto;
  padding: 10px;
}

.__patient-todo__a63b2 .todo-header {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.__patient-todo__a63b2 .todo-icon {
  flex-grow: 0;
  margin-right: 10px;
  margin-top: -10px;
  width: 92px;
}

.__patient-todo__a63b2 .icon {
  height: 70px;
  width: 70px;
}

.__patient-todo__a63b2 .todo-arrow {
  flex-grow: 0;
  margin: 10px;
  margin-left: 20px;
  width: 25px;
}

.__patient-todo__a63b2 .todo-top {
  flex-grow: 4;
  padding-bottom: 10px;
  padding-top: 10px;
}

.__patient-todo__a63b2 .title {
  margin-bottom: 10px;
}

.__patient-todo__a63b2 .subheading {
  margin-bottom: 1px;
}
