.btn {
  border-radius: 3px;
  white-space: normal !important;

  &:hover,
  &:active {
    @media (hover:hover) {
      outline: none;
    }
  }

  &:focus {
    outline: none;
  }

  &.btn-small {
    @include text-bold;
    font-size: 14px;

    @media (max-width: $phone) {
      font-size: 16px;
    }
  }

  &.btn-xxsmall {
    @include text-bold;
    font-size: 12px;
    padding-bottom: $unit-xs;
    padding-left: $unit-sm;
    padding-right: $unit-sm;
    padding-top: $unit-xs;
  }

  &.btn-medium {
    @include text-bold;
    font-size: 16px;
  }

  &.btn-red {
    background-color: nurx-color("primary");
    color: nurx-color("white");

    @media (hover:hover) {
      &:hover,
      &:focus,
      &:active {
        background-color: nurx-color-level("primary", 1.5);
        color: nurx-color("white");
      }
    }
  }

  &.danger {
    background-color: nurx-color("danger");
    color: nurx-color("white");
  }

  &.btn-border-gray {
    border-color: #e6e7ea;
    color: nurx-color("black");
  }

  &.btn-white {
    // NOTE @DT: this is exact clone of .btn-default in styles.scss, except for color change.
    // AppKit doesn"t natively have a white button with dark text. Only has a white background + gray/light text.
    background: nurx-color("white");
    border: 1px solid #e6e7ea;
    color: #555;

    @media (hover:hover) {
      &:hover,
      &:focus,
      &:active,
      &.active,
      &.focus,
      &.hover {
        background: #f9f9fb;
        border-color: #e6e7ea;
        box-shadow: none;
        color: #333;
      }
    }

    &.no-border {
      @extend .no-border;
    }
  }

  &.btn-full-width {
    width: 100%;
  }

  &.btn-full-width-no-padding {
    width: 100%;
    padding: 0;
  }

  &.btn-75-width {
    width: 75%;
  }

  &.btn-half-width {
    width: 48%;
  }

  &.btn-quarter-width {
    width: 24%;
  }

  &.btn-primary-gradient {
    // blue gradient button
    background: linear-gradient(90.07deg, #4b7ff1 0.03%, #1764f9 99.98%);
    color: nurx-color("white");
  }

  &.btn-alternative-gradient {
    // purple gradient button
    background: linear-gradient(90.07deg, #956fff 0.03%, #6f3dff 99.98%);
    color: nurx-color("white");
  }

  &.btn-side-padding {
    padding-left: $unit-md;
    padding-right: $unit-md;
  }
}

// Checkout "selection button" styles
.option-selection-button {
  background: nurx-color("white");
  border: 1px solid #e8e8e8;
  border-radius: $unit-sm;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  margin-bottom: $unit-reg;
  position: relative;
  text-align: center;
  transition: all 0.25s ease-in-out;

  &:hover {
    border: 1px solid nurx-color("accent");
  }

  @media (max-width: $tablet) {
    margin-bottom: $unit-reg;
    padding: $unit-reg;
  }

  @media (min-width: $tablet) {
    min-height: 100px;
    padding: $unit-reg;
  }

  .text-container {
    align-self: center;

    h4 {
      @include text-bold;
      color: nurx-color("accent");
      margin: 0 !important;
    }

    span {
      color: #84888e;
      display: block;
      font-size: 1.1em;
      line-height: 1.3em;
      margin-top: $unit-xs;
    }
  }

  // If button is selected
  &.selected {
    border: 2px solid nurx-color("accent");

    &::before {
      background-color: #1ba9ff;
      background-image: url("/assets/images/checkout/white-checkmark.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 63%;
      border: 1px solid #1ba9ff;
      border-radius: 100%;
      bottom: -$unit-sm;
      content: "";
      height: $unit-md;
      position: absolute;
      right: -$unit-sm;
      width: $unit-md;

      @media (max-width: $phone) {
        bottom: initial;
        top: -$unit-sm;
      }
    }
  }
}

// Disable the icon because it"s getting cut off by the overflow:hidden, but required for the accordion animation
li.shipping-address .option-selection-button.selected::after {
  display: none;
}

// if Generic Alternative:
.option-selection-button.branded-alternative {
  background: none;

  h4 {
    font-size: 1.14em;
  }
}

/* Allow Bootstrap nav items to be buttons */
/* Copypasted and modified from Bootstrap styling */
.nav > li > button {
  display: block;
  padding: $nav-link-padding;
  position: relative;

  &:hover,
  &:focus {
    background-color: $nav-link-hover-bg;
    text-decoration: none;
  }
}

.nav-tabs > li {
  & > button {
    background-color: transparent;
    border-radius: $border-radius-base $border-radius-base 0 0;
    border: 1px solid transparent;
    color: #9499a3;
    line-height: $line-height-base;
    margin-right: 2px;

    &:hover {
      border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
      color: #000;
    }
  }

  // Active state, and its :hover to override normal :hover
  &.open > button {
    &,
    &:hover,
    &:focus {
      color: $nav-tabs-active-link-hover-color;
      cursor: default;
      background-color: $nav-tabs-active-link-hover-bg;
      border: 1px solid $nav-tabs-active-link-hover-border-color;
      border-bottom-color: transparent;
    }
  }
}
