.mobile-app-content {
  height: $viewport-height;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  // Required for iOS Momentum Scrolling on iOS Overflow Elements; has to be scroll, not auto:
  overflow-y: scroll;
  padding-bottom: calc(#{$app-navbar-height} + #{$unit-reg});
  padding-top: calc(#{$app-header-height} + #{$unit-reg});

  .in-progress {
    background-color: #e4eff2;
  }

  .module {
    border: 0;
  }

  .module .module-heading {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .component-orders-mobile {
    padding-bottom: $unit-sm;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }


  .module-heading::after {
    display: none;
  }

  .module-heading {
    .actions {
      text-align: right;

      button {
        font-size: $unit-reg;
      }
    }
  }

  .module-content {
    .card {
      border: 0;
      position: relative;
    }

    .module-content-inner {
      border-radius: $border-radius-standard;
      padding-bottom: $unit-reg;
      padding-left: $unit-reg;
      padding-right: $unit-reg;
      padding-top: $unit-reg;

      .inner-heading {
        @include text-bold;
      }

      .inner-heading,
      .inner-message {
        color: nurx-color("black");
        text-align: center;
      }

      .fa {
        color: #dfe0e8;
      }

      .app-new-request {
        text-align: center;
      }
    }

    &:not(:last-child) {
      padding-bottom: 0 !important;
    }
  }
}

.module.transparent-background {
  background-color: transparent;
}
