.__banner__2e204 {
  margin: $unit-reg auto 0;
  max-width: 600px;
}

.__banner__2e204.no-margin {
  margin: 0;
}

// Announcement Container + Text
.__banner__2e204 .announcement-notice {
  border-radius: $border-radius-standard;
  color: nurx-color("white");
  font-size: 15px;
  line-height: 1.6em;
  margin-bottom: $unit-reg;
  padding: $unit-reg;

  a {
    @include text-bold;
    color: #285586;

    &:hover {
      color: #285586 !important;
    }
  }

  &.legal-banner {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;

    p {
      color: nurx-color("black");
      font-size: 14px;
      margin-bottom: 0;
    }

    a {
      @include text-bold;
      color: grayscale-color("black");
      text-decoration: underline;

      &:hover {
        color: grayscale-color("black") !important;
        text-decoration: underline !important;
      }
    }
  }

}
