.__styles__request__start__email-collection__95d41 .intro {
  @include request-intro(null, 0);
}

.__styles__request__start__email-collection__95d41 .error-text {
  color: nurx-color("danger");
  margin-left: 4px;
}

.__styles__request__start__email-collection__95d41 .checkout-banner {
  display: flex;
  align-items: stretch;
  margin: 20px 0;
}

.__styles__request__start__email-collection__95d41 .checkmark-icon-container {
  margin-right: 12px;
  display: flex;
}

.__styles__request__start__email-collection__95d41 .checkmark-icon {
  height: 20px;
  width: 20px;
  overflow: hidden;

  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.__styles__request__start__email-collection__95d41 .banner-text {
  @include text-bold;
  @include text-muted;
  font-size: 0.9em;
}
