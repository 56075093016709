.address-and-default-button {
  i {
    color: nurx-color-level("accent", 1);
  }

  .panel-default-selected {
    @include text-bold;
    color: #53575e;
    font-size: 0.86em;
    line-height: 1.5em;

    i {
      margin-right: $unit-xs;
    }
  }
}

.address-use-card {
  .panel {
    .panel-card-content {
      &:not(:only-of-type) {
        &:not(:last-of-type) {
          margin-bottom: $unit-reg;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .user-address-panel {
    .panel-body {
      min-height: $unit-xl;
    }
  }
}
