.__health-profile-form__44773 .input-label {
  margin-bottom: 10px;
  margin-right: 10px;
}

.__health-profile-form__44773 .input-full-width {
  input {
    min-width: 100%;
  }
}

.__health-profile-form__44773 .info {
  color: $copy;
  margin-top: -40px;
}
