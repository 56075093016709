.tag {
  @include text-bold;
  border-radius: 0.25em;
  color: nurx-color("white");
  display: inline-block;
  font-size: 0.86em;
  line-height: 1em;
  margin-top: 0.5em;
  padding: 0.6em 3em 0.6em 0.9em;
  position: relative;

  &:not(:last-child) {
    margin-right: 0.25em;
  }

  &.tag-primary {
    background-color: #46a8f1;
  }

  &.tag-default {
    background-color: #acacac;
  }

  button {
    background-color: rgba(0, 0, 0, 0.08);
    border: 0;
    bottom: 0;
    color: nurx-color("white");
    padding: 0.3em 0.8em;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 0.25s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }

    i {
      margin: 0;
    }
  }
}
