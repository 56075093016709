.__auto-refill-consent-item__63859 .wrapper {
  margin-bottom: 24px;

  &.shipping-preference-wrapper {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .shipping-preference-item {
    padding: 7px 0 7px 16px;

    p {
      margin-bottom: 0;
    }
  }
}

.__auto-refill-consent-item__63859 .col-logo {
  display: flex;
  align-items: center;
  overflow: hidden;

}

.__auto-refill-consent-item__63859 .logo-image {
  object-position: center;
  position: absolute;
  width: 100%;
}

.__auto-refill-consent-item__63859 .logo-image-contain {
  object-fit: contain;
  padding: 10px;
}

.__auto-refill-consent-item__63859 .logo-image-cover {
  object-fit: cover;
}

.__auto-refill-consent-item__63859 .main-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding: 26px;
  background-color: grayscale-color("lighter-gray");

  &.shipping-preference-item {
    background-color: transparent;
    flex-direction: column;
    align-items: flex-start;

    .subheading {
      font-size: 16px;
    }
  }
}


.__auto-refill-consent-item__63859 .subheading {
  @include text-bold;
  @include text-medium;
  color: grayscale-color("black");
}

.__auto-refill-consent-item__63859 .refill-input-width {
  width: 100%;
  max-width: 24px;
}
